import gql from 'graphql-tag';

export const GET_ALL_COMPONENTS = gql(`
query($maxregs: Int) {
  componentQuery {
    getAll(maxregs:$maxregs){
      id
      applicationId
      parentId
      name
      description
      active
      reusable
      reusableComponentId
      reusableApplicationId
      reusableApplicationVersion
      idType    
      idSubType 
    }
    }
}
`);

export const GET_COMPONENT_BY_ID = gql(`
query($id: Int!) {
  componentQuery {
    getById(id:$id) {
      id
      applicationId
      parentId
      name
      description
      active
      reusable
      reusableComponentId
      reusableApplicationId
      reusableApplicationVersion
      idType
      idSubType
      order,
      fcr,
      ucr,
      uum,
      fum,
      iapComponentAttributes{
          id,
          name,
          idAttributeType,
          value
      }
      iapComponentEvents{
          id,
          name,
          idEventType,
          active
      }
    }
  }
}
`);

export const GET_COMPONENT_TREE_BY_ID = gql(`
query($componentId: Int!,$applicationId: Int!,$applicationVersion: Int!) {
  componentQuery {
    getTreeById(componentId:$componentId,applicationId:$applicationId,applicationVersion:$applicationVersion) {
      id
      applicationId
      applicationVersion
      parentId
      name
      description
      active
      reusable
      reusableComponentId
      reusableApplicationId
      reusableApplicationVersion
      idType      
      idSubType
      order
      fcr
      ucr
      uum
      fum
      iapComponentEvents{
        id
        name
        description
        componentId
        idEventType 
        group       
        active
        fcr
        ucr
        uum
        fum
      }
      iapComponentAttributes{
          id
          idDataType
          idAttributeType
          componentId
          idType
          idModelValueType
          valueListId
          mandatory
          name
          value
          order
          valueBinaryFileName
          valueBinaryFileType
          valueBinaryString

      }
      iapComponentDataSources{
          componentId
          dataSourceId
          id
          name
          applicationId
          applicationVersion
          dataSource{
              idDataSourceType
              name
              objectGroups{
                idObjeto
                objetoId
                objetoIdRoot
                idObjetoRoot
                groupId
                applicationId
                applicationVersion
                add
                edit
                list
                delete
                view
                import
                export
              }
        iapDataSourceDataBase{
          id
          dataSourceId
          idBaseDatos
          idDataBaseType
          querySelect
          queryFrom
          queryWhere
          queryOrderBy
          queryGroupBy
          queryHaving
          filterDistinct
          fcr
          ucr
          uum
          fum
        },
        iapDataSourceService{
          dataSourceId
          idHttpMethodType
          methodName
          type
          idAuthType
          url
          fileJson
          host
          fcr
          ucr
          uum
          fum         
        },
              iapDataSourceTableAliases{
                  id
                  tableAlias
                  tableName
                  shortDescription
                  idIcon
                  join
                  isInput
                  
              }    
          }

      }
    }
  }
}
`);



export const GET_COMPONENT_TREE_ALLOBJCETS_BY_ID = gql(`
query($componentId: Int!,$applicationId: Int!,$applicationVersion: Int!, $withAllAtributtes: Boolean!) {
  componentQuery {
    getTreeAllObjectsById(componentId:$componentId,applicationId:$applicationId,applicationVersion:$applicationVersion,withAllAtributtes:$withAllAtributtes) {
      id
      applicationId
      applicationVersion
      parentId
      name
      description
      active
      reusable
      reusableComponentId
      reusableApplicationId
      reusableApplicationVersion
      idType  
      idSubType    
      order
      fcr
      ucr
      uum
      fum
      iapComponentEvents{
        id
        name
        description
        componentId
        idEventType
        group
        active
        fcr
        ucr
        uum
        fum
      }
      objectGroups{
        idObjeto
        objetoId
        objetoIdRoot
        idObjetoRoot
        groupId
        applicationId
        applicationVersion
        add
        edit
        list
        delete
        view
        import
        export
      }
      expressions{
        id
        applicationId
        applicationVersion
        idObjeto
        objetoId
        idObjetoRoot
        objetoIdRoot
        idTypeExpression
        group
        fcr
        ucr
        uum
        fum
        iapExpressionDetails{
          id
          expressionId
          variable
          expression
          expressionTranslated
          processOrder
          localizable
          fcr
          ucr
          uum
          fum
        }
      }

      workFlows{
            id
            active
            description
            fcr
            fum
            idObjeto
            name
            objetoId
            idObjetoRoot
            objetoIdRoot
            ucr
            uum
            description
            iapWorkFlowActivities{
                id
            workFlowId
            typeWorkFlowId
            name
            label
            color
            left
            top
            isInvalid
            canDelete
            fcr
            ucr
            uum
            fum
            iapWorkFlowActivityControls
            {
              id
              workFlowActivityId
              name
              value
              localizable
              group
            }
                iapWorkFlowActivityVariables{
                    id
                    workFlowActivityId
                    name
                    isInput
                    defaultValue
                    fcr
                    ucr
                    uum
                    fum
                    iapWorkFlowActivityConnectionWorkFlowActivityVariableIdInputNavigations{
                        fcr
                        fum
                        id
                        ucr
                        uum
                        workFlowActivityVariableIdInput
                        workFlowActivityVariableIdOutput
                        externalId
                    }
                    iapWorkFlowActivityConnectionWorkFlowActivityVariableIdOutputNavigations{
                        fcr
                        fum
                        id
                        ucr
                        uum
                        workFlowActivityVariableIdInput
                        workFlowActivityVariableIdOutput
                        externalId
                    }
                }              

            }
        }

      
      iapComponentAttributes{
          id
          idDataType
          idAttributeType
          componentId
          idType
          idModelValueType
          valueListId
          mandatory
          name
          value
          order
          valueBinaryFileName
          valueBinaryFileType
          valueBinaryString
      }
      iapComponentDataSources{
          componentId
          dataSourceId
          id
          name
          ucr
          autoSave
          autoLoad
          maxRowsReturned
          queryWhere
          queryOrderBy
          queryGroupBy
          queryHaving
          filterDistinct
          order
          applicationId
          applicationVersion
          iapComponentDataSourceServiceConfigurations{
              componentDataSourceId
              dataSourceServiceConfigurationId
              idServiceConfigurationType
              key
              value
          }
          iapComponentDataSourceFieldConfigurations{
            componentDataSourceId
            dataSourceFieldId
            filterSearch
            filterSearchOrder
            defaultFilterSearch
            defaultFilterSearchOrder
            autoUpdate
            defaultValue
            defaultValidation
            filterResult
            filterResultOrder
            mandatory
            edit
            view
            list
            add
            editMassive
            import
            sortOrder
            idSortType
            aggregateOperation
            idFilterType
            idFilterControlType
            idDefaultMatchMode
            defaultFilterValue
            cellStyle    
            displayContentMaxlength        
            fcr
            ucr
            uum
            fum
            dataSourceField{
                id
                dataSourceId
                dataSourceTableAliasId
                tableName
                field
                tableNameAlias
                fieldAlias
                primaryKey                
                foreignKey
                identityColumn
                position
                defaultValue
                nullable
                encrypted
                sqlType
                length
                precision
                idDataType
                catalogTypeId
                foreignTableName
                foreignTableFieldId
                foreignTableFieldDescription
                dataSourceLookUpId
                shortDescription
                description
                computed
                dataSourceLookUp{
                  id
                  dataSourceId
                  dataSource{
                                  id,
                                    applicationId,
                                    applicationVersion,
                                    name,
                                    description,
                                    objectGroups{
                                        idObjeto
                                        objetoId
                                        objetoIdRoot
                                        idObjetoRoot
                                        groupId
                                        applicationId
                                        applicationVersion
                                        add
                                        edit
                                        list
                                        delete
                                        view
                                        import
                                        export
                                      }
                                    iapDataSourceFields{
                                                        id,
                                                        dataSourceId,
                                                        dataSourceTableAliasId
                                                        tableName,
                                                        field,
                                                        tableNameAlias,
                                                        fieldAlias
                                                         }

            }
            }
              }
            
          }
          dataSource{
              idDataSourceType
              name
              description
              objectGroups{
                  idObjeto
                  objetoId
                  objetoIdRoot
                  idObjetoRoot
                  groupId
                  applicationId
                  applicationVersion
                  add
                  edit
                  list
                  delete
                  view
                  import
                  export
                }
        iapDataSourceDataBase{
          id
          dataSourceId
          idBaseDatos
          idDataBaseType
          querySelect
          queryFrom
          queryWhere
          queryOrderBy
          queryGroupBy
          queryHaving
          filterDistinct
          fcr
          ucr
          uum
          fum
        },
        iapDataSourceService{
          dataSourceId
          idHttpMethodType
          idAuthType
          url
          fileJson
          fcr
          ucr
          uum
          fum         
        },
        iapDataSourceServiceConfigurations {
          id,
          dataSourceId,
          idServiceConfigurationType,
          key,            
          value,
          description,
          fcr,
          ucr
        },
              iapDataSourceFields{

                id
                dataSourceId
                dataSourceTableAliasId
                tableName
                field
                tableNameAlias
                fieldAlias
                primaryKey                
                foreignKey
                identityColumn
                position
                defaultValue
                nullable
                encrypted
                sqlType
                length
                precision
                idDataType
                catalogTypeId
                foreignTableName
                foreignTableFieldId
                foreignTableFieldDescription
                dataSourceLookUpId
                shortDescription
                description
                computed
                fcr
                ucr
                uum
                fum
                dataSourceLookUp{
                  id
                  dataSourceId
                  idFieldDescription
                  tableAlias
                  maxRowsReturned                  
                  queryDisplay
                  queryWhere
                  queryOrder
                  addNewvaluesOnTheFly
                  additionaFieldsOnSearch
                  filterDistinct
                  fieldsOnReturn
                  idControlType
                  componentId
                  parameters
                  iapDataSourceLookUpFieldMaps
                  {

                      id
                    dataSourceLookUpId
                    dataSourceFieldIdTarget
                    dataSourceFieldIdTargetLookUp
                    dataSourceFieldIdSource
                    isFieldLinked
                    isFieldFiltered
                  }
                    dataSource{
                      id,
                        applicationId,
                        applicationVersion,
                        name,
                        description,
                        idDataSourceType,
                        fcr,
                        ucr,
                        uum,
                        fum,
                        objectGroups{
                          idObjeto
                          objetoId
                          objetoIdRoot
                          idObjetoRoot
                          groupId
                          applicationId
                          applicationVersion
                          add
                          edit
                          list
                          delete
                          view
                          import
                          export
                        }
                        iapDataSourceTableAliases{
                            id,
                            dataSourceId,
                            tableName,
                            tableAlias,
                            shortDescription
                            idIcon,
                            join,
                            isInput,
                            fcr,
                            ucr,
                            uum,
                            fum

                        },
                        iapDataSourceDataBase{
                          id
                          dataSourceId
                          idBaseDatos
                          idDataBaseType
                          querySelect
                          queryFrom
                          queryWhere
                          queryOrderBy
                          queryGroupBy
                          queryHaving
                          filterDistinct
                          fcr
                          ucr
                          uum
                          fum
                        },
                        iapDataSourceService{
                          dataSourceId
                          idHttpMethodType
                          idAuthType
                          url
                          fileJson
                          fcr
                          ucr
                          uum
                          fum         
                        },
                        iapDataSourceServiceConfigurations {
                          id,
                          dataSourceId,
                          idServiceConfigurationType,
                          key,            
                          value,
                          description,
                          fcr,
                          ucr
                        },
                        iapDataSourceFields{
                          id,
                          dataSourceId,
                          dataSourceTableAliasId
                          tableName,
                          field,
                          tableNameAlias,
                          fieldAlias,
                          primaryKey,
                          foreignKey,
                          identityColumn,
                          dataSourceLookUpId
                          position,
                          defaultValue,
                          nullable,
                          encrypted
                          sqlType,
                          length,
                          precision,
                          idDataType,
                          catalogTypeId,
                          foreignTableName,
                          foreignTableFieldId,
                          foreignTableFieldDescription
                          shortDescription
                          description
                          localizable
                          computed
                          fcr
                          ucr
                          fum
                          uum
                          dataSourceLookUp{
                            idControlType
                          }  
                          iapObjectDataSourceFieldConfigurations{
                            idObjeto,
                            objetoId,
                            dataSourceFieldId
                            defaultFilterSearch      
                            defaultFilterSearchOrder
                            autoUpdate
                            defaultValue
                            defaultValidation
                            edit
                            add
                            editMassive
                            import
                            fcr
                            filterResult
                            filterResultOrder
                            filterSearch
                            filterSearchOrder
                            fum
                            list
                            view
                            mandatory
                            sortOrder
                            idSortType
                            aggregateOperation
                            idFilterType
                            idFilterControlType
                            idDefaultMatchMode
                            defaultFilterValue
                            cellStyle
                            displayContentMaxlength
                            dataSourceField{
                                id
                                tableName
                                field
                                tableNameAlias,
                                fieldAlias,
                                dataSourceLookUpId
                            }
                          }
                        }
                    }
                }
                          
              }
              iapDataSourceTableAliases{
                  id
                  tableAlias
                  tableName
                  shortDescription
                  idIcon
                  join
                  isInput
              }    
          }

      }
    }
  }
}
`);



export const GET_COMPONENT_SIMPLE_TREE_BY_ID = gql(`
query($componentId: Int!,$applicationId: Int!,$applicationVersion: Int!) {
  componentQuery {
    getTreeById(componentId:$componentId,applicationId:$applicationId,applicationVersion:$applicationVersion) {
      id
      applicationId
      applicationVersion
      parentId
      name
      description
      active
      reusable
      reusableComponentId
      reusableApplicationId
      reusableApplicationVersion
      idType   
      idSubType   
      order
      fcr
      ucr
      uum
      fum     
      iapComponentAttributes{
          id
          idDataType
          idType
          idModelValueType
          name
          value
          order
          valueBinaryFileName
          valueBinaryFileType
          valueBinaryString

      },
      iapComponentDataSources{
          componentId
          dataSourceId
          id
          name
          applicationId
          applicationVersion
      }
      }
    }
  }
`);

export const GET_ONLYCOMPONENT_SIMPLE_TREE_BY_ID = gql(`
query($componentId: Int!,$applicationId: Int!,$applicationVersion: Int!) {
  componentQuery {
    getTreeById(componentId:$componentId,applicationId:$applicationId,applicationVersion:$applicationVersion) {
      id
      applicationId
      applicationVersion
      parentId
      name
      description
      active
      reusable
      reusableComponentId
      reusableApplicationId
      reusableApplicationVersion
      idType   
      idSubType   
      order
      }
    }
  }
`);

export const GET_APPLAYOUTCOMPONENT = gql(`
  query($applicationId: Int!,$applicationVersion: Int!) {
    componentQuery {
      getAppLayoutComponent(applicationId:$applicationId,applicationVersion:$applicationVersion) {
        id
      }
    }
  }
`);



export const GET_PARENTS = gql(`
query($applicationId: Int!,$applicationVersion: Int!) {
  componentQuery {
    getParents(applicationId:$applicationId,applicationVersion:$applicationVersion) {
      id
      applicationId
      applicationVersion
      parentId
      name
      description
      active
      reusable
      reusableComponentId
      reusableApplicationId
      reusableApplicationVersion
      idType   
      idSubType   
      order
      fcr
      ucr
      uum
      fum        
    }
  }
}
`);


//////////////
export const GET_COMPONENT_FILE = gql(`
query($componentId: Int!,$applicationId: Int!,$applicationVersion: Int!) {
  componentQuery {
    getFile(componentId:$componentId,applicationId:$applicationId,applicationVersion:$applicationVersion)
    {
      id
      applicationId
      applicationVersion
      parentId
      name
      description
      active
      reusable
      reusableComponentId
      reusableApplicationId
      reusableApplicationVersion
      idType  
      idSubType    
      order
      fcr
      ucr
      uum
      fum
      iapComponentEvents{
        id
        name
        description
        componentId
        idEventType
        group
        active
        fcr
        ucr
        uum
        fum
      }
      objectGroups{
        idObjeto
        objetoId
        objetoIdRoot
        idObjetoRoot
        groupId
        applicationId
        applicationVersion
        add
        edit
        list
        delete
        view
        import
        export
      }
      expressions{
        id
        applicationId
        applicationVersion
        idObjeto
        objetoId
        idObjetoRoot
        objetoIdRoot
        idTypeExpression
        group
        fcr
        ucr
        uum
        fum
        iapExpressionDetails{
          id
          expressionId
          variable
          expression
          expressionTranslated
          processOrder
          localizable
          fcr
          ucr
          uum
          fum
        }
      }

      workFlows{
            id
            active
            description
            fcr
            fum
            idObjeto
            name
            objetoId
            idObjetoRoot
            objetoIdRoot
            ucr
            uum
            description
            iapWorkFlowActivities{
                id
            workFlowId
            typeWorkFlowId
            name
            label
            color
            left
            top
            isInvalid
            canDelete
            fcr
            ucr
            uum
            fum
            iapWorkFlowActivityControls
            {
              id
              workFlowActivityId
              name
              value
              localizable
              group
            }
                iapWorkFlowActivityVariables{
                    id
                    workFlowActivityId
                    name
                    isInput
                    defaultValue
                    fcr
                    ucr
                    uum
                    fum
                    iapWorkFlowActivityConnectionWorkFlowActivityVariableIdInputNavigations{
                        fcr
                        fum
                        id
                        ucr
                        uum
                        workFlowActivityVariableIdInput
                        workFlowActivityVariableIdOutput
                        externalId
                    }
                    iapWorkFlowActivityConnectionWorkFlowActivityVariableIdOutputNavigations{
                        fcr
                        fum
                        id
                        ucr
                        uum
                        workFlowActivityVariableIdInput
                        workFlowActivityVariableIdOutput
                        externalId
                    }
                }              

            }
        }

      
      iapComponentAttributes{
          id
          idDataType
          idAttributeType
          componentId
          idType
          idModelValueType
          valueListId
          mandatory
          name
          value
          valueBinaryFileName
          valueBinaryFileType
          valueBinaryString
          order
      }
      iapComponentDataSources{
          componentId
          dataSourceId
          id
          name
          ucr
          autoSave
          autoLoad
          maxRowsReturned
          queryWhere
          queryOrderBy
          queryGroupBy
          queryHaving
          filterDistinct
          order
          applicationId
          applicationVersion
          iapComponentDataSourceFieldConfigurations{
            componentDataSourceId
            dataSourceFieldId
            filterSearch
            filterSearchOrder
            defaultFilterSearch
            defaultFilterSearchOrder
            autoUpdate
            defaultValue
            defaultValidation
            filterResult
            filterResultOrder
            mandatory
            edit
            view
            list
            add
            editMassive
            import
            sortOrder
            idSortType
            aggregateOperation
            idFilterType
            idFilterControlType
            idDefaultMatchMode
            defaultFilterValue 
            cellStyle 
            displayContentMaxlength          
            fcr
            ucr
            uum
            fum
            dataSourceField{
                  id  
                  tableName
                  field
                  tableNameAlias
                  fieldAlias
              }
            
          }
          dataSource{
              idDataSourceType
              name
              description
              objectGroups{
                idObjeto
                objetoId
                objetoIdRoot
                idObjetoRoot
                groupId
                applicationId
                applicationVersion
                add
                edit
                list
                delete
                view
                import
                export
              }
        iapDataSourceDataBase{
          id
          dataSourceId
          idBaseDatos
          idDataBaseType
          querySelect
          queryFrom
          queryWhere
          queryOrderBy
          queryGroupBy
          queryHaving
          filterDistinct
          fcr
          ucr
          uum
          fum
        },
        iapDataSourceService{
          dataSourceId
          idHttpMethodType
          idAuthType
          url
          fileJson
          fcr
          ucr
          uum
          fum         
        },
        iapDataSourceServiceConfigurations {
          id,
          dataSourceId,
          idServiceConfigurationType,
          key,            
          value,
          description,
          fcr,
          ucr
        },
              iapDataSourceFields{

                id
                dataSourceId
                dataSourceTableAliasId
                tableName
                field
                tableNameAlias
                fieldAlias
                primaryKey                
                foreignKey
                identityColumn
                position
                defaultValue
                nullable
                encrypted
                sqlType
                length
                precision
                idDataType
                catalogTypeId
                foreignTableName
                foreignTableFieldId
                foreignTableFieldDescription
                dataSourceLookUpId
                shortDescription
                description
                computed
                fcr
                ucr
                uum
                fum
                dataSourceLookUp{
                  id
                  dataSourceId
                  idFieldDescription
                  tableAlias
                  maxRowsReturned
                  queryDisplay
                  queryWhere
                  queryOrder
                  addNewvaluesOnTheFly
                  filterDistinct
                  additionaFieldsOnSearch
                  parameters
                  fieldsOnReturn
                  idControlType
                  componentId
                  iapDataSourceLookUpFieldMaps
                  {

                      id
                    dataSourceLookUpId
                    dataSourceFieldIdTarget
                    dataSourceFieldIdTargetLookUp
                    dataSourceFieldIdSource
                    isFieldLinked
                    isFieldFiltered
                  }
                    dataSource{
                      id,
                        applicationId,
                        applicationVersion,
                        name,
                        description,
                        idDataSourceType,
                        fcr,
                        ucr,
                        uum,
                        fum,
                        objectGroups{
                          idObjeto
                          objetoId
                          objetoIdRoot
                          idObjetoRoot
                          groupId
                          applicationId
                          applicationVersion
                          add
                          edit
                          list
                          delete
                          view
                          import
                          export
                        }
                        iapDataSourceTableAliases{
                            id,
                            dataSourceId,
                            tableName,
                            tableAlias,
                            shortDescription
                            idIcon,
                            join,
                            isInput,
                            fcr,
                            ucr,
                            uum,
                            fum

                        },
                        iapDataSourceDataBase{
                          id
                          dataSourceId
                          idBaseDatos
                          idDataBaseType
                          querySelect
                          queryFrom
                          queryWhere
                          queryOrderBy
                          queryGroupBy
                          queryHaving
                          filterDistinct
                          fcr
                          ucr
                          uum
                          fum
                        },
                        iapDataSourceService{
                          dataSourceId
                          idHttpMethodType
                          idAuthType
                          url
                          fileJson
                          fcr
                          ucr
                          uum
                          fum         
                        },
                        iapDataSourceServiceConfigurations {
                          id,
                          dataSourceId,
                          idServiceConfigurationType,
                          key,            
                          value,
                          description,
                          fcr,
                          ucr
                        },
                        iapDataSourceFields{
                          id,
                          dataSourceId,
                          dataSourceTableAliasId
                          tableName,
                          field,
                          tableNameAlias,
                          fieldAlias,
                          primaryKey,
                          foreignKey,
                          identityColumn,
                          dataSourceLookUpId
                          position,
                          defaultValue,
                          nullable,
                          encrypted
                          sqlType,
                          length,
                          precision,
                          idDataType,
                          catalogTypeId,
                          foreignTableName,
                          foreignTableFieldId,
                          foreignTableFieldDescription
                          shortDescription
                          description
                          localizable
                          computed
                          fcr
                          ucr
                          fum
                          uum
                        }
                    }
                }
                          
              }
              iapDataSourceTableAliases{
                  id
                  tableAlias
                  tableName
                  shortDescription
                  idIcon
                  join
                  isInput
              }    
          }

      }
    }
  }
}
`);
