<script setup>
import { onBeforeUnmount, onUpdated, ref, onMounted, computed  } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { useRouter } from 'vue-router';
import debounce from 'lodash.debounce';
import EmailTypeConst from '../../../../domain/Constants/EmailTypeConst';

const emit = defineEmits(['assign', 'spam', 'archive', 'trash', 'changeMailType', 'reply', 'navigate', 'pageChange', 'refresh', 'changeAccount']);

const props = defineProps({
    mails: {
        type: Array
    },
    totalMails: {
        type: Number
    },
    filterDefaultValue: {
        type: Object
    },
    objectId: {
        type: Number,
        default: -1
    },
    idObject: {
        type: Number,
        default: -1
    },
});

const router = useRouter();
const menuRef = ref(null);
const mailTable = ref(null);
const selectedMails = ref([]);
const menuItems = ref([
    { label: 'Archivar', icon: 'pi pi-file', command: () => onSelectedMailsAction('archive') },
    { label: 'Spam', icon: 'pi pi-ban', command: () => onSelectedMailsAction('spam') },
    { label: 'Eliminar', icon: 'pi pi-trash', command: () => onSelectedMailsAction('trash') },
    { label: 'Cambiar cuenta', icon: 'pi pi-users', command: () => onChangeAccount() },
]);

const sortField = ref('receivedDateTime');  // Campo por defecto para ordenar
const sortOrder = ref(-1);  // Orden descendente por defecto

const filterTable = ref({
    fromTo: '',    // Para 'from' y 'to'
    subject: '',
    body: '', 
});

const onPageChange = (event) => {
    const newPage = event.page;
    const rows = event.rows;
    const top = rows;
    const skip = newPage * top;

    // Obtener los valores de los filtros
    const fromToValue = filterTable.value.fromTo?.toLowerCase() || '';
    const subjectValue = filterTable.value.subject?.toLowerCase() || '';
    const bodyValue = filterTable.value.body?.toLowerCase() || '';

    // Emitir los valores de los filtros y la paginación
    emit('pageChange', { 
        searchFilters: { 
            fromTo: fromToValue, 
            subject: subjectValue, 
            body: bodyValue 
        },
        sortField: sortField.value,
        sortOrder: sortOrder.value,
        page: top, 
        skip: skip 
    });
};

const onSearch = debounce(() => {
    const searchFilters = {
        fromTo: filterTable.value.fromTo.toLowerCase() || '',
        subject: filterTable.value.subject.toLowerCase() || '',
        body: filterTable.value.body.toLowerCase() || '',
    };

    // Emitir los valores de búsqueda al componente padre
    emit('pageChange', {
        searchFilters,
        sortField: sortField.value,
        sortOrder: sortOrder.value,
        page: EmailTypeConst.TOP,
        skip: 0,
    });
}, 300);

const refresh = (event) => {
    emit('refresh');
};

const filteredMails = computed(() => {
    const searchValue = filterTable.value.fromTo?.toLowerCase() || '';
    return props.mails.filter(mail => {
        return (
            mail.from?.emailAddress?.name.toLowerCase().includes(searchValue) ||
            mail.subject.toLowerCase().includes(searchValue) ||
            mail.message?.toLowerCase().includes(searchValue)
        );
    });
});

onMounted(() => {
    filterTable.value.fromTo = props.filterDefaultValue || '';
    filterTable.value.subject = '';
    filterTable.value.body = '';

    setEventListenersOnRow('addEventListener');
    
    /*if (props.filterDefaultValue) {
        onSearch();
    }*/
});

onUpdated(async () => {
    await setEventListenersOnRow('removeEventListener');
    await setEventListenersOnRow('addEventListener');
});

onBeforeUnmount(() => {
    setEventListenersOnRow('addEventListener');
});

const setEventListenersOnRow = (type) => {
    const mailRowsRefs = [...mailTable.value.$el.querySelectorAll('tbody > tr')];

    mailRowsRefs.forEach((mailRow) => {
        mailRow[type]('mouseenter', (event) => actionButtonToggle(event, mailRow));
        mailRow[type]('mouseleave', (event) => actionButtonToggle(event, mailRow));
    });
};
const actionButtonToggle = (event, mailRow) => {
    const actionButtonsRef = mailRow.querySelector('.action-buttons');
    const dateRef = mailRow.querySelector('.date-text');

    if (!actionButtonsRef || !dateRef) return;

    if (event.type === 'mouseenter') {
        actionButtonsRef.style.display = 'flex';
        dateRef.style.display = 'none';
    } else {
        actionButtonsRef.style.display = 'none';
        dateRef.style.display = 'flex';
    }
};

const onSelectedMailsAction = (emitType) => {
    if (selectedMails.value.length !== 0) {
        for (const mail of selectedMails.value) {
            clearMaileActions(mail);
        }

        emit(emitType, selectedMails.value);
        selectedMails.value = [];
    }
};

const onChangeAccount = () => {
    emit('changeAccount');
};

const onSingleMailAction = (emitType, mail) => {
    clearMaileActions(mail);

    emit(emitType, mail);

    selectedMails.value = [];
};

const changeMailType = (mailType, mail) => {
    emit('changeMailType', mailType, mail);
};

const onReplyMail = (mail) => {
    emit('reply', mail);
};

const navigate = (item) => {
    const targetSection = item.routerLink || 'compose';
    const emailId = item.emailId;
    emit('navigate', { targetSection, emailId } ); // Emitimos el evento
};

const onNavigateToDetailPage = (id) => {
    navigate({ routerLink: 'detail', emailId: id });
};

const clearMaileActions = (mail) => {
    Object.keys(mail).forEach((key) => {
        if (mail[key] === true) {
            mail[key] = false;
        }
    });
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

const rowClass = (data) => {
    return data.isRead ? '' : 'unread-row'; // Clase 'unread-row' para no leídos
};

const onSort = (event) => {
    sortField.value = sortField.value == 1 ? -1 : 1;
    sortField.value = event.sortField;
    sortOrder.value = event.sortOrder;
    
    emit('pageChange', { 
        searchFilters: {
            fromTo: filterTable.value.fromTo.toLowerCase() || '',
            subject: filterTable.value.subject.toLowerCase() || '',
            body: filterTable.value.body.toLowerCase() || '',
        },
        sortField: sortField.value,
        sortOrder: sortOrder.value,
        page: EmailTypeConst.TOP,
        skip: 0
    });
};

defineExpose({
    onSearch
});

</script>

<template>
    <DataTable ref="mailTable" :value="mails" v-model:selection="selectedMails" filters="filterTable" :rows="EmailTypeConst.TOP" :lazy="true" @sort="onSort" :totalRecords="totalMails" :rowClass="rowClass" paginator @page="onPageChange" :rowsPerPageOptions="[10, 20, 30]" dataKey="id" rowHover :globalFilterFields="['from?.emailAddress?.name', 'subject', 'message']">
        <Column selectionMode="multiple" style="width: 4rem"> </Column>
        <Column style="width: 8rem">
            <template #header>
                <div class="flex -ml-2">
                    <Button type="button" icon="pi pi-refresh" text plain rounded @click="refresh()"></Button>
                    <Button type="button" icon="pi pi-ellipsis-v" class="ml-3" text plain rounded @click="menuRef.toggle($event)"></Button>
                    <Menu ref="menuRef" popup :model="menuItems" class="w-8rem"></Menu>
                </div>
                <div></div>
            </template>
            <template #body="{ data }">
                <td v-if="!data.trash && !data.spam" class="w-4rem">
                    <span class="cursor-pointer" @click="changeMailType('starred', data)">
                        <i class="pi pi-fw text-xl" :class="{ 'pi-star-fill': (data.flag?.flagStatus === 'flagged'), 'pi-star': (data.flag?.flagStatus !== 'flagged') }"></i>
                    </span>
                </td>
                <td v-if="!data.trash && !data.spam" class="w-4rem">
                    <span class="cursor-pointer ml-3" @click="changeMailType('important', data)">
                        <i class="pi pi-fw text-xl" :class="{ 'pi-bookmark-fill': (data.importance === 'high'), 'pi-bookmark': (data.importance !== 'high') }"></i>
                    </span>
                </td>
            </template>
        </Column>
        <Column style="min-width: 4rem">
            <template #body="{ data }">
                <Avatar v-if="!data.image" icon="pi pi-user" shape="circle"></Avatar>
                <Avatar v-else @click="onNavigateToDetailPage(data.id)" :id="data.id" :image="`/demo/images/avatar/${data.image ? data.image : '.png'}`" class="cursor-pointer"></Avatar>
            </template>
        </Column>
        <Column style="min-width: 4rem">
            <template #header>
                <IconField iconPosition="left" class="w-full">
                    <InputText
                        type="text"
                        placeholder="Buscar por remitente o destinatario"
                        @input="onSearch"
                        class="w-full sm:w-auto"
                        v-model="filterTable.fromTo"
                        :disabled="props.filterDefaultValue"
                        style="flex: 1 1 30%; min-width: 150px;"
                    ></InputText>
                </IconField>
            </template>
            <template #body="{ data }">
                <div @click="onNavigateToDetailPage(data.id)" :style="{ minWidth: '12rem' }" class="text-900 font-semibold cursor-pointer">{{ data.from?.emailAddress?.name ? data.from?.emailAddress?.name : data.uniqueSenders[0] }}</div>
            </template>
        </Column>
        <Column style="min-width: 30rem">
            <template #header>
                <IconField iconPosition="left" class="w-full">
                    <InputText
                        type="text"
                        placeholder="Buscar por asunto"
                        @input="onSearch"
                        class="w-full sm:w-auto"
                        v-model="filterTable.subject"
                        style="flex: 1 1 30%; min-width: 150px;"
                    ></InputText>
                </IconField>
            </template>
            <template #body="{ data }">
                <span @click="onNavigateToDetailPage(data.id)" class="font-medium white-space-nowrap overflow-hidden text-overflow-ellipsis block cursor-pointer" style="max-width: 30rem">
                    {{ data.subject ? data.subject : data.topic }}
                </span>
            </template>
        </Column>
        <Column field="receivedDateTime" :sortable="true">
            <template #header>
                <IconField iconPosition="left" class="w-full">
                    <InputText
                        type="text"
                        placeholder="Buscar en el cuerpo"
                        @input="onSearch"
                        class="w-full sm:w-auto"
                        v-model="filterTable.body"
                        style="flex: 1 1 30%; min-width: 150px;"
                    ></InputText>
                </IconField>
            </template>
            <template #body="{ data }">
                <div :style="{ minWidth: '10rem' }">
                    <div class="flex justify-content-end w-full px-0">
                        <span ref="dateRef" class="date-text text-700 font-semibold white-space-nowrap">{{ formatDate(data.receivedDateTime ? data.receivedDateTime : data.lastDeliveredDateTime) }}</span>
                        <div ref="actionRef" class="action-buttons" style="display: none">
                            <Button v-if="objectId && idObject" @click="onSingleMailAction('assign', data)" type="button" icon="pi pi-bolt" class="h-2rem w-2rem mr-2" v-tooltip.top="'Asignar'"></Button>
                            <Button @click="onSingleMailAction('archive', data)" type="button" icon="pi pi-inbox" class="h-2rem w-2rem mr-2" v-tooltip.top="'Archivar'"></Button>
                            <Button @click="onReplyMail(data)" type="button" icon="pi pi-reply" class="h-2rem w-2rem mr-2" severity="secondary" v-tooltip.top="'Responder'"></Button>
                            <Button @click="onSingleMailAction('trash', data)" type="button" v-tooltip.top="'Eliminar'" icon="pi pi-trash" class="h-2rem w-2rem" severity="danger"></Button>
                        </div>
                    </div>
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<style >
.unread-row {
    font-weight: bold !important;
    background-color: #f0f8ff !important; /* Color de fondo para correos no leídos */
}
</style>