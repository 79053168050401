<script setup>
import { ref, onMounted, watch, computed } from 'vue';

const content = ref({});
const newMail = ref({
    message: '' // Inicia con un mensaje vacío
});

const emit = defineEmits(['save', 'update:dialogVisible']);
const props = defineProps({
    dialogVisible: {
        type: Boolean,
        default: false
    },
    mailDetail: {
        type: Object,
        default: null
    }
});

const localDialogVisible = ref(false);

onMounted(() => {
    updateContent();
});

watch(
    () => props.mailDetail,
    () => {
        updateContent();
    }
);

const originalMessageSeparator = 
    '<br><br>' +
    '--- Original Message ---' +
    //'<br><strong>From:</strong>' + content.value.from.emailAddress.name +  ' ' + content.value.from.emailAddress.address +
    //'<br><strong>Date:</strong>' + content.value.date +
    //'<br><strong>To:</strong>' + content.value.toRecipients.map(recipient => recipient.emailAddress.address).join(', ') +
    //'<br><strong>Subject:</strong>' + content.value.subject +
    '<br><br>';
    
const updateContent = () => {debugger
    let transformedMailDetail = {...props.mailDetail };
    content.value = transformedMailDetail;

    if (isGroupMail.value) {
        // Transformar correo de grupo a correo estándar
        if (props.mailDetail) {
            transformedMailDetail = {
                from: {
                    emailAddress: {
                        address: props.mailDetail.uniqueSenders[0],
                        name: 'Grupo'
                    }
                },
                subject: 'Re: ' + (props.mailDetail.topic || ''),
                body: {
                    content: originalMessageSeparator + (props.mailDetail.preview || '')
                },
                toRecipients: [],
                cc: []
            };
        }
    } else {
        transformedMailDetail.subject = 'Re: ' + transformedMailDetail.subject;
        transformedMailDetail.body.content = originalMessageSeparator + transformedMailDetail.body.content;
    }
    content.value = transformedMailDetail;
};

const sendMail = () => {
    const replyMail = setMailAction();
    emit('save', props.mailDetail.id, replyMail);
};

const isGroupMail = computed(() => {
    return content.value && content.value.subject === undefined;
});

const setMailAction = () => {debugger
    return {
        ...content.value,
        id: generateId(),
        toRecipients: content.value.from.emailAddress.address,
        ccRecipients: content.value.cc,
        subject: content.value.subject,
        body: newMail.value.message, // Usamos el contenido actualizado aquí
        sent: true,
        archived: false,
        trash: false,
        spam: false,
        starred: false,
        important: false,
        date: generateDate()
    };
};

const generateId = () => {
    let text = '';
    let possible = '0123456789';
    for (let i = 0; i < 5; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};

const generateDate = () => {
    return new Date().toDateString().split(' ').slice(1, 4).join(' ');
};

// Escuchar el evento input para capturar cambios en el div editable
const updateMessageContent = (event) => {
    newMail.value.message = event.target.innerHTML; // Actualiza el contenido con lo que se edite en el div
};
</script>

<template>
    <Dialog v-model:visible="localDialogVisible" header="New Message" modal class="mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6" contentClass="border-round-bottom border-top-1 surface-border p-0">
        <div class="p-0 m-0">
            <div class="surface-section grid grid-nogutter formgrid flex-column md:flex-row gap-6 p-5 border-round">
                <div class="col">
                    <label for="to" class="block text-900 font-semibold mb-3">Para</label>
                    <IconField iconPosition="left" class="w-full" style="height: 3.5rem">
                        <InputIcon class="pi pi-user" style="left: 1.5rem" />
                        <InputText id="to" type="text" class="w-full pl-7 text-900 font-semibold" style="height: 3.5rem" v-model="content.from.emailAddress.address" />
                    </IconField>
                </div>
                <div class="col">
                    <label for="cc" class="block text-900 font-semibold mb-3">CC</label>
                    <IconField iconPosition="left" class="w-full" style="height: 3.5rem">
                        <InputIcon class="pi pi-users" style="left: 1.5rem" />
                        <InputText id="cc" type="text" class="w-full pl-7 text-900 font-semibold" style="height: 3.5rem" v-model="content.cc" />
                    </IconField>
                </div>
                <div class="col">
                    <label for="subject" class="block text-900 font-semibold mb-3">Asunto</label>
                    <IconField iconPosition="left" class="w-full" style="height: 3.5rem">
                        <InputIcon class="pi pi-pencil" style="left: 1.5rem" />
                        <InputText id="subject" type="text" class="w-full pl-7 text-900 font-semibold" style="height: 3.5rem" v-model="content.subject" />
                    </IconField>
                </div>

                <!-- Campo de contenido editable -->
                <div class="col-12 field">
                    <div class="border-1 surface-border border-round p-4" contenteditable="true"
                        @input="updateMessageContent"
                        v-html="content.body.content">
                    </div>
                </div>
            </div>

            <div class="flex column-gap-3 justify-content-end p-5 border-top-1 surface-border">
                <Button type="button" outlined icon="pi pi-image"></Button>
                <Button type="button" outlined icon="pi pi-paperclip"></Button>
                <Button type="button" class="h-3rem" icon="pi pi-send" label="Responder" @click="sendMail()"></Button>
            </div>
        </div>
    </Dialog>
</template>