<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <div v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :key="componentKey">
        
        <CrudDocument ref="crudDocumentControl"
            v-show="loaded && !viewDetail && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE)"
            :rootParentId="Component.rootParentId" :container="container" :Component="Component"
            :id="getPropertyValue(CrudDocumentTypeConst.ID)" v-tooltip="getPropertyValue(CrudDocumentTypeConst.TOOLTIP)"
            :style="getPropertyValue(CrudDocumentTypeConst.STYLE)" :class="getPropertyValue(CrudDocumentTypeConst.CLASS)"
            :name="getPropertyValue(CrudDocumentTypeConst.NAME)"            
            :disabled="getPropertyBooleanValue(CrudDocumentTypeConst.DISABLED)"
            :visible="getPropertyBooleanValue(CrudDocumentTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
            :placeholder="getPropertyValue(CrudDocumentTypeConst.PLACEHOLDER)"
            :caption="getPropertyValue(CrudDocumentTypeConst.CAPTION)"
            :fileExtensions="getPropertyValue(CrudDocumentTypeConst.FILEEXTENSIONS)"
            :maxFileSize="getPropertyNumberValue(CrudDocumentTypeConst.MAXFILESIZE)"
            :multiple =getPropertyBooleanValue(CrudDocumentTypeConst.MULTIPLE)
            :idObject ="getPropertyValue(CrudDocumentTypeConst.IDOBJECT)"
            :objectId="getPropertyNumberValue(CrudDocumentTypeConst.OBJECTID)"
            :captionDropArea="getPropertyValue(CrudDocumentTypeConst.CAPTIONDROPAREA)"
            :canAdd="getPropertyBooleanValue(CrudDocumentTypeConst.CANADD) && canDoOperation(ObjectGroupConst.ADD)"
            :canEdit="getPropertyBooleanValue(CrudDocumentTypeConst.CANADD) && canDoOperation(ObjectGroupConst.EDIT)"
            :canDelete="getPropertyBooleanValue(CrudDocumentTypeConst.CANADD) && canDoOperation(ObjectGroupConst.DELETE)"
            :onlyListDocument="getPropertyBooleanValue(CrudDocumentTypeConst.ONLYLISTDOCUMENT)"  
            :showAddinPopUp="getPropertyBooleanValue(CrudDocumentTypeConst.SHOW_POPUP_ADD)"  
            :entityMainIntegracionId="getPropertyNumberValue(CrudDocumentTypeConst.ENT_MAIN_INT_ID)"              
            :source="getPropertyValue(CrudDocumentTypeConst.SOURCE)"
         >
           
        </CrudDocument>
    </div>

   
</template>
<script lang="ts">


import { defineComponent, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import CrudDocumentTypeConst from '../../../../domain/Constants/CrudDocumentTypeConst';
import CrudDocument from '../../../../../../builderDocument/document/infrastructure/component/CrudDocument.vue'
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_crud_document',
    components: {
        CrudDocument,
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
       
        slotProps: {
            type: Object,
            default: () => ({})
        },
      
    },
    setup(props, context) {
        //
        const store = useStore();

        const { canDoOperation, vmodel, loaded, getPropertyValue,
             getPropertyBooleanValue, getPropertyNumberValue, setPropertyValueByName, getProperty, 
             updateDictionary,updateDictionaryFromRow, dictionaryKeys, resolveExpressions, markUnloadComponents,
              baseOnBeforeUnmount, getLayoutComponent, fieldKey, fieldKeyComponentDataSourceId,
               processEventComponent, processEvent,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);

        

        
        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })


        return {          
            getPropertyValue,
            getPropertyBooleanValue,
            getPropertyNumberValue,
            vmodel,
            CrudDocumentTypeConst,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
        };
    },
});
</script>
<style scoped></style>
