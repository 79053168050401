import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_DetailCrud = _resolveComponent("DetailCrud")!
  const _component_CrudTable = _resolveComponent("CrudTable")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.loaded && _ctx.viewDetail && _ctx.getPropertyValue(_ctx.CrudTableTypeConst.SELECTEDROW))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_DetailCrud, {
                  container: _ctx.container,
                  componentId: _ctx.componentInputId,
                  applicationId: _ctx.Component.applicationId,
                  applicationVersion: _ctx.Component.applicationVersion,
                  attrsInput: _ctx.componentAttributesList,
                  tabMode: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.SHOWTABMODE),
                  onBackToList: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backToList()))
                }, {
                  header: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "header")
                  ]),
                  buttons: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "buttons")
                  ]),
                  detail: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "detail")
                  ]),
                  _: 3 /* FORWARDED */
                }, 8 /* PROPS */, ["container", "componentId", "applicationId", "applicationVersion", "attrsInput", "tabMode"])
              ]))
            : _createCommentVNode("v-if", true),
          _withDirectives((_openBlock(), _createBlock(_component_CrudTable, {
            ref: "crudTableControl",
            key: _ctx.componentKey,
            container: _ctx.container,
            Component: _ctx.Component,
            id: _ctx.getPropertyValue(_ctx.CrudTableTypeConst.ID),
            style: _normalizeStyle(_ctx.getPropertyValue(_ctx.CrudTableTypeConst.STYLE)),
            class: _normalizeClass(_ctx.getPropertyValue(_ctx.CrudTableTypeConst.CLASS)),
            name: _ctx.getPropertyValue(_ctx.CrudTableTypeConst.NAME),
            disabled: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.DISABLED),
            visible: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.VISIBLE),
            placeholder: _ctx.getPropertyValue(_ctx.CrudTableTypeConst.PLACEHOLDER),
            showBtnSearch: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.SHOWBTNSEARCH),
            showBtnClear: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.SHOWBTNCLEAR),
            showBtnCloseTabs: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.SHOWBTNCLOSETABS),
            showPaginator: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.SHOWPAGINATE),
            showSaveSearch: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.SHOWSAVESEARCH),
            advancedSearch: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.ADVANCEDSEARCH),
            lazy: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.LAZY),
            lazyScroll: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.LAZYSCROLL),
            editAllExceptKeys: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.EDITALLEXCEPTKEYS),
            maxRegs: _ctx.getPropertyNumberValue(_ctx.CrudTableTypeConst.MAXREGS),
            canAddNewItem: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.CANADDNEWITEM) && _ctx.canDoOperation(_ctx.ObjectGroupConst.ADD),
            canImport: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.CANIMPORT) && _ctx.canDoOperation(_ctx.ObjectGroupConst.ADD),
            canExport: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.CANEXPORT) && _ctx.canDoOperation(_ctx.ObjectGroupConst.EXPORT),
            canUpdateMassive: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.CANUPDATEMASSIVE)  && _ctx.canDoOperation(_ctx.ObjectGroupConst.EDIT),
            canSelectMultiple: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.CANSELECTMULTIPLE),
            showActionMenu: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.SHOWACTIONMENU) && _ctx.canDoOperation(_ctx.ObjectGroupConst.DETAIL),
            showSearch: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.SHOWSEARCH),
            showList: _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
            showActionDetail: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.SHOWACTIONDETAIL) && _ctx.canDoOperation(_ctx.ObjectGroupConst.DETAIL),
            showPaginate: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.SHOWPAGINATE),
            showHeaderDetail: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.SHOWHEADERDETAIL),
            canDeleteItem: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.CANDELETEITEM)  && _ctx.canDoOperation(_ctx.ObjectGroupConst.DELETE),
            customAddNew: _ctx.customAddNew,
            canClearAllFilters: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.CANCLEARALLFILTER),
            showTabMode: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.SHOWTABMODE),
            showResultTabMode: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.SHOWTABMODERESULT),
            tabHeader: _ctx.getPropertyValue(_ctx.CrudTableTypeConst.TABHEADER),
            layoutDetailComponentId: (_ctx.getLayoutDetailComponentId ?? -1),
            filterDisplay: _ctx.getCatalogValue(_ctx.CrudTableTypeConst.FILTERDISPLAY),
            slotProps: _ctx.slotProps,
            setupImport: _ctx.getPropertyValue(_ctx.CrudTableTypeConst.SETUP_IMP),
            rowGroupMode: _ctx.getCatalogValue(_ctx.CrudTableTypeConst.ROWGROUPMODE ),
            groupRowsBy: _ctx.getPropertyValue(_ctx.CrudTableTypeConst.GROUPROWSBY),
            expandableRowGroups: _ctx.getPropertyBooleanValue(_ctx.CrudTableTypeConst.EXPANDABLEROWGROUPS ),
            "onClick:viewDetail": _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateSelectedRow($event, true))),
            "onClick:SelectRow": _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateSelectedRow($event, false))),
            "onUpdate:SelectMultipleRow": _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateSelectMultipleRow($event, true))),
            "onClick:addCustom": _ctx.openCustomNew,
            "onEnd:import": _ctx.raiseEndImportCommand,
            "onBegin:import": _ctx.raiseStartImportCommand
          }, {
            header: _withCtx(() => [
              _renderSlot(_ctx.$slots, "header")
            ]),
            buttons: _withCtx(() => [
              _renderSlot(_ctx.$slots, "buttons")
            ]),
            detail: _withCtx(() => [
              _renderSlot(_ctx.$slots, "detail")
            ]),
            buttonsresult: _withCtx(() => [
              _renderSlot(_ctx.$slots, "buttonsresult")
            ]),
            _: 3 /* FORWARDED */
          }, 8 /* PROPS */, ["container", "Component", "id", "style", "class", "name", "disabled", "visible", "placeholder", "showBtnSearch", "showBtnClear", "showBtnCloseTabs", "showPaginator", "showSaveSearch", "advancedSearch", "lazy", "lazyScroll", "editAllExceptKeys", "maxRegs", "canAddNewItem", "canImport", "canExport", "canUpdateMassive", "canSelectMultiple", "showActionMenu", "showSearch", "showList", "showActionDetail", "showPaginate", "showHeaderDetail", "canDeleteItem", "customAddNew", "canClearAllFilters", "showTabMode", "showResultTabMode", "tabHeader", "layoutDetailComponentId", "filterDisplay", "slotProps", "setupImport", "rowGroupMode", "groupRowsBy", "expandableRowGroups", "onClick:addCustom", "onEnd:import", "onBegin:import"])), [
            [_vShow, _ctx.loaded && !_ctx.viewDetail && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE)],
            [_directive_tooltip, _ctx.getPropertyValue(_ctx.CrudTableTypeConst.TOOLTIP)]
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.opNewRegister && _ctx.canDoOperation(_ctx.ObjectGroupConst.ADD))
      ? (_openBlock(), _createBlock(_component_Dialog, {
          key: 2,
          ref: "slotControls",
          visible: _ctx.opNewRegister,
          "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.opNewRegister) = $event)),
          header: "Añadir registro",
          maximizable: "",
          modal: "",
          styleClass: "mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6",
          contentStyleClass: "border-round-bottom border-top-1 surface-border p-0",
          onHide: _ctx.hideCustomNew
        }, {
          footer: _withCtx(() => [
            _createVNode(_component_Button, {
              id: "guardar",
              label: "Guardar",
              icon: "pi pi-save",
              class: "mr-2 p-button-rounded p-button-primary p-button-outlined",
              onClick: _ctx.saveCustomForm
            }, null, 8 /* PROPS */, ["onClick"])
          ]),
          default: _withCtx(() => [
            (_ctx.opNewRegister)
              ? _renderSlot(_ctx.$slots, "new", { key: 0 })
              : _createCommentVNode("v-if", true)
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["visible", "onHide"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}