/* eslint-disable hexagonal-architecture/enforce */
/* eslint-disable hexagonal-architecture/enforce */

import { createApp, h } from 'vue'
import MainWrapper from '@ilinium/shared/src/common/infrastructure/funciones/HelperMainWrapper'
import AppWrapper from './AppWrapper.vue'
//import { initMenus } from './infrastructure/data/DataMenus'
import router from './router'
import TemplateTypeConst from '@ilinium/shared/src/common/domain/constantes/TemplateTypeConst'
import { container } from './infrastructure/container'
import routes from './router'
import store from '@ilinium/shared/src/common/infrastructure/almacen'
import HelperLogin from '@ilinium/shared/src/entidades/builderMaster/auth/infrastructure/component/HelperLogin'
import Environment from '@ilinium/shared/src/common/infrastructure/funciones/environment'
import helperCatalog, { getCatalogsFromService } from '@ilinium/shared/src/entidades/builder/catalog/infrastructure/helper/helperCatalog';

export const app = createApp({
  render () {
    return h(AppWrapper)
  }
})


// esto se usa para cuando se pulsa F5
if (store.getters.getRoutes) {
  getCatalogsFromService(container, Environment.APPID, Environment.APPVERSION).then(response => {})
  HelperLogin.getApplicationMenus(false, routes, Environment.APPID, Environment.APPVERSION, container, window.location.pathname.substring(1), true);
}



app.use(router)
MainWrapper.init(app, [])



