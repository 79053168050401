
<template>

    <SummaryDebug v-if="getPropertyBooleanValue(TreeTableTypeConst.DEBUG,Component)"
                :data="Component"
                :vmodel="vmodel"

                />

        <TreeTable
            v-if ="loaded && (getPropertyBooleanValue(TreeTableTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW))" 
            :key="componentKey"
            :id="getPropertyValue(TreeTableTypeConst.ID)" 
            v-tooltip="getPropertyValue(TreeTableTypeConst.TOOLTIP)"
            :style="getPropertyValue(TreeTableTypeConst.STYLE)"
            :class="{[getPropertyValue(TreeTableTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted ,'p-readonly': getPropertyBooleanValue(TreeTableTypeConst.READONLY),'p-tree':true}"
            :name="getPropertyValue(TreeTableTypeConst.NAME)"
            :disabled="getPropertyBooleanValue(TreeTableTypeConst.DISABLED) || (!canEdit)"
            :visible="getPropertyBooleanValue(TreeTableTypeConst.VISIBLE)"
            :placeholder="getPropertyValue(TreeTableTypeConst.PLACEHOLDER)"
            :readonly="getPropertyBooleanValue(TreeTableTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
            :required="getPropertyBooleanValue(TreeTableTypeConst.REQUIRED)"
            :tabIndex="getPropertyNumberValue(TreeTableTypeConst.TABINDEX)" 

            :value = "getValue()"
            :selectionMode = "getSelectionMode()"
            :metaKeySelection = "getPropertyBooleanValue(TreeTableTypeConst.METAKEYSELECTION)"  
            :expandedKeys = "expandedKey ?? undefined" 
            :selectionKeys = "selectedKey ?? undefined" 

            :rows = "getPropertyNumberValue(TreeTableTypeConst.ROWS)"
            :first = "getPropertyNumberValue(TreeTableTypeConst.FIRST) ?? 0"
            :totalRecords = "getPropertyNumberValue(TreeTableTypeConst.TOTALRECORDS)"
            :paginator = "getPropertyBooleanValue(TreeTableTypeConst.PAGINATOR)" 
            :paginatorPosition = "getPaginatorPosition()"
            :alwaysShowPaginator = "getPropertyBooleanValue(TreeTableTypeConst.ALWAYSSHOWPAGINATOR)"
            :paginatorTemplate = "getPropertyValue(TreeTableTypeConst.PAGINATORTEMPLATE)"
            :pageLinkSize = "getPropertyNumberValue(TreeTableTypeConst.PAGELINKSIZE) ?? 5"
            

            :lazy = "getPropertyBooleanValue(TreeTableTypeConst.LAZY)"
            :loading="getPropertyBooleanValue(TreeTableTypeConst.LOADING)"
            :loadingIcon="getPropertyValue(TreeTableTypeConst.LOADINGICON)"
            :loadingMode="getLoadingMode()"

            :rowHover = "getPropertyBooleanValue(TreeTableTypeConst.ROWHOVER)"
            :autoLayout = "getPropertyBooleanValue(TreeTableTypeConst.AUTOLAYOUT)"
            :sortField = "getPropertyValue(TreeTableTypeConst.SORTFIELD)"
            :sortOrder = "getPropertyNumberValue(TreeTableTypeConst.SORTORDER)"
            :defaultSortOrder = "getPropertyNumberValue(TreeTableTypeConst.DEFAULTSORTORDER) ?? 1"
          
            :sortMode = "getSortMode()"
            :removableSort = "getPropertyBooleanValue(TreeTableTypeConst.REMOVABLESORT)"

            :filters = "getPropertyBooleanValue(TreeTableTypeConst.FILTERS) ?? false" 
            :filterMode = "getFilterMode()"

            :resizableColumns = "getPropertyBooleanValue(TreeTableTypeConst.RESIZABLECOLUMNS)"
            :columnResizeMode = "getColumnResizeMode()"
            :indentation = "getPropertyNumberValue(TreeTableTypeConst.INDENTATION) ?? 1"
            :showGridlines = "getPropertyBooleanValue(TreeTableTypeConst.SHOWGRIDLINES)"
            :scrollable = "getPropertyBooleanValue(TreeTableTypeConst.SCROLLABLE)"

            :scrollHeight = "getPropertyValue(TreeTableTypeConst.SCROLLHEIGHT)"

            :size = "getSize()"
            :tableStyle = "getPropertyValue(TreeTableTypeConst.TABLESTYLE)"
            :tableClass = "getPropertyValue(TreeTableTypeConst.TABLECLASS)"

            @update:expanded-keys="updateExpandedKeys"
            @update:selection-keys="updateSelectionKeys"
            
            @node-select="onNodeSelect"
            @node-unselect="onNodeUnSelect"
            @node-expand="onNodeExpand"
            @node-collapse="onNodeCollapse"

            v-model="arrayModel"
            @change ="setSelectedKeyToVModel()"
           
            >
            
            <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" :expander="col.expander"></Column>
            <!--<Column key="name" field="name" header="Name" expander style="width: 34%"></Column>-->
            <!--<Column key="name" field="size" header="Size" style="width: 33%"></Column>-->
            <!--<Column key="name" field="type" header="Type" style="width: 33%"></Column>-->

        </TreeTable>
        <CustomValidate v-if="loaded && getPropertyBooleanValue(TreeTableTypeConst.VISIBLE)" v-model:submitted="submitted" v-model:vObject="v$" />
    
        
        <div v-if="false" class="row">
            <div class="col-md-12">
                <label style="color:red;padding-left: 5px;">vmodel:  {{ vmodel == null ? 'null' : vmodel }} </label><br/>
                <label style="color:blue;padding-left: 5px;" for="">arrayModel:  {{ arrayModel == null ? 'null' : arrayModel }} </label><br/>
                <label style="color:orangered;padding-left: 5px;">SelectedKey:  {{ selectedKey == null ? 'null' : selectedKey }} </label><br/>
                <label style="color:green;padding-left: 5px;">ExpandedKey:  {{ expandedKey == null ? 'null' : expandedKey }} </label>
            </div>
        </div>
        
        
    </template>
    
    <script lang="ts">
    
    import { defineComponent, onMounted, ref, onBeforeUnmount } from 'vue';
    import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
    import TreeTableTypeConst from '../../../../domain/Constants/TreeTableTypeConst';
    import { Container } from 'inversify';
    import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
    import CustomValidate from '../../shared/CustomValidate.vue';
    import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
    import { useStore } from 'vuex';	
    import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
    import SummaryDebug from '../../shared/SummaryDebug.vue';
    //import TreeTable from 'primevue/treetable';
    //import Column from 'primevue/column';
    
    export default defineComponent({
        name: 'dynamic_tree_table',
        emits: ['node-expand', 'node-collapse', 'update:expandedKeys', 'update:selectionKeys', 'node-select', 'node-unselect'],
        components: {
            CustomValidate,
            SummaryDebug
        },
        props:
        {
            container: {
                type: Object as () => Container
            },
            Component: {
                type: Object as () => ComponentDataForm,
                default: () => ({})
            },

            slotProps: {
                type: Object,
                default: () => ({})
            },

        },
        setup(props, context) {
            const arrayModel = ref([]);
            const store = useStore();
            
            const setVModelToArray = () =>{    
                arrayModel.value = (vmodel.value ?? '').split(',').filter(x => x !== '') as []
            }
    
            const { vmodel, canDoOperation, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue
                , getPropertyValueOptions, getPropertyValueObjectOrFile, getCatalogValue
                , loaded, baseOnMounted, baseOnBeforeUnmount, v$, submitted, canEdit
                , isValidData, fieldKey, fieldKeyComponentDataSourceId, processEventComponent 
                ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store,setVModelToArray)
            
    
      
            const expandedKey = ref(null);
            const selectedKey = ref(null);
            const columns = ref([
                { field: 'name', header: 'Name', expander: true },
                { field: 'size', header: 'Size' },
                { field: 'type', header: 'Type' }
            ]);

      
            const setSelectedKeyToVModel = () => {
                if ((Object.keys(selectedKey.value ?? {})).length > 0) {
    
                    if (getSelectionMode() == 'single'){
                        vmodel.value = Object.keys(selectedKey.value ?? {})[0]
                    }
                    else{
                        vmodel.value = JSON.stringify((Object.keys(selectedKey.value ?? {})).join(','))
                    }
                    
                }
                else {
                    vmodel.value = null
                }
                processEventComponent(CatalogEventConst.CHANGE)
            }
    
    
            onMounted(() => {
                baseOnMounted();
                getColumnsDef();
            })
    
            onBeforeUnmount(() => {
                baseOnBeforeUnmount();
            })
    
    
            const getColumnsDef = ()  => {
                let coldef = [] as any;
                coldef = getPropertyValue(TreeTableTypeConst.COLUMNSDEF);

                try { 
                    columns.value = JSON.parse(coldef?.replace(/\'/g,'\"'));
                } 
                catch (e) { 
                    columns.value = [
                        {field:'name',header:'Name',expander:true},
                        {field:'size',header:'Size'},
                        {field:'type',header:'Type'}
                    ];
                }
            }

            const getValue = () : any[] | undefined => {
                let values = getPropertyValueObjectOrFile(TreeTableTypeConst.DATAVALUE, TreeTableTypeConst.DATAFILEVALUE, TreeTableTypeConst.DATASOURCEVALUE, true);

                if (values == null) return undefined;
                if (Array.isArray(values)) {
                    if (values.length>0) {
                        return values;
                    } else {
                        return undefined;
                    }
                }

                // ZERO WIDTH NO-BREAK SPACE
                values = values?.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g,"");
                // Carriage Return and Line Feed
                values = values?.replace(/(\r\n|\n|\r)/gm,"");
                //
                values = values?.replace(/\'/g,'\"');

                let nodes:any = '';
                try { 
                    nodes = JSON.parse(values); 
                } 
                catch (e) { 
                    nodes = values; 
                }

                return nodes;
            }
    
            const getValueStatic = () : any[] | undefined => {
                const nodes = ref();
                let files = [] as any;

                for (let i = 0; i < 5; i++) {
                    let node = {
                        key: i,
                        data: {
                            name: 'Item ' + i,
                            size: Math.floor(Math.random() * 1000) + 1 + 'kb',
                            type: 'Type ' + i
                        },
                        children: [
                            {
                                key: i + ' - 0',
                                data: {
                                    name: 'Item ' + i + ' - 0',
                                    size: Math.floor(Math.random() * 1000) + 1 + 'kb',
                                    type: 'Type ' + i
                                }
                            }
                        ]
                    } as never;

                    files.push(node);
                }

                nodes.value = files;
                return nodes.value;
            }

            const getSelectionMode = () : "multiple" | "checkbox" | "single" => {
                const value = getCatalogValue(TreeTableTypeConst.SELECTIONMODE)
                const mode: "multiple" | "checkbox" | "single" = value as "multiple" | "checkbox" | "single";
                return mode ?? 'multiple';
            }

            const getPaginatorPosition = () : "top" | "bottom" | "both" => {
                const value = getCatalogValue(TreeTableTypeConst.PAGINATORPOSITION)
                const mode: "top" | "bottom" | "both" = value as "top" | "bottom" | "both";
                return mode ?? 'bottom';
            }            

            const getSortMode = () : "single" | "multiple" => {
                const value = getCatalogValue(TreeTableTypeConst.SORTMODE)
                const mode: "single" | "multiple" = value as "single" | "multiple";
                return mode ?? 'single';
            }     

            const getColumnResizeMode = () : "fit" | "expand" => {
                const value = getCatalogValue(TreeTableTypeConst.SIZE)
                const mode: "fit" | "expand" = value as "fit" | "expand";
                return mode ?? 'fit';
            }  

            const getSize = () : "large" | "small" => {
                const value = getCatalogValue(TreeTableTypeConst.SIZE)
                const mode: "large" | "small" = value as "large" | "small";
                return mode;
            }   

            const getFilterMode = () : "strict" | "lenient"  => {
                const value = getCatalogValue(TreeTableTypeConst.FILTERMODE)
                const mode: "strict" | "lenient" = value as "strict" | "lenient";
                return mode ?? 'lenient';
            }
    
            const getLoadingMode = () : "mask" | "icon"  => {
                const value = getCatalogValue(TreeTableTypeConst.LOADINGMODE)
                const mode: "mask" | "icon" = value as "mask" | "icon";
                return mode ?? 'mask';
            }
    
            const onNodeExpand = (node : any) => {
                context.emit('node-expand', node);
            }
            const onNodeCollapse = (node : any) => {
                context.emit('node-collapse', node);
            }
            const onNodeSelect = (node : any) => {
                context.emit('node-select', node);
            }
            const onNodeUnSelect = (node : any) => {
                context.emit('node-unselect', node);
            }
            const updateExpandedKeys = (key : any) => {
                expandedKey.value = key;
                context.emit('update:expandedKeys', key);
            }
            const updateSelectionKeys = (key : any) => {
                selectedKey.value = key;
    
                arrayModel.value = [];
                if ((Object.keys(selectedKey.value ?? {})).length > 0) {
                    (Object.keys(selectedKey.value ?? {})).forEach(element => {
                        arrayModel.value.push(element as never);
                    });
                }
    
                setSelectedKeyToVModel();
                context.emit('update:selectionKeys', key);
            }
    
    
            return {
         
                getPropertyValue,
                getPropertyBooleanValue,
                vmodel,
                TreeTableTypeConst,
                getPropertyNumberValue,
                loaded,
                v$,
                submitted,
                canEdit,
                isValidData,
                fieldKey,
                fieldKeyComponentDataSourceId,
                processEventComponent,
                CatalogEventConst,
                ObjectGroupConst,
                canDoOperation,
                getValue,
                getSelectionMode,
                getPaginatorPosition,
                getSortMode,
                getColumnResizeMode,
                getSize,
                getFilterMode,
                getLoadingMode,
                onNodeExpand,
                onNodeCollapse,
                onNodeSelect,
                onNodeUnSelect,
                updateExpandedKeys,
                updateSelectionKeys,
                selectedKey,
                expandedKey,
                arrayModel,
                setSelectedKeyToVModel,
                columns,
                getValueStatic,
                componentKey
            };
        },
    });
    </script>
    <style scoped></style>
    