<template>
    <div class="flex flex-wrap">
        <div :class="{ 'grid ': true, 'w-full xl:w-6': !containerSize, 'w-6': containerSize && (xl), 'w-full': containerSize && !xl, 'mt-1': containerSize && !xl }"
            v-for="(item, index) of dataFilter"
            :key="item?.id"
            :style="getPointerMode(getFieldData(item.fieldId))">
            <div :class="{ 'flex align-content-start col-12 lg:col-4': true }">
                <i :class="tablas.find(x => x.tableName == getFieldData(item.fieldId)?.tableName)?.idIcon ?? 'pi pi-list'"
                    v-tooltip="getFieldData(item.fieldId)?.tableName"
                    class="pl-5 mr-2 flex align-items-center justify-content-center"></i>
                <label class="flex align-items-center justify-content-end" for="">{{ translateFieldLabel(item)
                    }}</label>
            </div>
            <div v-if="isFilterVisible(getFieldData(item.fieldId))"
                :class="{ 'col-12': true, 'lg:col-3': !notShowControl.includes(item.filter), 'lg:col-8': notShowControl.includes(item.filter)}">
                <div class="p-inputgroup flex-2">
                    <Dropdown v-model="item.filter" :options="getCatalogFilterByFieldType(getFieldData(item.fieldId))"
                        optionLabel="description" optionValue="id" @change="updateFiltersValues($event, item)" >
                    </Dropdown>
                    <i v-if="notShowControl.includes(item.filter)"
                        class="pi pi pi-times flex align-items-center text-gray-500 ml-1" style="font-size: 0.75rem"
                        @click="removeQuestion(dataFilter[index].id)"></i>
                </div>
            </div>
            <div v-if="!notShowControl.includes(item.filter)"
                :class="{ 'col-12 lg:col-5': isFilterVisible(getFieldData(item.fieldId)), 'col-12 lg:col-8': !isFilterVisible(getFieldData(item.fieldId)) }">
                <div class="p-inputgroup flex-2">
                    <LookUpEditor v-if="getFieldData(item.fieldId)?.dataSourceLookUpId !== null" v-model="item.value"
                        :dataSourceLookUp="getFieldData(item.fieldId)?.dataSourceLookUp" :catalogsData="catalogosData"
                        :container="container" :filterConditions="[]" :showSearch="true" :showClear="true"></LookUpEditor>
                    <div v-else-if="isType(getFieldData(item.fieldId), SqlTypesConst.DATETIME) || isType(getFieldData(item.fieldId), SqlTypesConst.DATE)"
                        class="flex" style="width:100%">
                        <Calendar
                            v-if="item.filter !== FiltroBusquedaConst.FILTROBUSQUEDA_NONULO && item.filter !== FiltroBusquedaConst.FILTROBUSQUEDA_NULO
                            && item.filter!=FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO"
                            :showButtonBar="true" v-model="item.rangeDateTime.from" :manualInput="true"
                            @contextmenu="showMenuFilter" :ref="calendarsFromRef[index]" />
            
                        <Calendar v-if="dateFilter.includes(item.filter) && item.filter!=FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO" :showButtonBar="true"
                            v-model="item.rangeDateTime.to" :manualInput="true" @contextmenu="showMenuFilter"
                            class="ml-1" :ref="calendarsToRef[index]" @input="setDateFilterValues({value:item.filter},index)" @date-select="setDateFilterValues({value:item.filter},index)"/>
                        <InputNumber v-if="item.filter==FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO" id="inputcalen" mode="decimal"
                            v-model="item.valueNumber" showButtons :format="false" :inputStyle="'width: 30px'" placeholder="Días..." @update:modelValue="setDateFilterValues({value:item.filter},index)" @input="setDateFilterValues({value:item.filter},index)"/>
                    </div>
                    <MultiSelect
                        v-else-if="isType(getFieldData(item.fieldId), SqlTypesConst.VARCHAR) && isCatalogType(getFieldData(item.fieldId))"
                        v-model="item.valueList"
                        :options="getCatalogData(getFieldData(item.fieldId)?.catalogTypeId ?? '')" :filter="true"
                        optionLabel="description" optionValue="id" style="width:100%" @contextmenu="showMenuFilter" />
                    <InputSwitch v-else-if="isType(getFieldData(item.fieldId), SqlTypesConst.BIT)" v-model="item.value"
                        class="mr-2" @contextmenu="showMenuFilter" :trueValue="'1'" :falseValue="'0'" />
                    <InputText v-else style="width:100%" v-model="item.value"
                        :maxlength="getFieldData(item.fieldId)?.length ?? 25" v-on:keyup.enter="$emit('search')"
                        @contextmenu="showMenuFilter" >
                    </InputText>

                    <i class="pi pi pi-times flex align-items-center text-gray-500 ml-1 mr-1" style="font-size: 0.75rem"
                        @click="removeQuestion(dataFilter[index].id)"></i>
                </div>

            </div>

        </div>

    </div>
    <Message v-if="dataFilter.length == 0" :closable="false">No hay filtros seleccionados</Message>

    <ContextMenu ref="menuFilter" :model="dataContext" />

</template>
<script lang="ts">

import { defineComponent, ref, onMounted, watch,onBeforeMount,computed } from 'vue';
import { SearchData } from '../../../search/domain/search';
import { Container } from 'inversify';
import FiltroBusquedaConst from '../../../../../common/domain/constantes/FiltroBusquedaConst';
import CatalogTypeConst from '../../../../../common/domain/constantes/CatalogTypeConst';
import SqlTypesConst from '../../../../../common/domain/constantes/SqlTypesConst';
import { IapComponentDataSource } from '../../../component/domain/iapComponentDataSource';
import { IapCatalog } from '../../../catalog/domain/iapCatalog';
import { useI18n } from 'vue-i18n';
import { messages } from './localization/MessagesBusqueda'
import { useStore } from 'vuex';
import DataSourceComp from '../functions/dataSourceComp';
import { IapDataSourceField } from '../../../datasource/domain/iapDataSourceField';
import HelperCommon from '../../../../../common/infrastructure/funciones/HelperCommon';

export default defineComponent({
    name: 'search_fields',
    emits: ['search'],
    props: {
        container: {
            type: Object as () => Container
        },
        modelValue: {
            type: Object as () => Partial<SearchData>[],
            default: () => ([])
        },
        compDataSource: {
            type: Object as () => IapComponentDataSource,
            default: () => ({})
        },
        catalogosData: {
            type: Object as () => IapCatalog[],
            default: () => ([])
        },
        fixCalendar: {
            type: Boolean,
            required: true
        },
        dataContext: {
            type: Object as () => any[],
            default: () => ([])
        },

    },

    setup(props, { emit }) {

        const { t, locale } = useI18n(messages)
        const store = useStore();
        const { dataFilter, isType, isCatalogType,
            isFilterVisible,
            getCatalogData,
            tablas,
            dataBaseId,
            getFieldData
        } = DataSourceComp(props.container as any, props, emit, t, props.compDataSource, props.catalogosData, store)
        const calendarsFromRef = ref(new Array());
        const calendarsToRef = ref(new Array());
        const containerSize = ref();
        const menuFilter = ref();
        const lg = ref(false);
        const md = ref(false);
        const xl = ref(false);
        const sm = ref(false);
        const dateFilter:string[]=[FiltroBusquedaConst.FILTROBUSQUEDA_ENTRE,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTMONTH,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTYEAR,
            FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSMONTH,FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSYEAR,FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSWEEK,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTWEEK,
            FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO
            ];
        const notShowControl:string[]=[FiltroBusquedaConst.FILTROBUSQUEDA_NONULO,FiltroBusquedaConst.FILTROBUSQUEDA_NULO,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTMONTH,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTYEAR,
           FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSMONTH,FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSYEAR,FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSWEEK,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTWEEK,
            ];    

        const fixCalendarComp = computed(() => props.fixCalendar);

            watch(
                fixCalendarComp,
            (newValue, oldValue) => {
                if(fixCalendarComp && fixCalendarComp.value==true){
                    assignCalendarsRef();
                }
                
            }
         );

        const translateFieldLabel = (item: any) => {

            const field = getFieldData(item.fieldId);

            if (!HelperCommon.isNullOrWhitespace(field?.shortDescription ?? '')) {
                return field?.shortDescription;
            }
            else if (!HelperCommon.isNullOrWhitespace(field?.fieldAlias ?? '')) {
                return field?.fieldAlias;
            }

            return field?.field;
        }


        const getPointerMode = (data: IapDataSourceField): string => {
            if (data) {
                if ('filterValue' in data && data['filterValue'] != null && !props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == data.id)?.defaultFilterSearch) {
                    return 'display: none';
                }
            }

            return '';
        }


        const getCatalogFilterByFieldType = (item: any) => {
            let catalog = getCatalogData(CatalogTypeConst.FILTROBUSQUEDA);

            const text:string[]=[FiltroBusquedaConst.FILTROBUSQUEDA_CONTIENE,FiltroBusquedaConst.FILTROBUSQUEDA_NOTCONTAINS,FiltroBusquedaConst.FILTROBUSQUEDA_STARTWITH
                ,FiltroBusquedaConst.FILTROBUSQUEDA_ENDWITH
            ];
            const num:string[]=[FiltroBusquedaConst.FILTROBUSQUEDA_GREATTHAN,FiltroBusquedaConst.FILTROBUSQUEDA_LESSTHAN,FiltroBusquedaConst.FILTROBUSQUEDA_GREATTHANEQTO
                ,FiltroBusquedaConst.FILTROBUSQUEDA_LESSTHANEQTO,FiltroBusquedaConst.FILTROBUSQUEDA_DISTINTO
            ];


            if (isType(item, SqlTypesConst.VARCHAR)) {
                catalog = catalog.filter(x =>!num.includes(x.id) && !dateFilter.includes(x.id));
            }

            if (isType(item, SqlTypesConst.INT) || isType(item, SqlTypesConst.MONEY)) {
                catalog = catalog.filter(x =>!text.includes(x.id) && !dateFilter.includes(x.id));
            }

            if (isType(item, SqlTypesConst.DATETIME) || isType(item, SqlTypesConst.DATE)) {
                catalog = catalog.filter(x =>!text.includes(x.id));
            }


            return catalog;
        }

        const removeQuestion = (searchDataId: string) => {
            dataFilter.value = dataFilter.value.filter(x => x.id!==searchDataId);
        }

        const showMenuFilter = (event: any) => {
            menuFilter.value.show(event);
        };

        const setSizeScreen = () => {

            sm.value = false;
            lg.value = false;
            md.value = false;
            xl.value = false;

            if (containerSize.value) {

                if (containerSize.value <= 641) {
                    sm.value = true;
                }
                else if (containerSize.value > 641 && containerSize.value <= 1007) {
                    md.value = true;
                }
                else if (containerSize.value > 1007 && containerSize.value <= 1200) {
                    lg.value = true;
                }
                else if (containerSize.value > 1200) {
                    xl.value = true;
                }

            }


        }

        const setItemEmpty = (event: any, index: number) => {

            if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_NONULO || event.value == FiltroBusquedaConst.FILTROBUSQUEDA_NULO) {

                if (index > -1) {
                    dataFilter.value[index].rangeDateTime.from = null;
                    dataFilter.value[index].rangeDateTime.to = null;
                    if ('value' in dataFilter.value[index]) {
                        dataFilter.value[index].value = null;
                    }

                    if ('valueNumber' in dataFilter.value[index]) {
                        dataFilter.value[index].valueNumber = undefined;
                    }

                    if ('valueBool' in dataFilter.value[index]) {
                        dataFilter.value[index].valueBool = undefined;
                    }
                }
            }


        }
        const updateFiltersValues=(event: any, item: any)=>{

            const index = dataFilter.value.findIndex((x: any) => x.id == item?.id);

            setItemEmpty(event,index);
            setDateFilterValues(event,index,true);

        }

        const setDateFilterValues=(event:any,index:number,isUpdateFilterValue:boolean=false)=>{
            let dateFrom: Date | null = null;
            let dateTo: Date | null = null;

            if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTMONTH){
                const currMonthDates=HelperCommon.currentMonthDates();
                dateFrom=currMonthDates.first;
                dateTo=currMonthDates.last;
            }
            if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSMONTH){

                const prevMonthDates=HelperCommon.previousMonthDates();
                dateFrom=prevMonthDates.first;
                dateTo=prevMonthDates.last;
            }

            if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTYEAR){

                const currYearDates=HelperCommon.currentYearDates();
                dateFrom=currYearDates.first;
                dateTo=currYearDates.last;
            }

            if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSYEAR){
                const prevYearDates=HelperCommon.previousYearDates();
                dateFrom=prevYearDates.first;
                dateTo=prevYearDates.last;
            }

            if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTWEEK){
                const currWeekDates=HelperCommon.currentWeekDates();
                dateFrom=currWeekDates.first;
                dateTo=currWeekDates.last;
            }

            if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSWEEK){
                const prevWeekDates=HelperCommon.previousWeekDates();
                dateFrom=prevWeekDates.first;
                dateTo=prevWeekDates.last;
            }

            
            if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO){
                const daysAgo=HelperCommon.daysAgoDates(dataFilter.value[index].valueNumber);
                dateFrom=daysAgo.first;
                dateTo=daysAgo.last;
            }
            
             
            if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_ENTRE && !isUpdateFilterValue){
                dateFrom=dataFilter.value[index].rangeDateTime.from;
                if(dataFilter.value[index].rangeDateTime.to!=null){
                    dateTo=new Date(dataFilter.value[index].rangeDateTime.to.setHours(23,59,59,999));
                }
                else{
                    dateTo=dataFilter.value[index].rangeDateTime.to;
                }
                
            }


            dataFilter.value[index].rangeDateTime.from = dateFrom;
            dataFilter.value[index].rangeDateTime.to = dateTo;

        }

        //arreglo inputs calendar por bug primevue
        const fixCalendarFocusTrap = (calendarRef: any) => {

            const componentExists = !!calendarRef;
            if (!componentExists) return;

            const alreadyMonkeyPatched =
                !!calendarRef.originalUpdateFocusFunc;
            if (alreadyMonkeyPatched) return;

            calendarRef.originalUpdateFocusFunc =
                calendarRef.updateFocus;

            calendarRef.updateFocus = () => {
                const inputEl = calendarRef.input;
                const inputElHasFocus = inputEl && inputEl == document.activeElement;
                if (!inputElHasFocus) {
                    calendarRef.originalUpdateFocusFunc();
                }
            };
        };


        //arreglo inputs calendar por bug primevue
        watch(() => calendarsToRef.value, (newValue, oldValue) => {

            calendarsToRef.value.filter((el) => el !== null && el !== undefined).forEach((item: any, index: any) => {
                if (item.value && item.value[0]) {
                    fixCalendarFocusTrap(item.value[0]);
                }
            });
        }, { immediate: true, deep: true });

        //arreglo inputs calendar por bug primevue
        watch(() => calendarsFromRef.value, (newValue, oldValue) => {

            calendarsFromRef.value.filter((el) => el !== null && el !== undefined).forEach((item: any, index: any) => {
                if (item.value && item.value[0]) {
                    fixCalendarFocusTrap(item.value[0]);
                }
            });
        }, { immediate: true, deep: true });


        //arreglo inputs calendar por bug primevue
        const assignCalendarsRef = () => {

            dataFilter.value.forEach((item: any, index: any) => {
                const field = getFieldData(item.fieldId);
                if (isType(field, SqlTypesConst.DATETIME) || isType(field, SqlTypesConst.DATE)) {
                    calendarsFromRef.value[index] = ref(null) as any;
                }
            });

            dataFilter.value.forEach((item: any, index: any) => {
                const field = getFieldData(item.fieldId);
                if (isType(field, SqlTypesConst.DATETIME) || isType(field, SqlTypesConst.DATE)) {
                    calendarsToRef.value[index] = ref(null) as any;
                }
            });

        }

      
        onMounted(() => {
     //arreglo inputs calendar por bug primevue
     calendarsFromRef.value.filter((el) => el !== null && el !== undefined).forEach((item: any, index: any)=>{
                    fixCalendarFocusTrap(item.value[0]);
            });

            setSizeScreen();



        });

        onBeforeMount(() => {

            //arreglo inputs calendar por bug primevue
            assignCalendarsRef();

            });



        return {
            FiltroBusquedaConst,
            getPointerMode,
            translateFieldLabel,
            getFieldData,
            getCatalogFilterByFieldType,
            removeQuestion,
            isFilterVisible,
            getCatalogData,
            calendarsFromRef,
            calendarsToRef,
            isCatalogType,
            dataBaseId,
            showMenuFilter,
            updateFiltersValues,
            dataFilter,
            containerSize,
            SqlTypesConst,
            isType,
            tablas,
            xl,
            dateFilter,
            setDateFilterValues,
            notShowControl,

        };
    },
});
</script>
<style scoped></style>
