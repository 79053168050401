/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import HelperCommon from "../../../../../common/infrastructure/funciones/HelperCommon";
import { IapApplicationConfiguration } from "../../domain/iapApplicationConfiguration";

export default function HelperApplication(
    ipublicPath:any
) {

    const builUrlImage = (data: string) => {
        if (!HelperCommon.isNullOrWhitespace(data)) {
            const type = 'application/octet-stream'
            const fileURL = "data:" + type + ";base64," + (data ?? '');
            return fileURL;
        }


        return `${ipublicPath}layout/images/pages/no-image.png`;
    }

    const getAppKeyValue=(key:string,environment:string | undefined,config:IapApplicationConfiguration[]):string | null=>{
      if(environment){
        const envKey=config.find(x=> x.idKeyType ==key && x.idEnvironmentType==environment)?.keyValue;

        if(envKey && !HelperCommon.isNullOrWhitespace(envKey)){
           return envKey;
        }

      }
     
       return config.find(x=> x.idKeyType ==key)?.keyValue??null;
    }

    const getAppConfigObj=(key:string,environment:string | undefined,config:IapApplicationConfiguration[]):IapApplicationConfiguration | undefined=>{
      if(environment){
        const envAppConfig=config.find(x=> x.idKeyType ==key && x.idEnvironmentType==environment);

        if(envAppConfig){
           return envAppConfig;
        }

      }
     
       return config.find(x=> x.idKeyType ==key);
    }

  return {
    builUrlImage,
    getAppKeyValue,
    getAppConfigObj

  };
}
