import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6130a81f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { open: "" }
const _hoisted_3 = { class: "flex align-items-center" }
const _hoisted_4 = { class: "ml-2" }
const _hoisted_5 = {
  key: 1,
  open: ""
}
const _hoisted_6 = { class: "flex align-items-center" }
const _hoisted_7 = { class: "ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectButton = _resolveComponent("SelectButton")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_SearchFields = _resolveComponent("SearchFields")!
  const _component_search_tree = _resolveComponent("search_tree")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isSummary)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          _createElementVNode("li", null, [
            _createElementVNode("details", _hoisted_2, [
              _createElementVNode("summary", null, [
                _createVNode(_component_Toolbar, {
                  class: "mb-3 mt-3 mr-1",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.stopPropagateEvent($event,!_ctx.advancedSearch?true:false)))
                }, {
                  start: _withCtx(() => [
                    (_ctx.advancedSearch)
                      ? (_openBlock(), _createBlock(_component_SelectButton, {
                          key: 0,
                          modelValue: _ctx.groupSearch.operatorLogic,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.groupSearch.operatorLogic) = $event)),
                          options: _ctx.logicOperatorsOptions,
                          optionLabel: "name",
                          optionValue: "value",
                          "aria-labelledby": "basic",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.stopPropagateEvent($event)))
                        }, null, 8 /* PROPS */, ["modelValue", "options"]))
                      : _createCommentVNode("v-if", true),
                    _createVNode(_component_Dropdown, {
                      modelValue: _ctx.selectedSearch,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedSearch) = $event)),
                      options: _ctx.getData,
                      filter: "",
                      optionLabel: "label",
                      optionGroupLabel: "label",
                      optionValue: "value",
                      onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectQuestion($event))),
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.stopPropagateEvent($event))),
                      optionGroupChildren: "items",
                      placeholder: "Selecciona un filtro",
                      class: "w-full md:w-14rem mr-2 ml-2"
                    }, {
                      optiongroup: _withCtx((slotProps) => [
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("i", {
                            class: _normalizeClass(slotProps.option.icon)
                          }, null, 2 /* CLASS */),
                          _createElementVNode("div", _hoisted_4, _toDisplayString(slotProps.option.label), 1 /* TEXT */)
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue", "options"]),
                    (_ctx.advancedSearch)
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 1,
                          icon: "pi pi-plus",
                          rounded: "",
                          outlined: "",
                          "aria-label": "Filter",
                          class: "ml-1",
                          onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.addChildNode($event)), ["stop"]))
                        }))
                      : _createCommentVNode("v-if", true),
                    (!_ctx.isRoot && _ctx.advancedSearch)
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 2,
                          icon: "pi pi-minus",
                          rounded: "",
                          outlined: "",
                          "aria-label": "Filter",
                          severity: "danger",
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.deleteChild(_ctx.groupSearch))),
                          class: "ml-1"
                        }))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _createVNode(_component_SearchFields, {
                container: _ctx.container,
                modelValue: _ctx.groupSearch.fields,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.groupSearch.fields) = $event)),
                catalogosData: _ctx.catalogosData,
                dataContext: _ctx.getData,
                compDataSource: _ctx.compDataSource,
                fixCalendar: _ctx.fixCalendar,
                onSearch: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('search')))
              }, null, 8 /* PROPS */, ["container", "modelValue", "catalogosData", "dataContext", "compDataSource", "fixCalendar"])
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupSearch.children, (value, key, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createVNode(_component_search_tree, {
                container: _ctx.container,
                compDataSource: _ctx.compDataSource,
                catalogosData: _ctx.catalogosData,
                allTree: _ctx.allTree,
                "onUpdate:allTree": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.allTree) = $event)),
                modelValue: _ctx.groupSearch.children[key],
                "onUpdate:modelValue": ($event: any) => ((_ctx.groupSearch.children[key]) = $event),
                isSummary: true,
                advancedSearch: _ctx.advancedSearch
              }, null, 8 /* PROPS */, ["container", "compDataSource", "catalogosData", "allTree", "modelValue", "onUpdate:modelValue", "advancedSearch"])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.isSummary)
      ? (_openBlock(), _createElementBlock("details", _hoisted_5, [
          _createElementVNode("summary", null, [
            _createVNode(_component_Toolbar, { class: "mb-3 mr-1" }, {
              start: _withCtx(() => [
                _createVNode(_component_SelectButton, {
                  modelValue: _ctx.groupSearch.operatorLogic,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.groupSearch.operatorLogic) = $event)),
                  options: _ctx.logicOperatorsOptions,
                  optionLabel: "name",
                  optionValue: "value",
                  "aria-labelledby": "basic",
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.stopPropagateEvent($event)))
                }, null, 8 /* PROPS */, ["modelValue", "options"]),
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.selectedSearch,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.selectedSearch) = $event)),
                  options: _ctx.getData,
                  filter: "",
                  optionLabel: "label",
                  optionGroupLabel: "label",
                  optionValue: "value",
                  onChange: _cache[14] || (_cache[14] = ($event: any) => (_ctx.selectQuestion($event))),
                  onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.stopPropagateEvent($event))),
                  optionGroupChildren: "items",
                  placeholder: "Selecciona un filtro",
                  class: "w-full md:w-14rem mr-2 ml-2"
                }, {
                  optiongroup: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("i", {
                        class: _normalizeClass(slotProps.option.icon)
                      }, null, 2 /* CLASS */),
                      _createElementVNode("div", _hoisted_7, _toDisplayString(slotProps.option.label), 1 /* TEXT */)
                    ])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "options"]),
                _createVNode(_component_Button, {
                  icon: "pi pi-plus",
                  rounded: "",
                  outlined: "",
                  "aria-label": "Filter",
                  class: "ml-1",
                  onClick: _cache[16] || (_cache[16] = _withModifiers(($event: any) => (_ctx.addChildNode($event)), ["stop"]))
                }),
                _createVNode(_component_Button, {
                  icon: "pi pi-minus",
                  rounded: "",
                  outlined: "",
                  "aria-label": "Filter",
                  severity: "danger",
                  class: "ml-1",
                  onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.deleteChild(_ctx.groupSearch)))
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createVNode(_component_SearchFields, {
            container: _ctx.container,
            modelValue: _ctx.groupSearch.fields,
            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.groupSearch.fields) = $event)),
            catalogosData: _ctx.catalogosData,
            compDataSource: _ctx.compDataSource,
            fixCalendar: _ctx.fixCalendar,
            dataContext: _ctx.getData,
            onSearch: _cache[19] || (_cache[19] = ($event: any) => (_ctx.$emit('search')))
          }, null, 8 /* PROPS */, ["container", "modelValue", "catalogosData", "compDataSource", "fixCalendar", "dataContext"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupSearch.children, (value, key, index) => {
            return (_openBlock(), _createBlock(_component_search_tree, {
              key: index,
              container: _ctx.container,
              compDataSource: _ctx.compDataSource,
              catalogosData: _ctx.catalogosData,
              modelValue: _ctx.groupSearch.children[key],
              "onUpdate:modelValue": ($event: any) => ((_ctx.groupSearch.children[key]) = $event),
              allTree: _ctx.allTree,
              "onUpdate:allTree": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.allTree) = $event)),
              isSummary: false,
              advancedSearch: _ctx.advancedSearch
            }, null, 8 /* PROPS */, ["container", "compDataSource", "catalogosData", "modelValue", "onUpdate:modelValue", "allTree", "advancedSearch"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}