import { computed,  onMounted,  ref, reactive } from "vue";

import logoComp from "../../../../../common/infrastructure/funcionescompartidas/logoComp";
import { authRequest } from "../../domain/authRequest";
import HelperCommon from "../../../../../common/infrastructure/funciones/HelperCommon";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { IServiceAuth } from "../../application/IServiceAuth";
import { TYPES } from "../../../../../common/domain/types";
import { Container } from "inversify";
import {
  Actions,
  Mutations,
} from "../../../../../common/infrastructure/almacen/enums/StoreEnums";
import HelperLogin from "./HelperLogin";
import CatalogConfigurationConst from '@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogConfigurationConst';
import { getCatalogsFromService } from "../../../../builder/catalog/infrastructure/helper/helperCatalog";
import HelperLoading from "../../../../../common/infrastructure/funciones/HelperLoading";
import Environment from "../../../../../common/infrastructure/funciones/environment";
import ErrorCodesConst from "../../../../../common/domain/constantes/ErrorCodes/ErrorCodesConst";
import HelperApplication from "../../../../builder/application/infrastructure/functions/helperApplication";

export default function loginComp(
  ipublicPath: any,
  routes: any,
  container: Container,
  props: any,
  store:any
) {
  const { getLogoPanelUrl, getLogoUrl, getFaviconUrl} = logoComp();

  const submitButton = ref<HTMLElement | null>(null);
  const bodyLogin = ref<HTMLElement | null>(null);
  let isLoading = ref<boolean>(false);
  let index = 0;
  const isForgetPass = ref(false);
  const submitted = ref(false);  
  const myShowModalAuthType = ref(false);
  const appCfg = reactive({version:"",color1:"",color2:"", mscfgLoaded:false});
  

  const request = ref<authRequest>({
    grant_type: "password",
    username: "",
    password: "",
    microsoftEmail: null,
    googleEmail: null,
    client_id: "",
    client_secret: "",
    refresh_token: "",
    brokerId: null,
    profileId: "",
    applicationId: Environment.APPID,
    applicationVersion: Environment.APPVERSION,
    external: false,
    sessionId:''
  });

  const customEmail = (value: string) => {
    return HelperCommon.validarEmail(value);
  };

  const rules = {
    password: {
      required: helpers.withMessage("El password es obligatorio", required),
    },
    username: {
      required: helpers.withMessage("El usuario es obligatorio", required),
      customEmail: helpers.withMessage("Correo no válido", customEmail),
    },
  };

  const v$ = useVuelidate(rules, request);



  const getAppConfig = () => {
    HelperLoading.showLoading();
    const _srvAuth = container?.get<IServiceAuth>(TYPES.AUTH_SERVICE);
    _srvAuth
      ?.getAppConfig(Environment.APPID, Environment.APPVERSION)
      .then((response: any) => {   
        let cfgArray = response.data.data.appConfiguration        
        if (cfgArray){
          const { getAppKeyValue } = HelperApplication(undefined)
          appCfg.version = getAppKeyValue(CatalogConfigurationConst.VERSION,Environment.ENVIRONMENT??'',cfgArray)??''     
          appCfg.color1 = getAppKeyValue(CatalogConfigurationConst.COLOR1,Environment.ENVIRONMENT??'',cfgArray)??'';
          appCfg.color2 = getAppKeyValue(CatalogConfigurationConst.COLOR2,Environment.ENVIRONMENT??'',cfgArray)??'';          
          // se establece el título de la applicación
          document.title = getAppKeyValue(CatalogConfigurationConst.TITLE,Environment.ENVIRONMENT??'',cfgArray)??'';
          // se añade la configuración al storage
          store.commit(Mutations.SET_APPCONFIG, cfgArray?.map((x:any)=> ({idKeyType:x.idKeyType,keyValue:x.keyValue,idEnvironmentType:x.idEnvironmentType,keyBinaryValue:x.keyBinaryValue})) ?? [])         
          request.value.external =(getAppKeyValue(CatalogConfigurationConst.ISAPPEXTERNAL,Environment.ENVIRONMENT??'',cfgArray) === "true") ? true : false;
          appCfg.mscfgLoaded = true;
        }
        

       
      })
      .finally(() => {
        HelperLoading.hideLoading();
      });
  };

  onMounted(() => {   
    getAppConfig();    
    pageLoad();
  });

  const handleSubmit = (isFormValid: boolean) => {
    submitted.value = true;

    if (!isFormValid) {
      return;
    }
  };

  const isValidLoginData = (): boolean => {
    submitted.value = true;
    v$.value.$touch();
    if (v$.value.$invalid) {
      HelperCommon.setFocusOnFirstError();
      return false;
    }

    return true;
  };
  const showLoading = computed(() => {
    
    return isLoading.value;
  });

  //Form submit function
  const onSubmitLogin = () => {
    if (isValidLoginData()) {
      isLoading.value = true;
      HelperLoading.showLoading();
      //setTimeout(() => {
        store
          .dispatch(Actions.AUTH, [request, container])
          .then(() => {
            getCatalogsFromService(props.container, Environment.APPID,Environment.APPVERSION).then(response => {
              isLoading.value = true;
              if (response) {
                HelperLogin.getApplicationMenus(true, routes, Environment.APPID, Environment.APPVERSION, container, '', true).then((resp)=> {
                  isLoading.value = true;
                  myShowModalAuthType.value = resp;
                  store.commit(Mutations.SETVERSION,appCfg.version);
                }).finally(() => {
                  isLoading.value = false;
                  HelperLoading.hideLoading();
                });
              }
            })
          }).catch((error: any) => {
            isLoading.value = false;
            HelperLoading.hideLoading();
            if (error.status == ErrorCodesConst.NEED_MULTIFACTOR_CODE) {
              store.commit(Mutations.SET_USER, error.data);
              myShowModalAuthType.value = true;
            }
          })
          
      //}, 500);
    }
  };

  const onSubmitForgotPassword = () => {
    isForgetPass.value = true;   
  };

  function pageLoad() {
    carousel();
  }

  function carousel() {
    if (
      routes?.currentRoute.value.path == "/login" &&
      bodyLogin.value != null
    ) {
      if (index > 5) {
        index = 0;
      }
      index++;
      if (index === 1) {
        bodyLogin.value.style.background =
          "url(" +
          ipublicPath +
          "layout/images/pages/login/bgil1.jpg) center top / cover no-repeat fixed";
      } else if (index === 2) {
        bodyLogin.value.style.background =
          "url(" +
          ipublicPath +
          "layout/images/pages/login/bgil2.jpg) center top / cover no-repeat fixed";
      } else if (index === 3) {
        bodyLogin.value.style.background =
          "url(" +
          ipublicPath +
          "layout/images/pages/login/bgil3.jpg) center top / cover no-repeat fixed";
      } else if (index === 4) {
        bodyLogin.value.style.background =
          "url(" +
          ipublicPath +
          "layout/images/pages/login/bgil4.jpg) center top / cover no-repeat fixed";
      } else {
        bodyLogin.value.style.background =
          "url(" +
          ipublicPath +
          "layout/images/pages/login/bgil5.jpg) center top / cover no-repeat fixed";
      }
    }
    setTimeout(carousel, 30000);
  }

  return {
    request,
    onSubmitLogin,
    onSubmitForgotPassword,
    submitButton,
    bodyLogin,
    carousel,
    pageLoad,
    isForgetPass,    
    showLoading,
    isLoading,
    ipublicPath,
    v$,
    isValidLoginData,
    submitted,
    handleSubmit,
    getLogoPanelUrl,
    getLogoUrl,
    getFaviconUrl,
    appCfg,
    myShowModalAuthType,
  };
}
