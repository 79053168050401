<template>
    <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG, Component)" :data="Component"
        :vmodel="vmodel" />

    <div v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        style="height: fit-content;width: fit-content;" @mouseenter="showEdit = true; showDelete = true"
        @mouseleave="showEdit = false; showDelete = false">
        <img :key="componentKey" :id="getPropertyValue(ImageTypeConst.ID)"
            v-tooltip.bottom="getPropertyValue(ImageTypeConst.TOOLTIP)" :style="getPropertyValue(ImageTypeConst.STYLE)"
            :class="getPropertyValue(ImageTypeConst.CLASS)" :name="getPropertyValue(ImageTypeConst.NAME)"
            :disabled="getPropertyBooleanValue(ImageTypeConst.DISABLED)"
            :visible="getPropertyBooleanValue(ImageTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
            :placeholder="getPropertyValue(ImageTypeConst.PLACEHOLDER)" :src="getSrcImage"
            :alt="getPropertyValue(ImageTypeConst.ALT)" :width="getPropertyValue(ImageTypeConst.WIDTH)"
            :height="getPropertyValue(ImageTypeConst.HEIGHT)" @click="processEventComponent(CatalogEventConst.CLICK)"
            @error="noImageUrl($event)" />
        <i v-if="showEdit && hasEditMode && getPropertyBooleanValue(ImageTypeConst.CAN_EDIT) && canDoOperation(ObjectGroupConst.EDIT)"
            class="pi pi-file-edit" @click="toggleImage($event)" style="color: 'var(--p-primary-color)'"></i>
        <i v-if="showDelete && hasEditMode && getPropertyBooleanValue(ImageTypeConst.CAN_DEL) && canDoOperation(ObjectGroupConst.DELETE)"
            class="pi pi-times" @click="removeImage()" style="color: red"></i>
    </div>
    <Skeleton v-else size="5rem"></Skeleton>



    <OverlayPanel ref="opImg" :dismissable="false" :showCloseIcon="true">
        <div class="card">

            <FileUpload name="images[]" @uploader="onTemplatedUpload($event)" :multiple="false"
                :accept="getPropertyValue(ImageTypeConst.IMG_FORMAT) ?? '.png'" :maxFileSize="getPropertyNumberValue(ImageTypeConst.IMG_SIZE) ?? 1000000" :fileLimit="1"
                @select="onSelectedFiles" :customUpload="true">

                <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                    <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                        <div class="flex gap-2">
                            <Button @click="chooseCallback()" icon="pi pi-images" rounded outlined></Button>
                            <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload" rounded outlined
                                severity="success" :disabled="!files || files.length === 0"></Button>
                            <Button @click="clearCallback()" icon="pi pi-times" rounded outlined severity="danger"
                                :disabled="!files || files.length === 0"></Button>
                        </div>
                    </div>
                </template>
                <template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
                    <div v-if="files.length > 0">
                        <h5>Pending</h5>
                        <div class="flex flex-wrap p-0 sm:p-5 gap-5">
                            <div v-for="(file, index) of files" :key="file.name + file.type + file.size"
                                class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                                <div>
                                    <img role="presentation" :alt="file.name" :src="file.objectURL" width="100"
                                        height="50" class="shadow-2" />
                                </div>
                                <span class="font-semibold">{{ file.name }}</span>
                                <div>{{ formatSize(file.size) }}</div>
                                <Badge value="Pending" severity="warning" />
                                <Button icon="pi pi-times"
                                    @click="onRemoveTemplatingFile(file, removeFileCallback, index)" outlined rounded
                                    severity="danger" />
                            </div>
                        </div>
                    </div>

                    <div v-if="uploadedFiles.length > 0">
                        <h5>Completed</h5>
                        <div class="flex flex-wrap p-0 sm:p-5 gap-5">
                            <div v-for="(file, index) of uploadedFiles" :key="file.name + file.type + file.size"
                                class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                                <div>
                                    <img role="presentation" :alt="file.name" :src="file.objectURL" width="100"
                                        height="50" class="shadow-2" />
                                </div>
                                <span class="font-semibold">{{ file.name }}</span>
                                <div>{{ formatSize(file.size) }}</div>
                                <Badge value="Completed" class="mt-3" severity="success" />
                                <Button icon="pi pi-times" @click="removeUploadedFileCallback(index)" outlined rounded
                                    severity="danger" />
                            </div>
                        </div>
                    </div>
                </template>
                <template #empty>
                    <div class="flex align-items-center justify-content-center flex-column">
                        <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400" />
                        <p class="mt-4 mb-0">Arrastre y suelte los archivos aquí para cargarlos.</p>
                    </div>
                </template>
            </FileUpload>
        </div>
    </OverlayPanel>

</template>
<script lang="ts">


import { defineComponent, onBeforeUnmount, ref, computed, shallowRef } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import ImageTypeConst from '../../../../domain/Constants/ImageTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import { IapComponentDataSource } from '../../../../../component/domain/iapComponentDataSource';
import DataSourceUtilComp from '../../../../../crud/infrastructure/functions/dataSourceUtilComp';
import DataSourceComp from '../../../../../crud/infrastructure/functions/dataSourceComp';
import Environment from '../../../../../../../common/infrastructure/funciones/environment';
import { DataUpdateOperation } from '../../../../../dataupdate/domain/dataUpdateOperation';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';
import SqlTypesConst from '../../../../../../../common/domain/constantes/SqlTypesConst';
import HelperOperationData from '../../../../../../../common/infrastructure/funciones/HelperOperationData';
import { dataUpdate } from '../../../../../dataupdate/domain/dataUpdate';
import { IServiceCache } from '../../../../../cache/application/IServiceCache';
import CacheConst from '../../../../../cache/domain/cacheConst';
import { TYPES } from '../../../../../../../common/domain/types';

export default defineComponent({
    name: 'dynamic_image',
    components: {
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },
    },
    setup(props, context) {
        //

        const store = useStore();
        const { vmodel, canDoOperation, getPropertyValue,getPropertyNumberValue, getPropertyBooleanValue, loaded, baseOnBeforeUnmount
            , fieldKey, fieldKeyComponentDataSourceId, processEventComponent, componentKey
        } = ComponentCommonRender(props.Component, props.slotProps, props.container, store);
        const opImg = ref();
        const totalSizePercent = shallowRef(0);
        const totalSize = shallowRef(0);
        const files = ref([]);
        const showEdit = shallowRef(false);
        const showDelete = shallowRef(false);


        const noImagePath = () => {
            const appPublic = process.env.VUE_APP_PUBLIC_PATH ?? '/';
            return `${appPublic}layout/images/pages/no-image.png`;
        }

        const getComponentDataSource = () => {
            const dsData = getPropertyValue(ImageTypeConst.DS_FIELD_DATA);
            const dsDataId = getPropertyValue(ImageTypeConst.DS_FIELD_ID);
            const imgPath = getPropertyValue(ImageTypeConst.SERVER_PATH);
            const imgFormat = getPropertyValue(ImageTypeConst.IMG_FORMAT);
            if (dsData && dsDataId && imgPath && imgFormat) {
                const idsData = dsData?.split('#');
                if (props.Component.iapComponentDataSources && (idsData?.length ?? 0) > 1) {
                    const compDataSourceDataId = parseInt(idsData[1]);
                    return props.Component.iapComponentDataSources.find(x => x.id == compDataSourceDataId)
                }

            }

            return null;
        }

        const getFormatRecordId = () => {
            const dsData = getPropertyValue(ImageTypeConst.DS_FIELD_DATA);
            const dsDataId = getPropertyValue(ImageTypeConst.DS_FIELD_ID);
            const imgPath = getPropertyValue(ImageTypeConst.SERVER_PATH);
            const imgFormat = getPropertyValue(ImageTypeConst.IMG_FORMAT);

            if (dsData && dsDataId && imgPath && imgFormat) {
                const { getFieldKeyColumn } = DataSourceComp(props.container as any, props, context.emit, {}, {} as any, [], store, {} as any)
                const datasource = getComponentDataSource();
            
                const idsDataKey = dsDataId?.split('#');
                if (datasource && (idsDataKey?.length ?? 0) > 1) {
                    const dataSourceFieldId = idsDataKey[0];

                    if (datasource?.dataSource?.items) {
                        const fieldName = datasource?.dataSource.iapDataSourceFields.filter(x => x.id == dataSourceFieldId).map(x => getFieldKeyColumn(x))

                        return imgPath + '\\' + datasource?.dataSource?.items[0][fieldName[0]] + imgFormat;
   
                    }

                }
                
            }

            return null;
        }
        
        const getDataSourceScr = () => {
            const dsData = getPropertyValue(ImageTypeConst.DS_FIELD_DATA);
            const dsDataId = getPropertyValue(ImageTypeConst.DS_FIELD_ID);
            const imgPath = getPropertyValue(ImageTypeConst.SERVER_PATH);
            const imgFormat = getPropertyValue(ImageTypeConst.IMG_FORMAT);

            if (dsData && dsDataId && imgPath && imgFormat) {
                const { getFieldKeyColumn } = DataSourceComp(props.container as any, props, context.emit, {}, {} as any, [], store, {} as any)
                const datasource = getComponentDataSource();
                // hay que sacar el field del ds
                const idsData = dsData?.split('#');
                if (datasource && (idsData?.length ?? 0) > 1) {
                    const dataSourceFieldDataId = idsData[0];

                    if (datasource?.dataSource?.items) {

                        const fieldName = datasource?.dataSource.iapDataSourceFields.filter(x => x.id == dataSourceFieldDataId).map(x => getFieldKeyColumn(x))
                        datasource.dataSource.items[0][fieldName[0]] = null
                    }
                }
                const idsDataKey = dsDataId?.split('#');
                if (datasource && (idsDataKey?.length ?? 0) > 1) {
                    const dataSourceFieldId = idsDataKey[0];

                    if (datasource?.dataSource?.items) {
                        const fieldName = datasource?.dataSource.iapDataSourceFields.filter(x => x.id == dataSourceFieldId).map(x => getFieldKeyColumn(x))

                        return Environment.URL_IMG + imgPath + '/' + datasource?.dataSource?.items[0][fieldName[0]] + imgFormat+ '?_t=' + new Date().getTime().toString();
                    }

                }
            }

            return null;
        }

        const hasEditMode = computed(()=>{
            const dsData = getPropertyValue(ImageTypeConst.DS_FIELD_DATA);
            const dsDataId = getPropertyValue(ImageTypeConst.DS_FIELD_ID);
            const imgPath = getPropertyValue(ImageTypeConst.SERVER_PATH);
            const imgFormat = getPropertyValue(ImageTypeConst.IMG_FORMAT);

            if (dsData && dsDataId && imgPath && imgFormat) {
                return true;
            }
            else{
                return false
            }
        })

        const getSrcImage = computed(() => {
            const _src = getPropertyValue(ImageTypeConst.SRC);
            if (_src) {
                return _src;
            }

            debugger

            return getDataSourceScr() ?? noImagePath();
        })

        const updateCache = (imgData:any)  =>{
            const id = getFormatRecordId();
            if (props.container && id){
                HelperLoading.showSaving();
                loaded.value = false;
                const _srv = props.container.get<IServiceCache>(TYPES.CACHE_REPOSITORY)
                    _srv.refresh(props.Component.applicationId, props.Component.applicationVersion, CacheConst.ONEIMG, id,imgData).then(response => {
                        
                        if (response) {
                            //MessageService.showToast(MessageType.Correcto, '', 'Se ha actualizado la caché correctamente.')
                        }
                        else {
                            //MessageService.showToast(MessageType.Error, '', 'No se ha podido actualizar la caché.')
                        }

                        
                    })
                        .finally(() => {
                            HelperLoading.hideSaving()
                            loaded.value = true;
                    })
            }

        }

        const doOperationUpdate = (imgData: any) => {            
            const dsData = getPropertyValue(ImageTypeConst.DS_FIELD_DATA);
            const dsDataId = getPropertyValue(ImageTypeConst.DS_FIELD_ID);
            const imgPath = getPropertyValue(ImageTypeConst.SERVER_PATH);
            const imgFormat = getPropertyValue(ImageTypeConst.IMG_FORMAT);
            const cds = getComponentDataSource();
            const idsData = dsData?.split('#');
            if (dsData && dsDataId && imgPath && imgFormat && cds && cds.dataSource?.items && idsData && (idsData?.length ?? 0) > 1) {
                const { getPrimaryKeys, getFieldId } = DataSourceUtilComp(cds, store)
                const { getFieldKeyColumn } = DataSourceComp(props.container as any, props, context.emit, {}, {} as any, [], store, {} as any)
                const _data = cds.dataSource.items.find((y: any) => y !== undefined);
                const dsf = cds.dataSource.iapDataSourceFields.find(x => x.id == idsData[0]);//  _field == (dataBaseId.value + DELIMITERFIELDS + x.tableName + DELIMITERFIELDS + x.field))?.tableName          
                const tableName = dsf?.tableName ?? '';
                const _field = getFieldKeyColumn(dsf);
                const mustSerialize = (dsf?.sqlType == SqlTypesConst.BIT) ?? false;
                const mustToString = (dsf?.sqlType == SqlTypesConst.MONEY) ?? false;
                const bExits = (getDataSourceScr()  != null)
                const dataUpdate: dataUpdate = {
                    valores: [{
                        fieldId: getFieldId(_field, cds.dataSource.iapDataSourceFields) as any,
                        value: mustSerialize ? JSON.stringify(imgData) as any : mustToString ? imgData.toString() : imgData as any,//JSON.stringify(newValue) as any
                    }],
                    claves: bExits  ?  getPrimaryKeys(cds.dataSource, _data as any, tableName as any) : [],
                    tipoOperacion: bExits? OperationDataTypeConst.UPDATE: OperationDataTypeConst.INSERT,
                    id: HelperUtils.newGuid()
                };

                // se añaden los autoupdate de la configuración del componentdatasource
                const { tablasData, getDefaultvalue } = DataSourceComp(props.container as any, { Component: props.Component }, {}, {}, cds as any, [] as any, store)
                cds.iapComponentDataSourceFieldConfigurations.filter(x => x.autoUpdate == true)
                    .forEach(cfg => {
                        const _fieldToUpdate = tablasData.value.find(x => x.id == cfg.dataSourceFieldId && x.tableName == tableName);
                        if (_fieldToUpdate) {
                            const newField = getFieldKeyColumn(_fieldToUpdate);
                            const newFieldValue = getDefaultvalue(_fieldToUpdate, cfg.defaultValue)
                            //data[field] = newValue

                            if (
                                (_fieldToUpdate?.nullable == true) // columnas que admiten nulos
                                || (_fieldToUpdate?.nullable == false && !(newFieldValue == null || newFieldValue == undefined))) // columnas no nulas que llegan valores nulos
                            {
                                const mustSerialize = (_fieldToUpdate?.sqlType == SqlTypesConst.BIT) ?? false;
                                dataUpdate.valores.push
                                    (
                                        {
                                            fieldId: getFieldId(newField, tablasData.value) as any,
                                            value: mustSerialize ? JSON.stringify(newFieldValue) as any : newFieldValue as any,//JSON.stringify(newValue) as any
                                        })
                            }
                        }
                    })

                const doOperationTransaction = dataUpdate.valores.length > 1;
                const dataInputRequest: DataUpdateOperation = {
                    componentId: cds.componentId,
                    componentDataSourceId: cds.id,
                    dataSourceId: cds.dataSourceId,
                    parameters: cds.dataSource.iapDataSourceServiceConfigurations ?? [],
                    data: [dataUpdate]
                }
                HelperOperationData.doOperationData(props.container, cds.applicationId, cds.applicationVersion, [dataInputRequest], doOperationTransaction)
                .then(response => {
                                        if (response && response[0].result == true) {
                                           updateCache(imgData) 
                                        }
                                    }).catch((err) => {

                                    }) .finally(() => {
                            
                        });
            }
        }

        const onTemplatedUpload = (event: any) => {
            HelperLoading.showLoading();
            event.files.forEach((element: any) => {

                HelperCommon.toBase64(element).then((value) => {
                    const base64 = value as any;
                    const content = base64.substr(base64.indexOf(',') + 1);
                    doOperationUpdate(content);

                    HelperLoading.hideLoading();
                    opImg.value.hide()

                });
            })
        };

        const formatSize = (bytes: any) => {
            if (bytes === 0) return "0 B";
            const k = 1024;
            const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
        };

        const uploadEvent = (callback: any) => {
            totalSizePercent.value = totalSize.value / 10;
            callback();
        };

        const onSelectedFiles = (event: any) => {
            files.value = event.files;
            files.value.forEach((file: any) => {
                totalSize.value += parseInt(formatSize(file.size));
            });
        };

        const onRemoveTemplatingFile = (file: any, removeFileCallback: any, index: number) => {
            removeFileCallback(index);
            totalSize.value -= parseInt(formatSize(file.size));
            totalSizePercent.value = totalSize.value / 10;
        };

        function noImageUrl(event: any) {
            event.target.src = noImagePath();
        }

        const toggleImage = (event: any) => {
            opImg.value.toggle(event);
        }

        const removeImage = () => {
            doOperationUpdate(null);
        }

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })

        return {
            getPropertyValue,
            getPropertyBooleanValue,
            getPropertyNumberValue,
            vmodel,
            ImageTypeConst,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            , canDoOperation
            , processEventComponent
            , CatalogEventConst
            , componentKey
            , opImg
            , onTemplatedUpload
            , formatSize
            , onSelectedFiles
            , onRemoveTemplatingFile
            , uploadEvent
            , noImageUrl
            , getSrcImage
            , toggleImage
            , showEdit
            , showDelete
            , removeImage
            ,hasEditMode


        };
    },
});
</script>
<style scoped></style>
