<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
    <span class="p-float-label wrap-input100 validate-input"  :key="componentKey">
        <!--<Dropdown v-if="paths.length > 1" v-model="selectedPathId"  style="width: 250px;" @change="onChangePath()"
                        :options="paths" :showClear="true" optionValue="id" optionLabel="path"
                        placeholder="" @before-show="loadHistory" filter showClear/>-->

        <Button v-if="paths.length > 1" id="showBrowsing" size="small"
                    v-tooltip="'Historial de navegación'" class="p-button p-component p-button-icon-only p-button-secondary p-button-rounded"
                    icon="pi pi-history" :model="paths" @click="openBrowsing"/>


        <OverlayPanel ref="opBrowsing" :dismissable="true" :showCloseIcon="true">
            <card>
                <template #content>
                    <div class="flex justify-content-center">
                        <DataTable
                            :value="paths"
                            :paginator="true" :rows="5" resizableColumns columnResizeMode="fit" style="width:100%">

                            <Column field="path" header="Sitio" sortable>
                                <template #body="slotProps">
                                    {{ slotProps.data.caption ?? slotProps.data.path }}
                                </template>
                            </Column>
                            <Column field="date" header="Fecha" sortable>
                                <template #body="slotProps">
                                    {{ formatDate(slotProps.data.date) }}
                                </template>
                            </Column>

                            <Column header="Link">
                                <template #body="slotProps">
                                    <i class="pi pi-external-link" v-tooltip="'Abrir enlace'"
                                        @click="redirectToPath(slotProps.data.path)"></i>
                                </template>
                            </Column>
                        </DataTable>

                    </div>
                </template>
            </card>

        </OverlayPanel>
    </span>
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import Environment from "@ilinium/shared/src/common/infrastructure/funciones/environment";
import SummaryDebug from '../../shared/SummaryDebug.vue';
import { useRouter } from 'vue-router';
import { db , Browsing} from "../../../../../../../common/domain/db";


export default defineComponent({
    name: 'dynamicbrowsing',
    components: {
        SummaryDebug,
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },
        
    },
    setup(props, context) {
        
        const router = useRouter();
        const store = useStore();
        const paths = ref<Browsing[]>([]);
        const selectedPathId = ref('')
        const opBrowsing = ref();

        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, resolveExpressions, baseOnBeforeUnmount
            ,fieldKey,fieldKeyComponentDataSourceId ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);
        
        let isLoading = ref<boolean>(false);

        const openBrowsing = (event: any) => {
            loadHistory();
            opBrowsing.value?.toggle(event);
        }

        const closeBrowsing = () => {
            opBrowsing.value?.hide();
        }

        function onChangePath() {
            const selectedPath = paths.value.find((path: any) => path.id === selectedPathId.value);
            if (selectedPath) {
                router.push({ path: selectedPath.path });
            }
        };

        onMounted(async () => {
            await loadHistory();
        })

        onBeforeUnmount(() => {
            opBrowsing.value?.hide();
            paths.value.length = 0
            selectedPathId.value = '';
            baseOnBeforeUnmount();   

        })

       

        async function loadHistory() {
            const myHistory = await db.browsing.where("userId").equals(store.getters.getCurrentUser.id)
                            .and((record: any) => record.applicationId === Environment.APPID && Environment.APPVERSION === 1)
                            .sortBy("date").then(records => records.reverse());
            paths.value = myHistory;
        }

        const redirectToPath = (newpath: string) => {
            closeBrowsing();
            router.push({ path: newpath });
        };

        function formatDate(rowData: any) {
            // Convierte la fecha al formato dd/MM/yyyy
            const date = new Date(rowData);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }

        return {
            BaseControlTypeConst,
            ObjectGroupConst,
            paths,
            selectedPathId,
            onChangePath,
            getPropertyBooleanValue,
            vmodel,
            loadHistory,
            openBrowsing,
            opBrowsing,
            redirectToPath,
            formatDate,
            componentKey,
            loaded

        };
    },
});
</script>
<style scoped></style>
