
import {  defineComponent, inject, shallowRef, computed, onMounted, ref, watchEffect } from 'vue'
import Button from 'primevue/button'
import Toast from 'primevue/toast'
import ForgotPassword from './ForgotPassword.vue'
import ResetPassword from './ResetPassword.vue'
import DobleFactor from './DobleFactor.vue'
import { Container } from 'inversify'
import loginComp from './Login'
import { useStore } from 'vuex'
import HelperLoading from '@ilinium/shared/src/common/infrastructure/funciones/HelperLoading';
import { TYPES } from '@ilinium/shared/src/common/domain/types';
import  { IServiceAuth } from '@ilinium/shared/src/entidades/builderMaster/auth/application/IServiceAuth'
import  { authRequest } from '@ilinium/shared/src/entidades/builderMaster/auth/domain/authRequest'
import Environment from '@ilinium/shared/src/common/infrastructure/funciones/environment'
import { msalInstance, initializeMsalInstance, authMicrosoft } from "./authConfig";
import { AuthenticationResult, AccountInfo } from "@azure/msal-browser";
import { Actions, Mutations } from '@ilinium/shared/src/common/infrastructure/almacen/enums/StoreEnums'
import HelperLogin from '@ilinium/shared/src/entidades/builderMaster/auth/infrastructure/component/HelperLogin';
import { getCatalogsFromService } from '@ilinium/shared/src/entidades/builder/catalog/infrastructure/helper/helperCatalog';
import CatalogConfigurationConst from '@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogConfigurationConst';
import MessageService, { MessageType } from '@ilinium/shared/src/common/infrastructure/servicios/MessageService';
import { IServiceUser } from '@ilinium/shared/src/entidades/builderMaster/user/application/IServiceUser';
import HelperApplication from '@ilinium/shared/src/entidades/builder/application/infrastructure/functions/helperApplication'
import { watch } from 'fs'
import { CookieService, LocalService } from '../../../../../common/infrastructure/servicios'

declare const google: any;

const __default__ = defineComponent({
    components: { ForgotPassword, ResetPassword, DobleFactor, Button, Toast },
    name: 'login1',
    props: {
        routes: Object as any,
        container: {
            type: Object as () => Container        
        }, 
        isResetPass: {
            type: String,
            default: 'false'
        },
    },
    emits: ['isForgetPassChild'],
    setup(props, { emit }) {

        const ipublicPath = inject('publicPath', () => { })

        let showModalAuthType = shallowRef(true);
        const store = useStore();
        const rid = shallowRef<string | null>(null);
        const query: any = props.routes.currentRoute.value.query.query;
        const loaded = shallowRef(false);
        //let color1 = Environment.APPAPPCOLOR1
        //let color2 = Environment.APPAPPCOLOR2
        const isAuthenticated = shallowRef(false);
        const username = shallowRef<string | null>(null);
        const accessToken = shallowRef<string | null>(null);

        const { getAppKeyValue } = HelperApplication(undefined);
        const googAppId:string = getAppKeyValue(CatalogConfigurationConst.GOOGLE_APPID,Environment.ENVIRONMENT??'',store.getters.getAppConfig)??'';

        const signinWiths = computed(() => {
            const { getAppKeyValue } = HelperApplication(undefined);
            const signInValue=  getAppKeyValue(CatalogConfigurationConst.SIGNINWITH,Environment.ENVIRONMENT??'',store.getters.getAppConfig)
            return signInValue?? '';
        });

        const {
            request,
            onSubmitLogin,
            onSubmitForgotPassword,
            submitButton,
            bodyLogin,
            carousel,
            pageLoad,
            isForgetPass,
            showLoading,
            isLoading,
            v$,
            isValidLoginData,
            submitted,
            handleSubmit,            
            getLogoPanelUrl,
            getLogoUrl,
            getFaviconUrl,
            appCfg,            
            myShowModalAuthType,
        } = loginComp(ipublicPath as any, props.routes, props.container as any, props, store)
        
        showModalAuthType.value = myShowModalAuthType.value;
        let color1 = computed(() => appCfg.color1);
        let color2 = computed(() => appCfg.color2);
        //const isResetPass = ref(props.isResetPass ? (props.isResetPass.toLowerCase() === 'true' ? true : false) : false);
        let isResetPass = computed(() => props.isResetPass ? (props.isResetPass.toLowerCase() === 'true' ? true : false) : false);

        watchEffect(async() => {
                    if (appCfg.mscfgLoaded) {                        
                        await initializeMsalInstance();
                        // Verificar si el usuario ya está autenticado al cargar la aplicación
                        const account = msalInstance.getAllAccounts()[0];
                        if (account) {
                            isAuthenticated.value = true;
                            username.value = account.username;
                        }

                    }
                })

      



      
        let favicon:string = getFaviconUrl.value;


        const fetchData = async () => {
            try {
                HelperLoading.showLoading()
                const request: authRequest = {
                    grant_type: 'decrypt',
                    username: '',
                    password: query,
                    microsoftEmail: null,
                    googleEmail: null,
                    client_id: '',
                    client_secret: '',
                    refresh_token: '',
                    brokerId: -1,
                    profileId: '',
                    applicationId: Environment.APPID,
                    applicationVersion: Environment.APPVERSION,
                    external: false ,
                    sessionId: '',
                };
                
                const response = await new Promise<string>((resolve, reject) => {
                    if (props.container) {
                        const _srv = props.container.get<IServiceAuth>(TYPES.AUTH_SERVICE)
                        _srv.decryptApiAuth(request)
                            .then(async (response: any) => {
                                if (response.data.failed === true) {
                                    //MessageService.showMessage(MessageType.Error, 'Error de validación', response.data.message)
                                }

                                if (response.data.includes('microsoftEmail')) {
                                    const params = new URLSearchParams(response.data);
                                    const email = params.get('email');
                                    const microsoftEmail = params.get('microsoftEmail');
                                    if (email) {
                                        await setMicrosoftAccount(email, microsoftEmail);
                                    }
                                }
                                else if (response.data.includes('googleEmail')) {
                                    const params = new URLSearchParams(response.data);
                                    const email = params.get('email');
                                    const googleEmail = params.get('googleEmail');
                                    if (email) {
                                        await setGoogleAccount(email, googleEmail);
                                    }
                                }
                                else {
                                    rid.value = response.data;
                                    loaded.value = true;
                                    HelperLoading.hideLoading()
                                    resolve(response)
                                }
                            })
                            .catch((e) => {
                                HelperLoading.hideLoading()
                                reject(e)
                            })
                            .finally(() => {
                                HelperLoading.hideLoading()
                                //props.routes.push({ name: 'login' })
                                updateIsResetPass(false)
                            });
                        }
                });

                // Aquí puedes hacer lo que necesites con la respuesta
                //console.log(response);
            } catch (error) {
                // Manejar errores si es necesario
                console.error(error);
            }
        };

        const setMicrosoftAccount = async (email: string, microsoftAccount: string | null) => {
            HelperLoading.showLoading()

            store.dispatch(Actions.LINK_MICROSOFTACCOUNT, [email, microsoftAccount, null, Environment.APPID, Environment.APPVERSION, props.container]).then(() => {
                HelperLoading.hideLoading()
                MessageService.showToast(MessageType.Correcto, '', microsoftAccount ? 'Se ha vinculado la cuenta correctamente.' : 'Se ha desvinculado la cuenta correctamente.')
            })
            .catch(() => {
                MessageService.showToast(MessageType.Error, '', 'No se ha podido vincular la cuenta.')
                HelperLoading.hideLoading()
            })
            .finally(() => {
                HelperLoading.hideLoading();
            });
        };

        const setGoogleAccount = async (email: string, googleAccount: string | null) => {
            HelperLoading.showLoading()

            store.dispatch(Actions.LINK_MICROSOFTACCOUNT, [email, null, googleAccount, Environment.APPID, Environment.APPVERSION, props.container]).then(() => {
                HelperLoading.hideLoading()
                MessageService.showToast(MessageType.Correcto, '', googleAccount ? 'Se ha vinculado la cuenta correctamente.' : 'Se ha desvinculado la cuenta correctamente.')
            })
            .catch(() => {
                MessageService.showToast(MessageType.Error, '', 'No se ha podido vincular la cuenta.')
                HelperLoading.hideLoading()
            })
            .finally(() => {
                HelperLoading.hideLoading();
            });
        };

        const loginGoogle = async () => {
            if (typeof google === 'undefined') {
                console.error('Google Identity Services not loaded');
                return;
            }

            google.accounts.id.initialize({
                client_id: googAppId,
                ux_mode: 'popup', // Puedes usar 'redirect' si prefieres una redirección en lugar de un popup
                scope: 'profile email', // Añade los scopes necesarios
                callback: handleCredentialResponse
            });

            google.accounts.id.prompt(); // Mostrar el prompt de inicio de sesión
        }

        function handleCredentialResponse(response: any) {
            //console.log('ID Token: ', response.credential);
            // Procesar el token de ID aquí
            try {
                var token = response.credential;
                const base64Url = token.split('.')[1]; // El payload del JWT está en la segunda parte del token
                const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                if (response && response.credential) {
                    loginWithApp(response.credential, (JSON.parse(jsonPayload)).email, JSON.parse(jsonPayload).name, 'google');
                }

            } catch (error) {
                console.error("Error al iniciar sesión", error);
            }
        }

        const loginWithApp = async (token: string, email: string, name: string, grant_type: string) => {
            store.commit(Mutations.SET_TOKEN, token);

            const request = ref<authRequest>({
                grant_type: grant_type,
                username: email,
                password: name,
                microsoftEmail: null,
                googleEmail: null,
                client_id: '',
                client_secret: '',
                refresh_token: token,
                brokerId: null,
                profileId: '',
                applicationId: Environment.APPID,
                applicationVersion: Environment.APPVERSION,
                external: false,//(userApplications.value.find(a => a.id == selectedApplication?.value.id)?.iapApplicationConfigurations.find(c => c.idKeyType == 'appconfig-isapp-external')?.keyValue === "true") ? true : false,
                sessionId: ''
            });
            isLoading.value = true;
            HelperLoading.showLoading();

            if (grant_type == 'microsoft') {
                store
                .dispatch(Actions.AUTHMICROSOFT, [request, props.container])
                .then(() => {
                    getCatalogsFromService(props.container, Environment.APPID,Environment.APPVERSION).then(response => {
                        isLoading.value = true;
                        if (response) {
                            if (props.container) {
                                HelperLogin.getApplicationMenus(true, props.routes, Environment.APPID, Environment.APPVERSION, props.container, '', false).then((resp)=> {
                                            isLoading.value = true;
                                            myShowModalAuthType.value = resp;
                                            store.commit(Mutations.SETVERSION,appCfg.version);
                                        }).finally(() => {
                                            isLoading.value = false;
                                            HelperLoading.hideLoading();
                                        });
                            }
                        }
                    })
                })
                .finally(() => {
                    //isLoading.value = false;
                    //HelperLoading.hideLoading();
                    //routes?.push({ name: 'mipanel' });
                }).catch(e => {
                    isLoading.value = false;
                    HelperLoading.hideLoading();
                    accessToken.value = null;
                    store.commit(Mutations.SET_TOKEN, null);
                    console.log(e);
                    throw new Error(e);
                });
            }
            else if (grant_type == 'google') {
                store
                .dispatch(Actions.AUTHGOOGLE, [request, props.container])
                .then(() => {
                    getCatalogsFromService(props.container, Environment.APPID,Environment.APPVERSION).then(response => {
                        isLoading.value = true;
                        if (response) {
                            if (props.container) {
                                HelperLogin.getApplicationMenus(true, props.routes, Environment.APPID, Environment.APPVERSION, props.container, '', false).then((resp)=> {
                                            isLoading.value = true;
                                            myShowModalAuthType.value = resp;
                                            store.commit(Mutations.SETVERSION,appCfg.version);
                                        }).finally(() => {
                                            isLoading.value = false;
                                            HelperLoading.hideLoading();
                                        });
                            }
                        }
                    })
                })
                .finally(() => {
                    //isLoading.value = false;
                    //HelperLoading.hideLoading();
                    //routes?.push({ name: 'mipanel' });
                }).catch(e => {
                    accessToken.value = null;
                    store.commit(Mutations.SET_TOKEN, null);
                    console.log(e);
                    isLoading.value = false;
                    HelperLoading.hideLoading();
                    throw new Error(e);
                });
            }
        }

        const loginMicrosoft = async () => {
            var authenticatedAccount = await authMicrosoft();
            if (authenticatedAccount && authenticatedAccount.idToken) {
                accessToken.value = authenticatedAccount.idToken;
                isAuthenticated.value = true;
                
                loginWithApp(authenticatedAccount.idToken, authenticatedAccount.username, authenticatedAccount.name ? authenticatedAccount.name : '', 'microsoft');
            }
        };

        onMounted(async () => {
            LocalService.destroyAll();
            CookieService.destroyAll();
            if (props.routes.currentRoute.value.query.query) {
                fetchData();
            }
            else {
                loaded.value = true;
            }
        });

        const updateIsForgetPass = (event: any): void => {
            
			isForgetPass.value = event;
		};

        const updateIsResetPass = (event: any): void => {
                        
			emit('isForgetPassChild', false);
		};


        return {
            request,
            onSubmitLogin,
            onSubmitForgotPassword,
            submitButton,
            bodyLogin,
            carousel,
            pageLoad,
            isForgetPass,
            isResetPass,
            rid,
            showLoading,
            isLoading,
            ipublicPath,
            v$,
            isValidLoginData,
            submitted,
            handleSubmit,            
            getLogoPanelUrl,
            getLogoUrl,
            getFaviconUrl,
            showModalAuthType,
            color1,
            color2,
            appCfg,
            favicon,
            myShowModalAuthType,
            updateIsForgetPass,
            updateIsResetPass,
            loaded,
            isAuthenticated,
            username,
            loginMicrosoft,
            signinWiths,
            CatalogConfigurationConst,
            loginGoogle,
        };
    },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "45db1e20-color1": (_ctx.color1),
  "45db1e20-color2": (_ctx.color2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__