<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <Panel v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(PanelTypeConst.ID)" v-tooltip="getPropertyValue(PanelTypeConst.TOOLTIP)"
        :style="getPropertyValue(PanelTypeConst.STYLE)" :class="getPropertyValue(PanelTypeConst.CLASS)"
        :name="getPropertyValue(PanelTypeConst.NAME)" :disabled="getPropertyValue(PanelTypeConst.DISABLED)"
        :visible="getPropertyValue(PanelTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :placeholder="getPropertyValue(PanelTypeConst.PLACEHOLDER)"
        :header="getPropertyValue(PanelTypeConst.HEADER)" :toggleable="getPropertyBooleanValue(PanelTypeConst.TOGGLEABLE)"
        :collapsed="collapsed" v-model="vmodel"
        @toggle="toggle">
        <template #icons>
            <slot name="icons"></slot>
        </template>

        <template #header>
            <slot name="header"></slot>
        </template>

        <!-- <template #footer>
            <slot name="footer"></slot>
        </template> -->

        <slot name="content">
            
            <FormBuilder v-if="componentId>0 && (getPropertyBooleanValue(PanelTypeConst.TOGGLEABLE) == false || (
                getPropertyBooleanValue(PanelTypeConst.TOGGLEABLE) == true && collapsed == false

             ) ) "  :container="container" :componentId="componentId" :applicationId="Component.applicationId"
            :applicationVersion="Component.applicationVersion" :attrsInput="attrsInput" />                

        </slot>


     
    </Panel>
</template>
<script lang="ts">


import { defineComponent, onBeforeUnmount, shallowRef,watchEffect, onMounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import PanelTypeConst from '../../../../domain/Constants/PanelTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import EventBusCustom from '../../../../../../../common/infrastructure/event-bus-custom';
import { IapComponentAttribute } from '../../../../../component/domain/iapComponentAttribute';
import { PanelToggleEvent } from 'primevue/panel';

export default defineComponent({
    name: 'dynamic_panel',
    components: {
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },     
        slotProps: {
            type: Object,
            default: () => ({})
        },
    },
    setup(props) {
        //       
        const keyEventComponent = 'currentpnl_' + props.Component.formKey + (props.Component.rootParentId ?? -1).toString() + '_' + props.Component.id.toString() + '_' + EventConst.SHOWCOMPONENT;
        const store = useStore();
        const { vmodel,canDoOperation, loaded, getPropertyValue, getPropertyBooleanValue, baseOnBeforeUnmount
            ,fieldKey,fieldKeyComponentDataSourceId ,componentKey, getPropertyId,processEvent,setPropertyValueByName
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);
        
        const componentId = shallowRef(-1);
        const attrsInput = shallowRef<IapComponentAttribute[]>([]);
        const collapsed = shallowRef(getPropertyBooleanValue(PanelTypeConst.COLLAPSED) ?? false);

        const doComponentEvent = (evt: { componentId: number, header: string, modal: boolean, attrs: IapComponentAttribute[], callBackResponse: any }) =>{
            componentId.value = evt.componentId;
            attrsInput.value  = evt.attrs;

                if (evt.callBackResponse) {
                    evt.callBackResponse(true)
                }
        }

        const toggle = (event: PanelToggleEvent) =>{
            setPropertyValueByName(PanelTypeConst.COLLAPSED,event.value);
            collapsed.value = event.value;
        }
        
        onMounted(()=>{
            
                const propsIdCmd = getPropertyId(PanelTypeConst.CMD_DETAIL) ?? '';
                const hasWf = props.Component?.workFlows?.find(x => x.idObjeto == CatalogObjectTypeConst.ATTRCOMP && x.objetoId == propsIdCmd && x.active == true)
                if (propsIdCmd && hasWf) { 
                    // esto es para mostrar los detalles
                    EventBusCustom.on(keyEventComponent,doComponentEvent);
                    
                    watchEffect(() => {
                        
                    if (loaded.value == true && (getPropertyBooleanValue(PanelTypeConst.TOGGLEABLE) == false || (
                getPropertyBooleanValue(PanelTypeConst.TOGGLEABLE) == true && collapsed.value == false))) {
                    
                        processEvent(CatalogObjectTypeConst.ATTRCOMP, propsIdCmd, props.Component,keyEventComponent);
                    }
                    else{
                        componentId.value = -1;
                    }
                })
                   
                }
        })


        onBeforeUnmount(() => {            
            EventBusCustom.off(keyEventComponent,doComponentEvent);   
            componentId.value = -1;
            attrsInput.value.length = 0;              
            baseOnBeforeUnmount();
        })



        return {
          
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            PanelTypeConst,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
            ,componentId
            ,attrsInput
            ,toggle
            ,collapsed
        };
    },
});
</script>
<style scoped></style>
