<template>
    
    <div v-if="objectId && idObject && loadedDocuments">
        <UploadFile v-if="canAdd && !showAddinPopUp" :container="container" :Component="Component" 
                :idObject="idObject" :objectId="objectId" :caption="caption" :fileExtensions="fileExtensions"
                :entityMainIntegracionId="entityMainIntegracionId"
                :maxFileSize="maxFileSize" :multiple="multiple" :captionDropArea="captionDropArea" :source="source"
                @document_added="loadDocuments(); opAddFile=false" />

        <Dialog v-if="canAdd && opAddFile && showAddinPopUp" ref="slotControls" v-model:visible="opAddFile" header="Añadir ficheros"
            maximizable modal styleClass="mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6" :style="{ width: '50rem' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
            contentStyleClass="border-round-bottom border-top-1 surface-border p-0" >

            <UploadFile v-if="canAdd" :container="container" :Component="Component" 
                :idObject="idObject" :objectId="objectId" :caption="caption" :fileExtensions="fileExtensions"
                :maxFileSize="maxFileSize" :multiple="multiple" :captionDropArea="captionDropArea" :source="source"
                :entityMainIntegracionId="entityMainIntegracionId"
                @document_added="loadDocuments(); opAddFile=false" />

        </Dialog>
        <div v-if="onlyListDocument" class="card">
            <DataTable ref="dt"  v-model:selection="dataSelected" :value="documentsData" editMode="cell" @cell-edit-complete="onCellEditComplete($event)"
                tableClass="editable-cells-table" :filters="filters" :paginator="true" :rows="10"
                class="p-datatable-gridlines" :dataKey="id" :rowHover="true" :onPage="dataTablePageEvent"
                :onSort="dataTableSortEvent" :onFilter="dataTableFilterEvent" responsiveLayout="scroll"
                sortMode="multiple" resizableColumns columnResizeMode="expand"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="10" :reorderableColumns="true" scrollable scrollHeight="600px" removableSort
                currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} registros"
                :exportFilename="Component.name??'ResultadoBusqueda'" :csvSeparator="';'">
                <template #header>
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">

                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            <span class="p-input-icon-right" style="display: inline;">
                                <i class="pi pi-times" v-tooltip.top="'Borrar búsqueda'"
                                    @click="filters['global'].value = ''" />
                            </span>
                            <TableExport v-model:table="dt" :filename="'ResultadoDocumentos'"></TableExport>
                            <Button id="refresh" icon="pi pi-refresh"
                                class="ml-2  p-button-rounded p-button-primary p-button-outlined"
                                @click="loadDocuments()" />

                            <Button v-if="canAdd && showAddinPopUp" id="insertar" icon="pi pi-plus"
                                class="ml-2  p-button-rounded p-button-primary p-button-outlined" @click="toggleNew" />
                        </span>


                    </div>

                </template>

                <template #empty> No se han encontrado documentos para los filtros indicados </template>

                <Column v-if="multipleSelection" selectionMode="multiple" headerStyle="width: 3rem"></Column>

                <Column field="acciones" header="Acciones" :sortable="false" :exportable="false" frozen
                    style="width: 20px; !important">
                    <template #body="{ data }">

                        <div class="flex centercontent">


                            <i class="pi pi-eye ml-2" style="font-size: 1.5rem" @click="previewDocument(data)"
                                v-tooltip="'Ver documento'"></i>
                            <i v-if="canDelete" class="pi pi-times ml-2" v-tooltip="'Eliminar documento'"
                                style="color:#D32F2F;font-size: 1.5rem" @click="deleteDocument(data)"></i>

                        </div>
                    </template>
                </Column>

                <Column field="description" :sortable="true" style="min-width: 12rem">
                    <template #header>
                        <i class="fa-solid fa-pen-to-square mr-2"></i> Descripción
                    </template>
                    <template #body="{ data }">
                        <span v-html="data.description"></span>
                    </template>
                    <template #editor="{ data }">
                        <div class="grid p-fluid">
                            <div class="col-12 md:col-12">
                                <InputText id="description" v-model="data.description"
                                    :class="{ 'p-invalid': !data.description }" />
                                <small class="p-invalid" v-if="!data.description">La descripción es obligatoria.</small>
                            </div>
                        </div>
                    </template>
                </Column>
                <Column field="documentTypeId" :sortable="true" style="min-width: 12rem">
                    <template #header>
                        <i class="fa-solid fa-pen-to-square mr-2"></i> Tipo
                    </template>
                    <template #body="{ data }">
                        <span v-html="getCatalogDescription(data.documentTypeId)"></span>
                    </template>
                    <template v-if="canEdit" #editor="{ data }">
                        <div class="grid p-fluid">
                            <div class="col-12 md:col-12">
                                <Dropdown filter :showClear="true" v-model="data.documentTypeId" filtered
                                    :options="docTypes" optionValue="id" optionLabel="description" />
                            </div>
                        </div>
                    </template>
                </Column>

                <Column v-if="isUserAdmin()" field="visibilityArray" :sortable="true" style="min-width: 12rem">
                    <template #header>
                        <i class="fa-solid fa-pen-to-square mr-2"></i> Visibilidad
                    </template>
                    <template #body="{ data }">
                        <span v-html="getVisibilityDescription(data.visibility)"></span>
                    </template>
                    <template v-if="canEdit && isUserAdmin()" #editor="{ data }">
                        <div class="grid p-fluid">
                            <div class="col-12 md:col-12">
                                <MultiSelect filter :showClear="true" v-model="data.visibilityArray" filtered
                                    :options="identityTypes" optionValue="id" optionLabel="description" />
                            </div>
                        </div>
                    </template>
                </Column>
                <Column field="fcr" :sortable="true" header="Fecha" style="min-width: 12rem">
                    <template #body="{ data }">
                        {{ formateaFecha(data.fcr) }}
                    </template>
                </Column>
                <Column field="ucrName" :sortable="true" header="Usuario" style="min-width: 12rem" />

            </DataTable>
        </div>

    </div>

    <DocumentViewer v-if="showViewer && selectedDoc" :visible="showViewer"
        @update:Visibility="showViewer = false; selectedDoc = null" :document="selectedDoc" :canvas="canvas" />
</template>
<script lang="ts">

import { defineComponent, ref, onMounted,computed } from 'vue';
import HelperCommon from '../../../../../common/infrastructure/funciones/HelperCommon';
import HelperLoading from '../../../../../common/infrastructure/funciones/HelperLoading';
import { MessageService } from '../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../common/infrastructure/servicios/MessageService';
import { useStore } from 'vuex';
import { FileUploadUploaderEvent } from 'primevue/fileupload';

import HelperUtils from '../../../../../common/infrastructure/funciones/HelperUtils';
import { IServiceDocument } from '../../application/IServiceDocument';
import { TYPES } from '../../../../../common/domain/types';
import { Container } from 'inversify';
import ComponentDataForm from '../../../../builder/designer/domain/ComponentDataForm';
import helperCatalog from '../../../../builder/catalog/infrastructure/helper/helperCatalog';
import fechaComp from '../../../../../common/infrastructure/funcionescompartidas/fechaComp';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { IapCatalog } from '../../../../builder/catalog/domain/iapCatalog';
import { usePrimeVue } from 'primevue/config';
import DocumentViewer from '../../../../../common/infrastructure/componentes/base/common/visor/DocumentViewer.vue' //../../../../componentes/base/portal/documentos/DocumentViewer.vue'; 
import { useI18n } from 'vue-i18n';
import { messages } from '../../../../builder/catalog/infrastructure/localization/Messages';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Environment from '../../../../../common/infrastructure/funciones/environment';
import { StorageDocument } from '../../domain/storageDocument';
import DataBaseTypeConst from '../../../../../common/domain/constantes/DataBaseTypeConst';
import UploadFile from './UploadFile.vue'

export default defineComponent({
    name: 'crud_document',
    components: {
        DocumentViewer,
        UploadFile
    },
    props: {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        rootParentId:
        {
            type: Number,
            default: (-1)
        },

        idObject:
        {
            type: String,
            default: ('')
        },
        objectId:
        {
            type: Number,
            default: (-1)
        },
        entityMainIntegracionId:{
            type: Number,
            default: (1)

        },
        caption:
        {
            type: String,
            default: ('')
        },

        fileExtensions:
        {
            type: String,
            default: ('')
        },
        maxFileSize:
        {
            type: Number,
            default: (-1)
        },

        multiple:
        {
            type: Boolean,
            default: (false)
        },

        captionDropArea:
        {
            type: String,
            default: ('')
        },


        canAdd:
        {
            type: Boolean,
            default: (false)
        },

        canEdit:
        {
            type: Boolean,
            default: (false)
        },

        canDelete:
        {
            type: Boolean,
            default: (false)
        },
        onlyListDocument:
        {
            type: Boolean,
            default: (false)
        },

        showAddinPopUp:
        {
            type: Boolean,
            default: (false)
        },
        source:
        {
            type: String,
            default: (DataBaseTypeConst.DOCUMENTOS_MODEL)
        },

        multipleSelection:
        {
            type: Boolean,
            default: (false)
        },
        multipleSelectionData:
        {
            type: Object  as () => StorageDocument[],
            default: ([])
        },

    },
    setup(props,{emit}) {
        const { t, locale } = useI18n(messages)
        const store = useStore();
        const uploaderhtml = ref();        
        const documentsData = ref<StorageDocument[]>([])
        const doctype = (Environment.APPID == 3 || Environment.APPID == 32) ? 'documentTypeTable' : 'documentoTipo'
        const profiletype = (Environment.APPID == 3 || Environment.APPID == 32) ? 'entityTypeTable' : 'identidad'
        // 
        const docTypes = ref<IapCatalog[]>(helperCatalog.getCatalog(doctype).filter(x => (doctype == 'documentoTipo' && x.parentId == props.idObject) || doctype == 'documentTypeTable'))
        const identityTypes = ref<IapCatalog[]>(helperCatalog.getCatalog(profiletype).filter(x => (profiletype == 'identidad' && x.additionalValue == 'Perfil') || profiletype == 'entityTypeTable'))
        //const documentType = helperCatalog.getCatalog('documentoTipo')
        const { formateaFecha } = fechaComp();
        const filters = ref({
            'global': { value: '', matchMode: FilterMatchMode.CONTAINS },
            'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        const showViewer = ref(false);
        const selectedDoc = ref();
        const canvas = ref();
        const loadedDocuments = ref(false);
        const opAddFile = ref(false);


        const dataSelected = computed({
            get: () => {
                
               
                    return props.multipleSelectionData
               

            },
            set: (val) => emit('update:multipleSelectionData', val),
        });


        const isUserAdmin = () => {
            const adminProfiles = ['identidad-ADM', 'identidad-MED']
            return store.getters.getCurrentUser.isAdmin || adminProfiles.includes(store.getters.getCurrentUser?.perfil?.profileId);
        }


        const loadDocuments = () => {
            if (props.container) {
                HelperLoading.showLoading();
                const _srv = props.container.get<IServiceDocument>(TYPES.STORAGE_REPOSITORY)
                _srv.getByObjectId(props.idObject, props.objectId, props.Component.id as any, props.source).then(response => {
                    if (response) {
                        documentsData.value = response.filter(x => !(x.markForDelete ?? false)).sort((a, b) => {
                            return (Number(b.fcr) - Number(a.fcr));
                        });

                        documentsData.value.forEach(doc => {
                            doc['visibilityArray'] = doc.visibility?.split(',').filter(x => x) ?? []
                        })

                        if (!store.getters.getCurrentUser.isAdmin) {
                            documentsData.value = documentsData.value.filter(x => ['visibilityArray'].includes(store.getters.getCurrentUser?.perfil?.profileId) || HelperCommon.isNullOrWhitespace(x.visibility ?? ''))
                        }

                    }
                }).finally(() => {
                    HelperLoading.hideLoading();
                    loadedDocuments.value = true;
                })
            }

        }

  

      


        const previewDocument = (data: StorageDocument) => {
            selectedDoc.value = JSON.parse(JSON.stringify(data));
            if (HelperCommon.isNullOrWhitespace(data.urlLink ?? '')) {
                if (props.container) {
                    HelperLoading.showLoading();
                    const _srv = props.container.get<IServiceDocument>(TYPES.STORAGE_REPOSITORY)
                    _srv.getContent(data.id, props.Component.id as any, props.source).then(response => {

                        if (!HelperCommon.isNullOrWhitespace(response)) {
                            selectedDoc.value.content = response;
                            showViewer.value = true;
                        }
                    }).finally(() => {
                        HelperLoading.hideLoading();
                    })
                }
            }
            else {
                showViewer.value = true;
            }
        }

        const deleteDocument = (data: StorageDocument) => {
            var doCallback = (): void => {
                if (props.container) {


                    const _srv = props.container.get<IServiceDocument>(TYPES.STORAGE_REPOSITORY)

                    HelperLoading.showLoading()
                    _srv.delete(data.id, props.Component.id as any, props.source).then(response => {
                        if (response) {

                            MessageService.showToast(MessageType.Correcto, '', 'Se ha eliminado el documento correctamente.')
                            documentsData.value = documentsData.value.filter(x => x.id !== data.id)

                        }
                    })
                        .finally(() => {
                            HelperLoading.hideLoading()
                        })
                }
            }
            MessageService.showMessage(MessageType.Pregunta, '', 'Se va a proceder a eliminar el documento <b>' + data.description + ' </b>. <br/><b>¿Desea continuar?</b>', true, true, false, '', doCallback);
        }

        const onCellEditComplete = (event: any) => {

            let { data, newData, field } = event;
            const newValue = newData[field];

            if (data[field] !== newValue) {
                if (props.container) {
                    const _data = JSON.parse(JSON.stringify(newData)) as StorageDocument
                    _data.fum = new Date();
                    _data.uum = store.getters.getCurrentUser.id;

                    if (field == 'visibilityArray') {
                        _data.visibility = newValue?.join(',')
                    }

                    delete _data['visibilityArray']

                    const _srv = props.container.get<IServiceDocument>(TYPES.STORAGE_REPOSITORY)
                    _srv.update(_data, '', props.Component.id as any, props.source).then(response => {
                        if (response) {
                            data[field] = newValue
                            if (field == 'visibilityArray') {
                                data.visibility = newValue?.join(',')
                            }
                        }
                    });

                }
            }

        };

        const getCatalogDescription = (typeId: string) => {
            return docTypes.value.find(x => x.id == typeId)?.description ?? ''
        }


   

        const toggleNew = (event: any) => {
            opAddFile.value = true;

        }

        const getVisibilityDescription = (visibility: string) => {
            const data = visibility?.split(',');
            return identityTypes.value.filter(x => data?.includes(x.id)).map(x => x.description).join(',')
        }


        onMounted(() => {
            loadDocuments();
        });


        return {
            docTypes,
            documentsData,
            previewDocument,
            deleteDocument,
            getCatalogDescription,
            formateaFecha,
            onCellEditComplete,
            filters,
            uploaderhtml,
            
            showViewer,
            selectedDoc,
            canvas,
            loadDocuments,
            toggleNew,
            identityTypes,
            getVisibilityDescription,
            loadedDocuments,
            opAddFile,
            t,
            isUserAdmin     ,
            dataSelected       
        };
    },
});
</script>
<style scoped></style>
