<template>
    <span>To</span>
  
    <div style="display: flex;">
        <textarea style="resize: none;" @pointerdown.stop="" @dblclick.stop="" id="w3review" name="msgText" rows="2" cols="50" v-model="selectedTo"
            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full "    
        ></textarea>
        <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-subscript"
            @click="showExpression(SendMailNodeConst.VAR_TO_IN,'Destinatario')"></i>
    </div>

    <br />

    <span>CC</span>
  
    <div style="display: flex;">
        <textarea style="resize: none;" @pointerdown.stop="" @dblclick.stop="" id="w3review" name="msgText" rows="2" cols="50" v-model="selectedCc"
            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full "    
        ></textarea>
        <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-subscript"
            @click="showExpression(SendMailNodeConst.VAR_CC_IN,'Con copia a')"></i>
    </div>

    <br />

    <span>Asunto</span>
  
    <div style="display: flex;">
        <textarea style="resize: none;" @pointerdown.stop="" @dblclick.stop="" id="w3review" name="msgText" rows="2" cols="50" v-model="selectedSubject"
            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full "    
        ></textarea>
        <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-subscript"
            @click="showExpression(SendMailNodeConst.VAR_SUBJECT_IN,'Asunto')"></i>
    </div>

    <br />

    <span>Mensaje</span>
  
    <div style="display: flex;">
        <textarea style="resize: none;" @pointerdown.stop="" @dblclick.stop="" id="w3review" name="msgText" rows="2" cols="50" v-model="selectedBody"
            class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full "    
        ></textarea>
        <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-subscript"
            @click="showExpression(SendMailNodeConst.VAR_BODY_IN,'Con copia a')"></i>
    </div>

    <br />
    
</template>
<script lang="ts">

import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import Dropdown from 'primevue/dropdown';
import debounce from 'lodash.debounce';
import HelperCustomComponentControls from '../../../functions/helperCustomComponentControls';
import SendMailNodeConst from '../constants/SendMailNodeConst';
import Textarea from 'primevue/textarea';
//import PrimeVue from "primevue/config";
export default defineComponent({
    name: 'custommsgcontrol',
    components: {
        Dropdown,
        Textarea,
        //PrimeVue
    },
    props: {
        data: Object
    },
    setup(props) {
        const { showExpression } = HelperCustomComponentControls(props.data);
        const selectedTo = ref('');
        const selectedCc = ref('');
        const selectedSubject = ref('');
        const selectedBody = ref('');

        let debounceUpdateData: any;
        const debounceTime = 1200;
       
    
        
        const initDebounce = () => {
            debounceUpdateData = debounce((data: any, start: boolean = false) => {
                if (props.data){
                    props.data.onChange({ key: SendMailNodeConst.VAR_TO_IN, data: selectedTo.value })
                    props.data.onChange({ key: SendMailNodeConst.VAR_CC_IN, data: selectedCc.value })
                    props.data.onChange({ key: SendMailNodeConst.VAR_SUBJECT_IN, data: selectedSubject.value })
                    props.data.onChange({ key: SendMailNodeConst.VAR_BODY_IN, data: selectedBody.value })
                }
            }, debounceTime);
        }

        watch(
            selectedTo, (newvalue, oldvalue) => {
                if (oldvalue !== newvalue && oldvalue !== undefined) {
                    debounceUpdateData(newvalue, true);            
                }
            },
            {
                flush: 'post',
                //immediate: true, 
                deep: true
            }
        )

        watch(
            selectedCc, (newvalue, oldvalue) => {
                if (oldvalue !== newvalue && oldvalue !== undefined) {
                    debounceUpdateData(newvalue, true);            
                }
            },
            {
                flush: 'post',
                //immediate: true, 
                deep: true
            }
        )

        watch(
            selectedSubject, (newvalue, oldvalue) => {
                if (oldvalue !== newvalue && oldvalue !== undefined) {
                    debounceUpdateData(newvalue, true);            
                }
            },
            {
                flush: 'post',
                //immediate: true, 
                deep: true
            }
        )

        watch(
            selectedBody, (newvalue, oldvalue) => {
                if (oldvalue !== newvalue && oldvalue !== undefined) {
                    debounceUpdateData(newvalue, true);            
                }
            },
            {
                flush: 'post',
                //immediate: true, 
                deep: true
            }
        )

        onMounted(() => {
            if (props.data) {
                selectedTo.value = props.data[SendMailNodeConst.VAR_TO_IN]
                selectedCc.value = props.data[SendMailNodeConst.VAR_CC_IN]
                selectedSubject.value = props.data[SendMailNodeConst.VAR_SUBJECT_IN]
                selectedBody.value = props.data[SendMailNodeConst.VAR_BODY_IN]
            }
            initDebounce();
        })


        onBeforeUnmount(() => {
            debounceUpdateData.cancel();
        })
        
        return {
            selectedTo,
            selectedCc,
            selectedSubject,
            selectedBody,
            showExpression,
            SendMailNodeConst
        };
    },
});
</script>
<style scoped></style>
