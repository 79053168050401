import { Dictionary } from "../../expression/domain/dictionary";
import ControlTypeConst from "./Constants/ControlTypeConst";
import DynamicControlNameConst from './Constants/DynamicControlNameConst'
import CatalogCompConst from "../../catalog/domain/const/CatalogCompConst";
import helperCatalog from "../../catalog/infrastructure/helper/helperCatalog";
import CatalogTypeConst from "@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogTypeConst";
import HelperCommon from "@ilinium/shared/src/common/infrastructure/funciones/HelperCommon";
import CatalogConfigurationConst from "../../catalog/domain/const/CatalogConfigurationConst";
import CrudTableTypeConst from "./Constants/CrudTableTypeConst";
import { IapComponent } from "../../component/domain/iapComponent";
import Environment from "@ilinium/shared/src/common/infrastructure/funciones/environment";
import HelperApplication from "@ilinium/shared/src/entidades/builder/application/infrastructure/functions/helperApplication";


export default function ComponentRenderAux(store:any) {


        //En esta constante se meten los slots que tienen data. El slot tiene parametros que se usarian en los hijos del slot. 
        const slotData=[ ControlTypeConst.CTLAYOUT_AUTOCOMPLETE_CONTENT,ControlTypeConst.CTLAYOUT_AUTOCOMPLETE_OPTION,ControlTypeConst.CTLAYOUT_AUTOCOMPLETE_OPTIONGROUP,
            ControlTypeConst.CTLAYOUT_MENU_ITEM,ControlTypeConst.CTLAYOUT_MENU_ITEMICON,ControlTypeConst.CTLAYOUT_DVIEW_LIST,ControlTypeConst.CTLAYOUT_TLIN_CONTENT,ControlTypeConst.CTLAYOUT_TLIN_OPPOSITE,
            ControlTypeConst.CTLAYOUT_TLIN_MARKER,ControlTypeConst.CTLAYOUT_DVIEW_GRID
        ]
        //controles que al ser contenedores deben tener el slot content. No se incluye los que tienen content de forma nativa como el dynamicCard
        const controlContent=[ CatalogCompConst.COMPONENTSAPP_COMP, ControlTypeConst.CTSPAN,ControlTypeConst.CTDIALOG,ControlTypeConst.CTDIV,ControlTypeConst.CTUL,ControlTypeConst.CTLI,
            ,ControlTypeConst.CTFIELDSET,
           CatalogCompConst.COMPONENTSAPP_COMPI,
            ControlTypeConst.CTTABVIEW
        ];


        const specialControlContent=[ ControlTypeConst.CTPANEL];

        const crudTablCond:string[]=[CrudTableTypeConst.LAYOUT_HEADER,CrudTableTypeConst.LAYOUT_HEADER_BUTTONS,CrudTableTypeConst.LAYOUT_DETAIL];

        const compsAppConversion: Dictionary<String, String>[] = [

            { key: CatalogCompConst.COMPONENTSAPP_COMP, value: DynamicControlNameConst.DYNAMIC_HTML_DIV }, 
            { key: CatalogCompConst.COMPONENTSAPP_COMPI, value: DynamicControlNameConst.DYNAMIC_HTML_DIV }, 
            
        ]  
                
        const getValueName =(catalogType:string, idType:string):string | undefined | null=>{
           const appConvers=catalogType==CatalogTypeConst.TIPOCONTROL?compsAppConversion.find(d=>d.key==idType):null;
           if(appConvers){
            return appConvers.value.toString();
           } 
           return  helperCatalog.getCatalog(catalogType).find(x => x.id == idType)?.additionalValue;
        }

        
        const getAppTemplateRoute = ():string | null => {
            const { getAppConfigObj } = HelperApplication(undefined)
            const appTemp:any= getAppConfigObj(CatalogConfigurationConst.TEMPLATE,Environment.ENVIRONMENT??'',store.getters.getAppConfig);

            let route:string="";  

            if(appTemp){
                const catTmp=helperCatalog.getCatalog(CatalogTypeConst.TEMPLAREND)
                const parentIdTmp=catTmp.find(c=>c.id==appTemp?.keyValue)?.parentId;
                route=catTmp.find(x=>x.id==parentIdTmp)?.additionalValue??'';
            }

            return !HelperCommon.isNullOrWhitespace(route)?route:null;
        }


        const  getDynamics=(imports:boolean,comps:IapComponent[]):string=>{
            const  compsIdTypes=Array.from( new Set(comps.map(c => c.idType)));
            const dynamicControlCat=helperCatalog.getCatalog(CatalogTypeConst.TIPOCONTROL).filter(x=>x.additionalValue && (compsIdTypes.includes(x.id) || x.id==ControlTypeConst.CTDIV)).flatMap(x=>x.additionalValue);
            let dynamics="";

            if(imports){
                const route=getAppTemplateRoute();
                
                dynamicControlCat.forEach(c=>{
                    dynamics+=`import ${c} from '${route}/${c}.vue';`;
                });
            }
            else{

                dynamicControlCat.forEach(c=>{
                    dynamics+=`${c},`;
                });
            }

            return dynamics;
        }


        const getVueFileImports=(comps:IapComponent[]):string=>{
            return `import { defineComponent } from 'vue';` +
            `import ComponentDataForm from '../../../designer/domain/ComponentDataForm';` +
            `import { Container } from 'inversify';` +
            `import { IapComponentAttribute } from '../../../component/domain/iapComponentAttribute';` +
            `import CrudTableTypeConst from '../../domain/Constants/CrudTableTypeConst';` +
            `import LookUpEditorTypeConst from '../../domain/Constants/LookUpEditorTypeConst';` +
            `import TabPanelTypeConst from '../../domain/Constants/TabPanelTypeConst';` +
            `import TabViewTypeConst from '../../domain/Constants/TabViewTypeConst';` +
            `import ComponentHelperRender from '../../domain/Functions/ComponentHelperRender'; ` +
            `import { useStore } from 'vuex';` +
            `${getDynamics(true,comps)}`;
        }
      
        const getVueFileCode = (rootParentId: number,comps:IapComponent[]): string => {
            return `
              export default defineComponent({
                name: '${rootParentId}',
                components: {
                  ${getDynamics(false,comps)}
                },
                props: {
                  container: {
                    type: Object as () => Container
                  },
                  comps: {
                   type: Object as () => ComponentDataForm[],
                    default: () => ([])
                  },
                  rootParentId: {
                    type: Number,
                    default: -1
                  },
                  slotProps: {
                       type: Object,
                       default: () => ({})
                  },
                  formKey: {
                    type: String,
                    default: ''
                  },
                    lookUpComps: {
                                type: Object as () => {
                                    compId: number;
                                    iapComponentAttribute: IapComponentAttribute | null;
                                }[],
                                default: () => ({})
                            },
                },
                setup(props) {
                  const store = useStore();
                  const { isVisibleTabPanel,getLayoutComponent,getPropertyBooleanValue,getPropertyNumberValue,getPropertyValue,compIdIndexTab } = ComponentHelperRender({}, props.slotProps, store);
          
                  return {
                    getLayoutComponent,
                    CrudTableTypeConst,
                    LookUpEditorTypeConst,
                    TabPanelTypeConst,
                    TabViewTypeConst,
                    isVisibleTabPanel,
                    getPropertyBooleanValue,
                    getPropertyNumberValue,
                    getPropertyValue
                  };
                }
              });
            `;
          };

     
  
    return {
        slotData,
        controlContent,
        getValueName,
        getVueFileImports,
        getVueFileCode,
        crudTablCond,
        specialControlContent
    };
  
  }
  

