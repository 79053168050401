
import { CustomMenu } from "../../../../../common/domain/modelos/menu/CustomMenu";
import store from "../../../../../common/infrastructure/almacen";
import { Actions, Mutations } from "../../../../../common/infrastructure/almacen/enums/StoreEnums";
import { CookieService } from "../../../../../common/infrastructure/servicios";
import HelperUtils from "../../../../../common/infrastructure/funciones/HelperUtils";
import HelperCommon from "../../../../../common/infrastructure/funciones/HelperCommon";
import HelperApplication from '@ilinium/shared/src/entidades/builder/application/infrastructure/functions/helperApplication';
import { useLayoutBuilder } from '../../../../builder/template/infrastructure/prime/LayoutBuilder'
import { Container } from "inversify";
import { defineAsyncComponent, ref } from "vue";
import { authRequest } from "../../domain/authRequest";
import CatalogConfigurationConst from '@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogConfigurationConst';
import HelperLoading from '@ilinium/shared/src/common/infrastructure/funciones/HelperLoading';
import helperCatalog, { getCatalogsFromService } from '@ilinium/shared/src/entidades/builder/catalog/infrastructure/helper/helperCatalog';
import Environment from '@ilinium/shared/src/common/infrastructure/funciones/environment'

const { changeAppLayoutTemplate } = useLayoutBuilder();

const getApplicationMenus = async (fromShared: boolean, routes: any, applicationId: number, applicationVersion:number, container: Container, routeToRedirect: string, doMFA: boolean): Promise<boolean> => {
  let showModalAuthType = false;
  return await new Promise<boolean>((resolve) => {
  store
    .dispatch(Actions.SET_MENU, [applicationId, applicationVersion, container])
    .then(() => {
      //visibleRight.value = false;      
      buildRoutes(fromShared, routes, container, store.getters.getApplicationMenus, -1);      
      store.dispatch(Actions.SET_ROUTES, routes)
    })
    .finally(() => {
      changeAppLayoutTemplate(applicationId, applicationVersion, container);

      if (routeToRedirect == '') {
        showModalAuthType = redirectCompleteLogin(routes, doMFA);
      }
      else {
        routes.push({ name: routeToRedirect });
      }
      
      resolve(showModalAuthType);
    });
  });
}


const rebuildRoutes = async (fromShared: boolean, routes: any, applicationId: number, applicationVersion:number, container: Container, routeToRedirect: string, doMFA: boolean): Promise<boolean> => {
  let showModalAuthType = false;
  return await new Promise<boolean>((resolve) => {
    buildRoutes(fromShared, routes, container, store.getters.getApplicationMenus, -1);      
    changeAppLayoutTemplate(applicationId, applicationVersion, container);

    if (routeToRedirect == '') {
      showModalAuthType = redirectCompleteLogin(routes, doMFA);
    }
    else {
      routes.push({ name: routeToRedirect });
    }
    
    resolve(showModalAuthType);
  });
}

const formatMenuCaption = (menu:CustomMenu,parentLabel: string | null) =>{
  if (!HelperCommon.isNullOrWhitespace(parentLabel ?? '')){
    return ((parentLabel ?? '') + '/' + (menu.label ?? ''))
  }

    return menu.label
}

/*
En el menu el campo 'route' es la ruta de componentes estaticos 
*/
const buildRoutes = (fromShared: boolean, routes: any, container: Container, menusBuilder: CustomMenu[], parentId: -1, parentLabel: string | null = null) => {
  if (menusBuilder?.length > 0) {
    menusBuilder
      .filter(
        (x) =>
          (parentId == -1 && x.parentId == null) ||
          (parentId !== -1 && x.parentId == parentId)
      )
      .sort((x) => x.order ?? 0)
      .forEach((menu) => {
        if ((menu.to?.toString()) || menu.componentId != null) {
          //********* En el menu el campo 'route' es la ruta de componentes estaticos  ******************
          const menuRoute = (menu.route?.toString() ?? 'EmptyPage.vue')
          let componentaux = getComponentFromShared(menu.componentId, menuRoute);

          if (!fromShared) {
            componentaux = getComponentFromOutsideShared(menu.componentId,menuRoute);
          }
          const route = {
            path: menu.to?.toString(),// +'/:queryOperation?' ,
            force:true,
            name:menu.id.toString(),
            meta: {
              breadcrumb: [
                {
                  parentLabel: menu.to?.toString() && menuRoute ? parentLabel : null,
                  label:menu.label,
                },
              ],
              directivas: "",
              builtin: "",
              contrato: menu.contract,
              keepAlive:menu.keepAlive,
              componentId:menu.componentId,
              tag:null,
              caption:formatMenuCaption(menu,parentLabel)
            },
            component: componentaux,
            props: {
              container: container,
              componentId: menu.componentId,
              applicationId: menu.applicationId,
              applicationVersion: menu.applicationVersion,
              menuId:menu.id
            },
          }

          routes?.addRoute(route);

        }
        buildRoutes(fromShared, routes, container, (menu.items as CustomMenu[] ?? []), menu.id as any, (parentLabel == null ? '' : (parentLabel + ' > ') ) + menu?.label as any);
      });
  }
};

function getComponentFromShared(componentId: number | null, route: string) {

  if (componentId != null) {
    return defineAsyncComponent(() => import('../../../../builder/form/infrastructure/FormBuilder.vue'));
  }

  switch (route) {
    case '../../../../builder/application/infrastructure/component/Applications.vue':
      return defineAsyncComponent(() => import('../../../../builder/application/infrastructure/component/Applications.vue'));
    case '../../.././../builder/designer/infrastructure/component/Container.vue':
      return defineAsyncComponent(() => import('../../../../builder/designer/infrastructure/component/Designer.vue'));

    case "../../../../otros/inicio/mipanel/infrastructure/MiPanel.vue":
      return defineAsyncComponent(() => import(
        "../../../../otros/inicio/mipanel/infrastructure/MiPanel.vue"
      ));
    case "EmptyPage.vue":
        return defineAsyncComponent(() => import('../../../../../common/infrastructure/EmptyPage.vue'));
    default:
      return defineAsyncComponent(() => import(route));
  }
}

function getComponentFromOutsideShared(componentId: number | null, route: string) {
  if (componentId != null) {
    return defineAsyncComponent(() => import("@ilinium/shared/src/entidades/builder/form/infrastructure/FormBuilder.vue"));
  }

  switch (route) {
    case '../../../../builder/application/infrastructure/component/Applications.vue':
      return defineAsyncComponent(() => import('@ilinium/shared/src/entidades/builder/application/infrastructure/component/Applications.vue'));
    case '../../.././../builder/designer/infrastructure/component/Container.vue':
      return defineAsyncComponent(() => import('@ilinium/shared/src/entidades/builder/designer/infrastructure/component/Designer.vue'));

     case "../../../../otros/inicio/mipanel/infrastructure/MiPanel.vue":
      return defineAsyncComponent(() => import(
        "@ilinium/shared/src/entidades/otros/inicio/mipanel/infrastructure/MiPanel.vue"
      ));

      case "EmptyPage.vue":
        return defineAsyncComponent(() => import('@ilinium/shared/src/common/infrastructure/EmptyPage.vue'));
    default:
      return defineAsyncComponent(() => import(route));
  }
}

function changeEntity(routes: any, container: Container, visibleRight: any, whoAmIProfilesResponse: any, brokerId: any, perfilId: any, userEmail: any, selectedApplication: any, userApplications: any) {
  var profileIdentifier = whoAmIProfilesResponse?.entityProfiles.filter((p:any) => { return p.id === perfilId/* && p.entityMainId == brokerId*/ })[0].id;
  const { getAppKeyValue } = HelperApplication(undefined)
  //if (brokerId == store.getters.currentBrokerId) {
    //  store.dispatch(Actions.CHANGEPROFILE, profileIdentifier)
    //  visibleRight.value = false;
    //  routes?.push({ name: 'mipanel' });
  //}
  //else {
      const request = ref<authRequest>({
          grant_type: 'password',
          username: userEmail,
          password: '',
          microsoftEmail: null,
          googleEmail: null,
          client_id: '',
          client_secret: '',
          refresh_token: '',
          brokerId: brokerId,
          profileId: profileIdentifier.toString(),
          applicationId: selectedApplication.id,
          applicationVersion: selectedApplication.version,
          external: (getAppKeyValue(CatalogConfigurationConst.ISAPPEXTERNAL,Environment.ENVIRONMENT??'',userApplications.find((a:any) => a.id == selectedApplication.id)?.iapApplicationConfigurations) === "true")  ? true : false,
          sessionId:''
      });

      HelperLoading.showLoading();
      
      store
          .dispatch(Actions.AUTH, [request, container])
          .then(() => {
              getCatalogsFromService(container, request.value.applicationId ? request.value.applicationId : 0,request.value.applicationVersion ? request.value.applicationVersion : 0).then(response => {
                  store.commit(Mutations.SET_AUTHCOMPLETE, null)
                  getApplicationMenus(true, routes, request.value.applicationId ? request.value.applicationId : 0, request.value.applicationVersion ? request.value.applicationVersion : 0, container, 'mipanel', true).then(() => {
                      visibleRight.value = false;
                      store.dispatch(Actions.CHANGEPROFILE, profileIdentifier)
                  });
              })
          })
          .finally(() => {
              HelperLoading.hideLoading();
              //routes?.push({ name: 'mipanel' });
          }).catch(e => {

              throw new Error(e);
          });
  //}
}

function redirectCompleteLogin(routes: any, doMFA: boolean) {
  let showModalAuthType = false;

  var redirectCookie = CookieService.getCookie(
    CookieService.COOKIE_REDIRECT_URL
  );
  if (redirectCookie != null && redirectCookie != "") {
    routes?.push({ name: redirectCookie });
  } else {
    
    var cookieDobleFactor = CookieService.getCookie(CookieService.COOKIE_DOBLEFACTOR);
   
    if (!doMFA || (!store.getters.getCurrentUser.iapmUserOptions ||!store.getters.getCurrentUser.iapmUserOptions.find((c: { optionId: string }) => c.optionId == 'cfgbroker-MFA')?.value)
    ||
        ((cookieDobleFactor != null && cookieDobleFactor != '') &&
        store.getters.getCurrentUser.iapmUserOptions.find((c: { optionId: string }) => c.optionId == 'cfgbroker-MFA')?.value &&
        store.getters.getCurrentUser.multiFactorAuthentication != null &&
        store.getters.getCurrentUser.multiFactorAuthentication.code &&
        store.getters.getCurrentUser.multiFactorAuthentication.code != '' &&
        store.getters.getCurrentUser.multiFactorAuthentication.expireDateMultiFactor &&
        (new Date(store.getters.getCurrentUser.multiFactorAuthentication.expireDateMultiFactor) >= new Date()) &&
        store.getters.getCurrentUser.multiFactorAuthentication.validated)) 
        
        {
          var initUrl = store.getters.initUrlPage;
          if (initUrl == "") {
            const id = HelperUtils.newGuid();
            routes?.push({
              name: "rfps",
              params: { id: id, operation: "productos" },
            });
          } else {
            routes?.push({
              name: /*initUrl == 'default.aspx' ? */ "mipanel" /* : initUrl*/,
            });
          }
        }
    else {
        showModalAuthType = true;
    }
          
  }
  return showModalAuthType;
}


export default {
  getApplicationMenus,
  changeEntity,
  rebuildRoutes
}