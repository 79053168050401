
<SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
         :data="Component"
         :vmodel="vmodel"
         />
 <div v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :key="componentKey"         
     :id="getPropertyValue(HtmlTypeConst.ID)" v-tooltip="getPropertyValue(HtmlTypeConst.TOOLTIP)"
     :style="getPropertyValue(HtmlTypeConst.STYLE)"
     :class="{ [getPropertyValue(HtmlTypeConst.CLASS) ?? '']: true, 'customrequired': getPropertyBooleanValue(HtmlTypeConst.REQUIRED) }"
     :name="getPropertyValue(HtmlTypeConst.NAME)" :disabled="getPropertyBooleanValue(HtmlTypeConst.DISABLED)"
     :visible="getPropertyBooleanValue(HtmlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
     :placeholder="getPropertyValue(HtmlTypeConst.PLACEHOLDER)">
     <slot name="content"></slot>
     {{ getPropertyValue(HtmlTypeConst.TEXT) }}
     {{ vmodel }}

 </div>
