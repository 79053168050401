<template>
    <span>Teléfono</span>
      
    <div style="display: flex;">
        <textarea style="resize: none;" @pointerdown.stop="" @dblclick.stop="" id="w3review" name="msgText" rows="2" cols="50" v-model="selectedPhone"
    class="flex justify-content-center p-dropdown p-component p-inputwrapper p-inputwrapper-focus p-overlay-open w-full "    
    ></textarea>
            <i @pointerdown.stop="" @dblclick.stop="" aria-hidden="true" class="fa fa-subscript"
                @click="showExpression(UbmNodeConst.VAR_PHONE_IN,'Teléfono')"></i>
        </div>

    <br />
   
</template>
<script lang="ts">

import {  defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import Dropdown from 'primevue/dropdown';
import debounce from 'lodash.debounce';
import HelperCustomComponentControls from '../../../functions/helperCustomComponentControls';
import UbmNodeConst from '../constants/UbmNodeConst';
import Textarea from 'primevue/textarea';
//import PrimeVue from "primevue/config";
export default defineComponent({
    name: 'custommsgcontrol',
    components: {
        Dropdown,
        Textarea,
        //PrimeVue
    },
    props: {
        data: Object
    },
    setup(props) {
        const { showExpression } = HelperCustomComponentControls(props.data);
        const selectedPhone = ref('');


        let debounceUpdateData: any;
        const debounceTime = 1200;
       
    
        
  const initDebounce = () => {
    debounceUpdateData = debounce((data: any, start: boolean = false) => {
        if (props.data){
            props.data.onChange({ key: UbmNodeConst.VAR_PHONE_IN, data: selectedPhone.value })        
        }
    }, debounceTime);
  }

  watch(
    selectedPhone, (newvalue, oldvalue) => {
          if (oldvalue !== newvalue && oldvalue !== undefined) {
              debounceUpdateData(newvalue, true);            
          }
        },
        {
          flush: 'post',
          //immediate: true, 
          deep: true
        }
      )


        onMounted(() => {
            if (props.data) {
                selectedPhone.value = props.data[UbmNodeConst.VAR_PHONE_IN]

            }
            initDebounce();
        })


        onBeforeUnmount(() => {
            debounceUpdateData.cancel();
        })
        
        return {
            selectedPhone,
            showExpression,
            UbmNodeConst
        };
    },
});
</script>
<style scoped></style>
