import { IapComponent } from "../../../entidades/builder/component/domain/iapComponent";
import { Dictionary } from "../../../entidades/builder/expression/domain/dictionary";
import BaseControlTypeConst from "../../../entidades/builder/form/domain/Constants/BaseControlTypeConst";
import ControlTypeConst from "../../../entidades/builder/form/domain/Constants/ControlTypeConst";
import TabViewTypeConst from "../../../entidades/builder/form/domain/Constants/TabViewTypeConst";
import InteractionConst from "../../../entidades/builder/interaction/domain/interactionConst";
import { InteractionEvent } from "../../../entidades/builder/interaction/domain/interactionEvent";
import EventConst from "../../domain/constantes/EventConst";
import EventBusCustom from '../event-bus-custom'
import LocalService, { getLocalValue } from "../servicios/LocalService";
import HelperUtils from "./HelperUtils";


  
  
export const getComponentKeyLoaded = (compId:Number): string | undefined => {
  
  const data = getComponentLoaded();
  return data.find(x=> x.key == compId)?.value
}

export const getComponentLoaded = (): Dictionary<Number,string>[] => {
  
  const localData = getLocalValue(LocalService.COMP_LOADED) ?? '[]';
  if (localData == undefined || localData == "") {
    return [] as Dictionary<Number,string>[]
  }
  const data = JSON.parse(localData) as Dictionary<Number,string>[]
  return data;
}

export const setComponentLoaded = (dataInput:Dictionary<Number,string>): void => {
  
  const data = getComponentLoaded();
  if (data.find(x=> x.key == dataInput.key)=== undefined){
    data.push(dataInput);
    LocalService.setLocalValue(LocalService.COMP_LOADED, JSON.stringify(data))
  }
}

export const deleteComponentKeyLoaded = (compId:Number): void => {
  
  let  data = getComponentLoaded();
  data = data.filter( x=> x.key !== compId);
  LocalService.setLocalValue(LocalService.COMP_LOADED, JSON.stringify(data))
}



const buildInteractionEvent = (comp:IapComponent,_typeId:string,_objectId:any, _objName:string | null, _value:any) =>{

      var data: Partial<InteractionEvent> = {
        objectId:_objectId,
        typeId: _typeId,
        objectValue: _value,
        objectName: _objName

      }

      return data;
}

  
  const callInteraction = async (key: String, data: Partial<InteractionEvent>): Promise<InteractionEvent> => {
  
    return await new Promise<InteractionEvent>((resolve) => {
  
      var doCallbackOk = (response: InteractionEvent) => {
        resolve(response);
      }
      
      EventBusCustom.emit(key, { data: data, callBackResponse: doCallbackOk });
  
    });
  
  
  };
  

  async function resolveInteraction(comp: IapComponent | undefined, keyform: string, componentData: IapComponent[], compId: number, parameters: any) {
    
    if (comp) {
      switch (comp.idType) {
        case ControlTypeConst.CTCRUD:          
          const _key = 'current_' + keyform + (componentData[0].id ?? -1).toString() + compId.toString() + '_' + EventConst.NAVIGATION;
          const dataEventCrud = buildInteractionEvent(comp, InteractionConst.CALL_FUNCTION, comp.id, null, parameters);
          await callInteraction(_key, dataEventCrud);
          break;
        /*case ControlTypeConst.CTTABVIEW:
          break;
          */
        case ControlTypeConst.CTTABPANEL:
          // code block
          const attrDataTabView = componentData.find(x=> x.id == comp.parentId)?.iapComponentAttributes?.find(x => x.name ==  TabViewTypeConst.ACTIVEINDEX);
          const _keytab = keyform  + (componentData[0].id ?? -1).toString() + (comp.parentId ?? -1).toString() + EventConst.INTERACTION;
          const position = componentData.filter(x=> x.parentId == comp.parentId && HelperUtils.booleanify(x?.iapComponentAttributes?.find(x => x.name ==  BaseControlTypeConst.VISIBLE)?.value ?? 'false')).map((x,index) => ({pos:index,id:x.id })).find(z=> z.id == comp.id);
          if (attrDataTabView && position){
            const dataEventTabPanel = buildInteractionEvent(comp,InteractionConst.SET_PROPERTY_VALUE,attrDataTabView?.id,TabViewTypeConst.ACTIVEINDEX,position?.pos ?? 0)
            await callInteraction(_keytab,dataEventTabPanel);
  
          }
          break;
        default:
        // code block
      }
    }
  }
  


  export const doInteraction = async (keyform:string,componentData: IapComponent[],componetsIds:number[],parameters:any): Promise<boolean> => {

    return await new Promise<boolean>((resolve) => {
        componetsIds.forEach(compId =>{
          const comp = componentData.find(x=> x.id == compId);
          resolveInteraction(comp, keyform, componentData, compId, parameters);
        })
        resolve(true);
  
    });
}
  

  export default {doInteraction, getComponentKeyLoaded,getComponentLoaded,setComponentLoaded,deleteComponentKeyLoaded}
