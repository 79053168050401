<template>
    <Sidebar v-model:visible="visibleRight" position="right" class="layout-profile-sidebar w-full sm:w-25rem">
        <div class="flex flex-column mx-auto md:mx-0">
            <span class="text-color-secondary font-medium mb-5"><i class="fa fa-user"></i> {{ userName }}</span>
        </div>

        <span class="mb-2 font-semibold">Entidades relacionadas</span>
        <br>
        <Dropdown v-if="userApplications && userApplications.length > 1" v-model="selectedApplication" :options="userApplications" filter showClear optionLabel="name" placeholder="Seleccionar" class="w-full md:w-14rem" style="margin-top:5px;margin-bottom:10px;" @change="selectedBroker = { id: 0, name: '' };">
            <template #option="slotProps">
                <div class="flex align-items-center">
                    <img :src="slotProps.option.logo" alt="logo" class="dropdown-logo" style="width: 50px;margin-right:5px"  @error="noImageUrl($event)"/>
                    <span>{{ slotProps.option.name }}</span>
                </div>
            </template>
        </Dropdown>
        <Dropdown v-model="selectedBroker" :options="distinctEntityMainIds" filter showClear optionLabel="name" placeholder="Seleccionar" class="w-full md:w-14rem" style="margin-top:5px;margin-bottom:10px;">
            <template #option="slotProps">
                <div class="flex align-items-center">
                    <img :src="slotProps.option.logo" alt="logo" class="dropdown-logo" style="width: 30px;margin-right:5px"  @error="noImageUrl($event)"/>
                    <span>{{ slotProps.option.name }}</span>
                </div>
            </template>
        </Dropdown>
        <br>
        <InputText type="text" placeholder="Correduría o Perfil" v-model="filterEntityMain" style="margin-top:5px;margin-bottom:10px;" />
        <br>
        <div v-for="(e, index) of filteredEntities" :key="e.id">
          <!--<a @click="changeComponentTheme($event, t.file)" :class="['layout-config-option', { selected: theme === t.file }]" :style="{ backgroundColor: t.color }">
              <i class="pi pi-check" v-if="theme === t.file"></i>
          </a>-->
        <Card class="hover my-card" @mouseover="isHovering = true" @mouseleave="isHovering = false"  v-on:click="user?.isAdmin ? changeEntity(e.entityId, e.id) : changeEntity(getBrokerFromEntity(e.entityId), e.id)">
          <template #content>
            <div style="width: 25%; float: left;">
                <img class="logo" style="max-width: 80%" :src="getBrokerLogo(e.entityId)" :alt="'Logo del corredor:' + (user?.isAdmin ? entityBroker(e.entityId): entityBroker(getBrokerFromEntity(e.entityId)))"
                :title="'Logo del corredor: ' + (user?.isAdmin ? entityBroker(e.entityId): entityBroker(getBrokerFromEntity(e.entityId)))" @error="noImageUrl($event)" />
            </div>
            <div class="column" style="width: 70%; display: table;padding-left: 15px;">
                <span><i class="fa fa-building"></i> {{ user?.isAdmin ? entityBroker(e.entityId): entityBroker(getBrokerFromEntity(e.entityId)) }}</span>
                <br>
                <span><i class="fa fa-users"></i> {{ getDescription(e.profileId) }}</span>
            </div>
          </template>
        </Card>
      </div>
    </Sidebar>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { ref, reactive, computed, onMounted, inject, watch } from 'vue'
import EventBus from '@ilinium/shared/src/common/infrastructure/event-bus'
import EventConst from '@ilinium/shared/src/common/domain/constantes/EventConst'
import Cargando from '@ilinium/shared/src/common/infrastructure/componentes/base/common/cargando/Cargando.vue'
import Sidebar from 'primevue/sidebar';
import Card from 'primevue/card';
import { Container } from 'inversify';
import { Actions } from '@ilinium/shared/src/common/infrastructure/almacen/enums/StoreEnums'
import { authRequest } from '@ilinium/shared/src/entidades/builderMaster/auth/domain/authRequest'
import PerisApplicationConst from '@ilinium/shared/src/common/domain/constantes/Applications/PerisApplicationConst';
import { useRouter } from 'vue-router';
import { IapmCatalog } from '@ilinium/shared/src/entidades/builderMaster/user/domain/iapmCatalog';
import HelperLogin from '@ilinium/shared/src/entidades/builderMaster/auth/infrastructure/component/HelperLogin';
import HelperLoading from '@ilinium/shared/src/common/infrastructure/funciones/HelperLoading';
import helperCatalog, { getCatalogsFromService } from '@ilinium/shared/src/entidades/builder/catalog/infrastructure/helper/helperCatalog';
import Environment from '@ilinium/shared/src/common/infrastructure/funciones/environment'
import CatalogConfigurationConst from '@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogConfigurationConst'
import HelperApplication from '@ilinium/shared/src/entidades/builder/application/infrastructure/functions/helperApplication'

export default {
    name: 'AppActionBar',
    props: {
        container: {
            type: Object as () => Container
        },
        visibleRight: Boolean,
        whoAmIProfilesResponse: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        // Sugerencias
    },
    setup(props, { emit }) {
        const store = useStore()
        const catalogos = helperCatalog.getCatalog('identidad');
        let isLoading = ref<boolean>(false);
        const languages = [
            {
                id: 'es',
                description: 'ES'
            },
            {
                id: 'en',
                description: 'EN'
            }
        ]
        const routes = useRouter();
        
        const newsuggest = ref()
        const isError = ref(false)
        const ipublicPath = inject('publicPath', () => { })
        const isHovering = ref(false)
        const filterEntityMain = ref('')
        const selectedApplication = ref({ id: store.getters.getCurrentUser.id, version: store.getters.getCurrentUser.version })
        const selectedBroker = ref({ id: 0, name: '' })

        const visibleRight = computed({
            get: () => props.visibleRight as Boolean,
            set: (val) => emit('update:modelValue', val),
        });

        const getApplicationImage = (appId: number, appVersion: number) => {
            const url = process.env.VUE_APP_URLBUILDSHARED ?? '';
            let path = `/StaticFiles/app/${appId}_${appVersion}/img/logo.png`;
            let src = url + path;
            return src;
        }

        function changeEntity(brokerId, perfilId) {
            var profileIdentifier = props.whoAmIProfilesResponse?.entityProfiles.filter(p => { return p.id === perfilId/* && p.entityMainId == brokerId*/ })[0].id;
            const { getAppKeyValue } = HelperApplication(undefined)
            //if (brokerId == store.getters.currentBrokerId) {
              //  store.dispatch(Actions.CHANGEPROFILE, profileIdentifier)
              //  visibleRight.value = false;
              //  routes?.push({ name: 'mipanel' });
            //}
            //else {
                const request = ref<authRequest>({
                    grant_type: 'password',
                    username: userEmail.value,
                    password: '',
                    microsoftEmail: null,
                    googleEmail: null,
                    client_id: '',
                    client_secret: '',
                    refresh_token: '',
                    brokerId: brokerId,
                    profileId: profileIdentifier.toString(),
                    applicationId: selectedApplication?.value.id,
                    applicationVersion: selectedApplication?.value.version,
                    external: (getAppKeyValue(CatalogConfigurationConst.ISAPPEXTERNAL,Environment.ENVIRONMENT??'',userApplications.value.find(a => a.id == selectedApplication?.value.id)?.iapApplicationConfigurations) === "true")  ? true : false,
                    sessionId:''
                });

                isLoading.value = true;
                HelperLoading.showLoading();
                
                store
                    .dispatch(Actions.AUTH, [request, props.container])
                    .then(() => {
                        getCatalogsFromService(props.container, request.value.applicationId ? request.value.applicationId : 0,request.value.applicationVersion ? request.value.applicationVersion : 0).then(response => {})
                        HelperLogin.getApplicationMenus(true, routes, request.value.applicationId ? request.value.applicationId : 0, request.value.applicationVersion ? request.value.applicationVersion : 0, props.container, 'mipanel', true).then(() => {
                                visibleRight.value = false;
                                store.dispatch(Actions.CHANGEPROFILE, profileIdentifier)
                            });
                    })
                    .finally(() => {
                        isLoading.value = false;
                        HelperLoading.hideLoading();
                        //routes?.push({ name: 'mipanel' });
                    }).catch(e => {                        
                        throw new Error(e);
                    });
            //}
        }

        const userName = computed(() => {
            return store.getters.getCurrentUser.fullName;
        })
        const userEmail = computed(() => {
            return store.getters.getCurrentUser.userName;
        })
        
        const identityProfileId = computed(() => {
            return store.getters.getCurrentProfile;
        })

        const user = computed(() => {
            return store.getters.getCurrentUser;
        })

        const getBrokerFromEntity = (id: number) =>
        {
            return props.whoAmIProfilesResponse?.perfiles.filter(p => p.id == id)[0]?.entityMainId
        }

        const filteredProfiles = computed(() => {
            return props.whoAmIProfilesResponse?.perfiles.filter(p => { return p.id != identityProfileId.value /*|| p.entityMainId != brokerId.value*/ })
        })

        const filteredEntities = computed(() => {
            return props.whoAmIProfilesResponse?.entityProfiles.filter(p => {
                const identityCondition = p.id != identityProfileId.value || identityProfileId.value == -1; // que no sea el mismo con el que está conectado o sea admin
                
                var entityMainCondition = false;
                if (identityProfileId.value == -1 || identityProfileId.value == 0) { // admin
                    entityMainCondition = props.whoAmIProfilesResponse?.users.some(user =>
                        user.applicationId == selectedApplication?.value.id && user.applicationVersion == selectedApplication?.value.version &&
                        user.entityMains?.some(entityMain => entityMain.id === p.entityId) &&
                        (!selectedBroker.value || (selectedBroker.value.id == 0 || selectedBroker.value.id == p.entityId)) &&
                        (filterEntityMain.value == '' || filterByBrokerOrProfile(user, filterEntityMain.value, p.entityId, p.profileId))
                    );
                }
                else {
                    entityMainCondition = props.whoAmIProfilesResponse?.users.some(user =>
                        user.applicationId == selectedApplication?.value.id && user.applicationVersion == selectedApplication?.value.version &&
                        (!selectedBroker.value || (selectedBroker.value.id == 0 || selectedBroker && selectedBroker.value.id == p.entityId)) &&
                        (filterEntityMain.value == '' || filterByBrokerOrProfile(user, filterEntityMain.value, p.entityId, p.profileId))
                    );
                }
                
                // Devuelve el perfil si ambas condiciones se cumplen
                return identityCondition && entityMainCondition;
            });
        })

        function filterByBrokerOrProfile(user: any, inputText: string, entityMainId: number, profileId: string) {
            let result = false;
            if (user?.isAdmin) {
                result = ((entityBroker(entityMainId).toLowerCase().includes(inputText)) || (getDescription(profileId).toLowerCase().includes(inputText)))
            }
            else {
                result = ((entityBroker(getBrokerFromEntity(entityMainId)).includes(inputText)) || (getDescription(profileId).toLowerCase().includes(inputText)))
            }
            return result;
        }

        const distinctEntityMainIds = computed(() => {
            const entityMainIds = props.whoAmIProfilesResponse?.users
                .map(user => user.entityMains?.filter(em => em.applicationId == selectedApplication?.value.id)?.map(entityMain => entityMain.id))
                .flat();

            const uniqueIds = [...new Set(entityMainIds)]; // Elimina los duplicados

            // Mapea los IDs únicos a un array de objetos con 'id' y 'name'
            return uniqueIds.map(id => ({
                id: id,
                name: entityBroker(id),
                logo: getBrokerLogo(id) 
            }));
        });

        const userApplications = computed(() => {
            const apps = props.whoAmIProfilesResponse?.applications

            if (apps && apps.length > 0) {
                apps.forEach((app) => {
                    app.logo = getApplicationImage(app.id, app.version);
                });
                selectedApplication.value = apps[0];
            }
            return apps;
        });

        const currentUserProfile = computed(() => {
            const idPerfil = props.whoAmIProfilesResponse?.entityProfiles.find(p => { return p.id == identityProfileId.value /*&& p.brokerId == brokerId.value*/ }).profileId
            if (idPerfil) {
                return getDescription(idPerfil)
            }

            return 'Sin perfil'

        })

        const getDescription = (id: string) => {
            if (id) {
                return catalogos.find(x => x.id == id)?.description ?? '';
            }
            else return 'Todos';
        }

        const entityBroker = (brokerId) => {
            const result = props.whoAmIProfilesResponse?.users
                .filter(user => 
                    user.entityMains.some(em => {
                        if (brokerId < 0) return em?.id === brokerId;
                        else return em?.id === -brokerId;
                    })
                )
                .map(user => 
                    user.entityMains.filter(em => {
                        if (brokerId < 0) return em?.id === brokerId;
                        else return em?.id === -brokerId;
                    })[0]
                )[0]?.socialReason;

            return result ? result : 'No existe';
        }

        const entityProfile = (profileId) => {
            return props.whoAmIProfilesResponse?.entityProfiles.filter(ep => {
                            return ep.entityId === profileId
                        })[0].profileId
        }

        function noImageUrl(event: any) {
            const appPublic = process.env.VUE_APP_PUBLIC_PATH ?? '/';
            let src = `${appPublic}layout/images/pages/no-image.png`;
            event.target.src = src;
        }

        function getBrokerLogo(brokerId) {
            let pepe = Environment.URL_IMG_ENTITY + brokerId.toString() +'.png'
            return pepe
        }

        onMounted(() => {
            
        })

        return {
            ipublicPath,
            getBrokerLogo,
            userName,
            userEmail,
            newsuggest,
            isError,
            visibleRight,
            user,
            isHovering,
            changeEntity,
            languages,
            getDescription,
            identityProfileId,
            filteredProfiles,
            filteredEntities,
            store,
            isLoading,
            currentUserProfile,
            getBrokerFromEntity,
            entityBroker,
            entityProfile,
            noImageUrl,
            selectedApplication,
            userApplications,
            getApplicationImage,
            selectedBroker,
            distinctEntityMainIds,
            filterEntityMain
        }
    }
}
</script>



<style lang="css" scoped>
    .hover:hover {
        background-color: #1111;
    }

    .my-card {
        margin-bottom: 10px;
    }

    ::v-deep .p-card-body .p-card-content {
        padding: 0 !important;
    }

    ::v-deep .p-card-content img {
        max-width: 50px !important;
    }
</style>