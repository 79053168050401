
<template>

<SummaryDebug v-if="getPropertyBooleanValue(TreeTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
            
    <Tree 
        v-if ="loaded && (getPropertyBooleanValue(TreeTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW))" 
        :key="componentKey"
        :id="getPropertyValue(TreeTypeConst.ID)" 
        v-tooltip="getPropertyValue(TreeTypeConst.TOOLTIP)"
        :style="getPropertyValue(TreeTypeConst.STYLE)"
        :class="{[getPropertyValue(TreeTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted ,'p-readonly': getPropertyBooleanValue(TreeTypeConst.READONLY),'p-tree':true}"
        :name="getPropertyValue(TreeTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(TreeTypeConst.DISABLED) || (!canEdit)"
        :visible="getPropertyBooleanValue(TreeTypeConst.VISIBLE)"
        :placeholder="getPropertyValue(TreeTypeConst.PLACEHOLDER)"
        :readonly="getPropertyBooleanValue(TreeTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
        :required="getPropertyBooleanValue(TreeTypeConst.REQUIRED)"
        :tabIndex="getPropertyNumberValue(TreeTypeConst.TABINDEX)" 
        :value = "getValue()"
        :selectionMode = "getSelectionMode()"
        :metaKeySelection = "getPropertyBooleanValue(TreeTypeConst.METAKEYSELECTION)"  
        :expandedKeys = "expandedKey ?? undefined" 
        :selectionKeys = "selectedKey ?? undefined" 
        :filter = "getPropertyBooleanValue(TreeTypeConst.FILTER) ?? false" 
        :filterMode = "getFilterMode()"
        :filterPlaceholder = "getPropertyValue(TreeTypeConst.FILTERPLACEHOLDER)"
        :scrollHeight = "getPropertyValue(TreeTypeConst.SCROLLHEIGHT)"
        :highlightOnSelect = "getPropertyBooleanValue(TreeTypeConst.HIGHLIGHTONSELECT)" 
        :loading="getPropertyBooleanValue(TreeTypeConst.LOADING)"
        :loadingIcon="getPropertyValue(TreeTypeConst.LOADINGICON)"
        :loadingMode="getLoadingMode()"
        @node-expand="onNodeExpand"
        @node-collapse="onNodeCollapse"
        @node-select="onNodeSelect"
        @node-unselect="onNodeUnSelect"
        @update:expanded-keys="updateExpandedKeys"
        @update:selection-keys="updateSelectionKeys"
        v-model="arrayModel"
        @change ="setSelectedKeyToVModel()"
        @contextmenu="onRightClick"
        >
    </Tree>
    <CustomValidate v-if="loaded && getPropertyBooleanValue(TreeTypeConst.VISIBLE)" v-model:submitted="submitted" v-model:vObject="v$" />

    
    <ContextMenu ref="menu" :model="menuItems" />
    <div v-if="false" class="row">
        <div class="col-md-12">
            <label style="color:red;padding-left: 5px;">vmodel:  {{ vmodel == null ? 'null' : vmodel }} </label><br/>
            <label style="color:blue;padding-left: 5px;" for="">arrayModel:  {{ arrayModel == null ? 'null' : arrayModel }} </label><br/>
            <label style="color:orangered;padding-left: 5px;">SelectedKey:  {{ selectedKey == null ? 'null' : selectedKey }} </label><br/>
            <label style="color:green;padding-left: 5px;">ExpandedKey:  {{ expandedKey == null ? 'null' : expandedKey }} </label>
        </div>
    </div>
    
    
</template>

<script lang="ts">

import { defineComponent, onMounted, ref, onBeforeUnmount, shallowRef } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import TreeTypeConst from '../../../../domain/Constants/TreeTypeConst';
import { Container } from 'inversify';
import WidgetComponentDataTree from '../../../../../designer/domain/ComponentDataForm';
import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
import CustomValidate from '../../shared/CustomValidate.vue';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_tree',
    emits: ['node-expand', 'node-collapse', 'update:expandedKeys', 'update:selectionKeys', 'node-select', 'node-unselect'],
    components: {
        CustomValidate,
        SummaryDebug
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => WidgetComponentDataTree,
            default: () => ({})
        },

        slotProps: {
            type: Object,
            default: () => ({}),      
    },
    },
    setup(props, context) {
        const arrayModel = ref([]);
        const store = useStore();
        
        const setVModelToArray = () =>{    
            arrayModel.value = (vmodel.value ?? '').split(',').filter(x => x !== '') as []
        }

        const { vmodel, canDoOperation, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue
            , getPropertyValueOptions, getPropertyValueObjectOrFile, getCatalogValue
            , loaded, baseOnMounted, baseOnBeforeUnmount, v$, submitted, canEdit
            , isValidData, fieldKey, fieldKeyComponentDataSourceId, processEventComponent 
            ,componentKey,getLayoutComponent,buildMenu
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store,setVModelToArray)        

        
        const expandedKey = ref(null);
        const selectedKey = ref(null);
        
        const menu = ref();
        const menuItems = shallowRef([]);
        const layoutMenu = getLayoutComponent(props.Component, TreeTypeConst.LAYOUT_MENU);
        if (layoutMenu && layoutMenu.get(layoutMenu.id,'children')?.length > 0) {
            menuItems.value = buildMenu(layoutMenu.get(layoutMenu.id,'allchildren') ?? []) as any;
            
        }


        const onRightClick = (event: any) => {
            if (menuItems.value.length>0){
                menu.value.show(event);
                event.stopPropagation();
            }
            
            
        };

        const setSelectedKeyToVModel = () => {
            if ((Object.keys(selectedKey.value ?? {})).length > 0) {

                if (getSelectionMode() == 'single'){
                    vmodel.value = Object.keys(selectedKey.value ?? {})[0]
                }
                else{
                    vmodel.value = JSON.stringify((Object.keys(selectedKey.value ?? {})).join(','))
                }
                
            }
            else {
                vmodel.value = null
            }
            processEventComponent(CatalogEventConst.CHANGE)
        }


        onMounted(() => {
            baseOnMounted();
        })

        onBeforeUnmount(() => {
            expandedKey.value = null;
            selectedKey.value = null;        
            menu.value = null;
            menuItems.value.length = 0;
            baseOnBeforeUnmount();
        })



        const getValue = () : any[] | undefined => {
            let values = getPropertyValueObjectOrFile(TreeTypeConst.DATAVALUE, TreeTypeConst.DATAFILEVALUE, TreeTypeConst.DATASOURCEVALUE, true);

            if (values == null) return undefined;
            if (Array.isArray(values))
                return values;
            values = values?.replace(/\'/g,'\"');
            let nodes:any = '';
            try { 
                nodes = JSON.parse(values); 
            } 
            catch (e) { 
                nodes = values; 
            }
            return nodes;
        }

        const getSelectionMode = () : "multiple" | "checkbox" | "single" => {
            const value = getCatalogValue(TreeTypeConst.SELECTIONMODE)
            const mode: "multiple" | "checkbox" | "single" = value as "multiple" | "checkbox" | "single";
            return mode ?? 'multiple';
        }
        
        const getFilterMode = () : "strict" | "lenient"  => {
            const value = getCatalogValue(TreeTypeConst.FILTERMODE)
            const mode: "strict" | "lenient" = value as "strict" | "lenient";
            return mode ?? 'lenient';
        }

        const getLoadingMode = () : "mask" | "icon"  => {
            const value = getCatalogValue(TreeTypeConst.LOADINGMODE)
            const mode: "mask" | "icon" = value as "mask" | "icon";
            return mode ?? 'mask';
        }

        const onNodeExpand = (node : any) => {
            context.emit('node-expand', node);
        }
        const onNodeCollapse = (node : any) => {
            context.emit('node-collapse', node);
        }
        const onNodeSelect = (node : any) => {
            context.emit('node-select', node);
        }
        const onNodeUnSelect = (node : any) => {
            context.emit('node-unselect', node);
        }
        const updateExpandedKeys = (key : any) => {
            expandedKey.value = key;
            context.emit('update:expandedKeys', key);
        }
        const updateSelectionKeys = (key : any) => {
            selectedKey.value = key;

            arrayModel.value = [];
            if ((Object.keys(selectedKey.value ?? {})).length > 0) {
                (Object.keys(selectedKey.value ?? {})).forEach(element => {
                    arrayModel.value.push(element as never);
                });
            }

            setSelectedKeyToVModel();
            context.emit('update:selectionKeys', key);
        }


        return {
   
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            TreeTypeConst,
            ControlTypeConst,
            getPropertyNumberValue,
            loaded,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            processEventComponent,
            CatalogEventConst,
            ObjectGroupConst,
            canDoOperation,
            getValue,
            getSelectionMode,
            getFilterMode,
            getLoadingMode,
            onNodeExpand,
            onNodeCollapse,
            onNodeSelect,
            onNodeUnSelect,
            updateExpandedKeys,
            updateSelectionKeys,
            selectedKey,
            expandedKey,
            arrayModel,
            setSelectedKeyToVModel,
            componentKey,
            menu,
            menuItems,
            onRightClick
        };
    },
});
</script>
<style scoped></style>
