import { ClassicPreset } from 'rete';
import { AreaPlugin } from 'rete-area-plugin';
import { AreaExtra, Schemes } from '../rete/helperRete';

import { LocalService, MessageService } from '../../../../../../../common/infrastructure/servicios';
import { Router } from 'vue-router';
import { Container } from 'inversify';
import { MessageControl } from '../controls/messageControl';
import {  DataflowEngine } from 'rete-engine';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import CatalogObjectTypeConst from '../../../../../catalog/domain/const/CatalogObjectTypeConst';
import { IapWorkFlowActivityControl } from '../../../../domain/service/iapWorkFlowActivityControl';
import CatalogExpConst from '../../../../../catalog/domain/const/CatalogExpConst';
import { ExpresionEngine } from '../../../../../expression/infrastructure/helper/expressionEngine';
import { IapWorkFlowActivity } from '../../../../domain/service/iapWorkFlowActivity';
import EventConst from '../../../../../../../common/domain/constantes/EventConst';
import MessageNodeConst from '../constants/MessageNodeConst';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';


export class MessageNode extends ClassicPreset.Node<
  { ejecutar: ClassicPreset.Socket, message: ClassicPreset.Socket },
  { cancel: ClassicPreset.Socket, ok: ClassicPreset.Socket },
  { value: MessageControl }
> {
  height = 480;
  width = 380;

  //private value: Number;
  private msgOutput: string='';
  private keyEventComponent: string;
  private variableMsg: string;
  private variableMsgType: number;
  private variableModal: boolean;
  private variableCounter: boolean;

  private area: AreaPlugin<Schemes, AreaExtra>;
  private updateNode: any;
  private rdControlId: '';

  private dataflow: DataflowEngine<Schemes>
  private getNodeInternalData: any;
  private showExpression: any;
  private router: Router;
  private container: Container | undefined;
  private rootComponentId: number;
  private currentComponentId: number;
  private store: any;
  private activity: IapWorkFlowActivity | undefined;
  private componentData: IapComponent[];
  private formKey:String;
  private currentElementKey:string;
  
  

  //constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket,  public applicationId: number, public applicationVersion: number, public variableComponentInput: number, public variableComponentHeaderInput: string, public variableComponentModalInput: boolean, public variableComponentParametersInput: [], updateNode: any = undefined) {
  constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket,public formKeyInput: String,public currentElementKeyInput:string,public rootComponentInputId: number, public currentComponentInputId: number, public applicationId: number, public applicationVersion: number, public variableMsgInput: string, public variableMsgInputType: number, public variableMsgModalInput: boolean,public variableMsgCounterInput:boolean, updateNode: any = undefined, getNodeInternalData: any = undefined, showExpressionFunction: any = undefined, router: Router, container: Container | undefined, storeInput: any, itemActivity: IapWorkFlowActivity | undefined, dataflow: DataflowEngine<Schemes>,componentDataInput: IapComponent[]) {
    super("Message");

    this.area = area;
    this.updateNode = updateNode;
    this.variableMsg = variableMsgInput;
    this.variableMsgType = variableMsgInputType;
    this.router = router;
    this.dataflow = dataflow;
    this.variableModal = variableMsgModalInput;
    this.variableCounter = variableMsgCounterInput;

    this.getNodeInternalData = getNodeInternalData;
    this.showExpression = showExpressionFunction;
    this.container = container;
    this.rootComponentId = rootComponentInputId;
    this.currentComponentId = currentComponentInputId;
    this.store = storeInput;
    this.activity = itemActivity;
    this.keyEventComponent = (rootComponentInputId ?? -1).toString() + '_' + EventConst.SHOWCOMPONENT;
    this.componentData = componentDataInput;
    this.formKey = formKeyInput;
    this.currentElementKey = currentElementKeyInput;

    const dsControl = new MessageControl(formKeyInput,rootComponentInputId,currentComponentInputId,applicationId, applicationVersion, variableMsgInput, variableMsgInputType, variableMsgModalInput,variableMsgCounterInput,container,storeInput,itemActivity, this.updateData, this.getNode, this.showExp);

    this.rdControlId = (dsControl as any).id;

    this.addInput("ejecutar", new ClassicPreset.Input(socket, "Ejecutar", true));
    this.addControl(
      "value",
      dsControl

      //new ClassicPreset.InputControl("text", { initial })
    );
    this.addInput("message", new ClassicPreset.Input(socket, "MessageInput"));



    //area.update("control",dsControl.id)
    this.addOutput("ok", new ClassicPreset.Output(socket, "Ok", true));
    this.addOutput("cancel", new ClassicPreset.Output(socket, "Cancel", true));





  }

  showExp = (evt: any) => {
    if (this.showExpression) {
      return this.showExpression(evt)
    }
    return null;
  }

  getNode = (key: string) => {
    if (this.getNodeInternalData) {
      return this.getNodeInternalData(this.id, key, true, false)
    }
    return null;
  }

  updateData = (evt: any) => {

    //this.value = evt
    //@ts-ignore:disable-next-line
    this.controls.value[evt.key] = evt.data;
    //this.controls[evt.key].valueConnection = evt.data;

    this.area.update("control", this.rdControlId)


    if (this.updateNode) {
      this.updateNode(this.id, evt.key, JSON.stringify(evt.data), (evt?.operation ?? OperationDataTypeConst.UPDATE))
    }


  }

resolveExpressions = () =>{
  const currentComp = this.componentData.find(x => x.id == this.currentComponentId)
  const WfData = currentComp?.workFlows?.find(w => w.id == this.activity?.workFlowId)

  this.activity?.iapWorkFlowActivityControls?.forEach((wcf: IapWorkFlowActivityControl) => {

    if (currentComp && wcf) {

      
      let keyControl = CatalogObjectTypeConst.ATTRCOMP + '#' + WfData?.objetoId + '#' + wcf.id.toString();

      let exps = currentComp.expressions?.filter(x =>
        x.idObjeto == CatalogObjectTypeConst.WFAC
        && x.idTypeExpression == CatalogExpConst.EXP_SET
        && x.iapExpressionDetails?.length > 0
        && x.objetoId == keyControl);

        if ((exps?.length ?? 0) == 0){
          keyControl = CatalogObjectTypeConst.EVTCOMP + '#' + WfData?.objetoId + '#' + wcf.id.toString();

          exps = currentComp.expressions?.filter(x =>
            x.idObjeto == CatalogObjectTypeConst.WFAC
            && x.idTypeExpression == CatalogExpConst.EXP_SET
            && x.iapExpressionDetails?.length > 0
            && x.objetoId == keyControl);
        }
      if (exps?.length > 0) {
        exps?.every(exp => {
          if (exp.iapExpressionDetails?.length > 0) {            
            const localData = LocalService.getValue(this.formKey + LocalService.COMPONENTS_EXP + (this.rootComponentId ?? -1).toString());
            const data = HelperUtils.jsonParse(localData,[])
            let resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, this.store)
            //resu = resu?.toString();
            if (resu) {
              if (Object.keys(resu).length == 0) {
                resu = resu?.toString();
              }
            }

            switch (wcf.name) {
              case MessageNodeConst.VAR_MSG_IN:
                // code block
                this.msgOutput = resu
                break;
                case MessageNodeConst.VAR_MSGTYPE_IN:
                  // code block
                  this.variableMsgType = resu
                  break;
                  case MessageNodeConst.VAR_MSGCOUNTER_IN:
                    // code block
                    this.variableCounter = resu
                    break;
                  case MessageNodeConst.VAR_MSGMODAL_IN:
                    // code block
                    this.variableMsgModalInput = resu
                    break;
  
              default:
              // code block
            }

          }
        })

      }

    }
    

  })

  //const wcf =  this.activity?.iapWorkFlowActivityControls.find(x=> x.name == DisplayComponentNodeConst.VAR_PARAM_IN);
  
}

/*function transformString(input:string) {
  return input.replace(/#(.*?)#/g, '$1');
}
*/

  async execute(_: never, forward: (output: "cancel" | "ok") => void) {
    
    const inputs = (await this.dataflow.fetchInputs(this.id)) as {
      message: string[];
    };

    

     this.msgOutput = this.variableMsg ?? '';

    const msg = inputs?.message?.filter(x => x !== undefined);
    if (msg && Array.isArray(msg) && msg?.length > 0) {
      //this.msgOutput = '';
      let hasData = false;
      //@ts-ignore:disable-next-line
      this.msgOutput.match(/#(.*?)#/g, '$1')?.forEach(m =>{
        hasData=true;
        //@ts-ignore:disable-next-line
        const _variable = m.replaceAll('#','')
        const _value = HelperUtils.getDeepvalueObjectByKey(msg[0],_variable)
        //@ts-ignore:disable-next-line
        if (_value[_variable]){
          //@ts-ignore:disable-next-line
          this.msgOutput = this.msgOutput.replaceAll(m,_value[_variable])
        }
      })

      if (!hasData){
        this.msgOutput = JSON.stringify(msg[0] ?? '') as any
      }


    }
    else if (msg) {
      if (typeof(msg) === 'object'){
        this.msgOutput = JSON.stringify(msg ?? '{}') as any
      }
      else{
        this.msgOutput = msg as any
      }
      
    }
    //this.router.push({ name: 'mipanel' });
    /*
      Info = 1,
      Error,
      Aviso,
      Correcto,
      Pregunta,
    */


    // resolvemos las expresiones
    // vamos a buscar las expresiones si las hubiera
    
  
       this.resolveExpressions();
  
    // fin resolver las expresiones


    if (this.variableCounter){
      MessageService.showMessageCounter(this.variableMsgType as any, '', this.msgOutput)
      forward("ok");
    }
    else{
      if (this.variableMsgModalInput) {
        let _showConfirmButton = true;
        let _showCancelButton = false;
        switch (this.variableMsgType) {
          case 1:
          case 2:
          case 3:
          case 4:
            //forward("ok");
            break;
          case 5:
            _showCancelButton = true;
            //forward("ok");
            break;
  
          default:
            // code block
            forward("ok");
            break;
        }
        var doCallbackOk = () => {
          if (_showConfirmButton) {
            forward("ok");
          }
  
        }
  
  
        var doCallbackCancel = () => {
          if (_showCancelButton) {
            forward("cancel");
          }
  
        }
  
        await MessageService.showMessage(this.variableMsgType as any, '', this.msgOutput, _showConfirmButton, _showCancelButton, false, "", doCallbackOk, doCallbackCancel)
  
      }
      else {
        MessageService.showToast(this.variableMsgType as any, '', this.msgOutput)
        forward("ok");
      }
    }

   



  }


  data() {
    return {};
  }
}